import AuthAxios from "./AuthAxios";
import { saveAs } from 'file-saver'; //npm package to save files
import Swal from "sweetalert2";

export const handleFirebaseDownload = async (googleUID, fileName) => {

    if(googleUID && fileName.length > 40) {
        try {
            const response = await AuthAxios.post("/dokumente/download", {
                googleUID: googleUID,
                fileName: fileName
            });
            saveAs(response.data, fileName);

        } catch (error) {
            console.error("Download error: ", error);
            Swal.fire({
                icon: "error",
                title: "Fehler",
                html: "Fehler beim Datei Download."
            })
        }

    } else {
        Swal.fire({
            icon: "error",
            title: "Fehler",
            html: "Dateiname oder ID nicht gesetzt."
        })
    }

};
