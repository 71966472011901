import React from 'react';
import { createPortal } from 'react-dom';


import style from "./NotizenEditModal.module.css"

import { Form, Button, Modal } from "react-bootstrap";


const Backdrop = (props) => {
    return <div className={style.backdrop} onClick={props.onConfirm} />
}

const ModalOverlay = (props) => {

    return (
      <Modal show={props.visible} onHide={() => props.setVisible(false)}>
        <Modal.Header closeButton>
          <Modal.Title><span style={{fontSize: "70%"}}>{props.title}</span></Modal.Title>
        </Modal.Header>
        <Modal.Body>

            <p>Aufgabenarten werden dem Kunden in der VIN1 App angezeigt und dienen der Gruppierung der Aufgaben nach anliegen.</p>

            <Form.Label>Bezeichnung der Aufgabenart</Form.Label>
            <Form.Control
              type="text"
              name="name"
              placeholder="Aufgabenart"
              value={props.newAufgabenart ? props.newAufgabenart : ""}
              onChange={(e) => props.setNewAufgabenart(e.target.value)}
            />

        </Modal.Body>
        <Modal.Footer>
            <p style={{ fontSize: "100%", display: "flex", justifyContent: "flex-end", width: "auto !important", background: "#df4759", padding: "15px", color: "white", fontWeight: "bold", borderRadius: "8px", cursor: "pointer" }}
                onClick={() => props.setVisible(false)}
            >
                Schließen
            </p>
            <p style={{ fontSize: "100%", display: "flex", justifyContent: "flex-end", width: "auto !important", background: "#520da7", padding: "15px", color: "white", fontWeight: "bold", borderRadius: "8px", cursor: "pointer" }}
                onClick={() => {
                    props.setVisible(false)
                    props.createNewAufgabenart(props.newAufgabenart);
                }}
            >
                Speichern
            </p>
        </Modal.Footer>
      </Modal>
    )
}

const AufgabenartModal = (props) => {

    return (
      <>
        {createPortal(<Backdrop hideModal={props.hideModal} />, document.getElementById('backdrop-root'))}
        {createPortal(<ModalOverlay title={props.title} newAufgabenart={props.newAufgabenart} setNewAufgabenart={props.setNewAufgabenart} createNewAufgabenart={props.createNewAufgabenart} visible={props.visible} setVisible={props.setVisible} />, document.getElementById('overlay-root'))}
      </>
    )
}

export default AufgabenartModal