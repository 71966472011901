import React, { useEffect, useState , useContext} from "react";
import { useParams } from "react-router-dom";
//import { MainContext } from '../../contexts/MainContext';
import {
  Alert,
  Container,
  Row,
  Col,
  Form,
  Modal,
  Button,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";

import Spinner from "../../Bausteine/Spinner/Spinner"

import { COLORS } from "../../constants";
import { MainContext } from "../../contexts/MainContext";
import AuthAxios from "../../utils/AuthAxios";
import Swal from "sweetalert2";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSave, faXmark, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { faAlignLeft, faAlignCenter, faAlignRight } from "@fortawesome/free-solid-svg-icons";
import GewinnspielTable from "./GewinnspielTable";
//import CropImageModal from "./CropImageModal";
//import b64toBlob from "../../utils/cropper";

//import NewsLetterHelperModal from "./NewsLetterHelperModal";
import GewinnspielCard from "./GewinnspielCard";
import FilePreviewRemoveButton from "../../Bausteine/FilePreviewRemoveButton";

import {
  BtnBold,
  BtnItalic,
  createButton,
  HtmlButton,
  Editor,
  EditorProvider,
  Toolbar 
} from 'react-simple-wysiwyg';



export const getCurrentDateTime = () => {
  const now = new Date();
  const berlinTime = new Date(now.toLocaleString("en-US", {timeZone: "Europe/Berlin"}));
  const date = berlinTime.getFullYear()+'-'+('0'+(berlinTime.getMonth()+1)).slice(-2)+'-'+('0'+berlinTime.getDate()).slice(-2);
  const time = ('0'+berlinTime.getHours()).slice(-2) + ":" + ('0'+berlinTime.getMinutes()).slice(-2);
  return `${date}T${time}`;  
};


export const getCurrentDateTimeEnd = () => {
  const now = new Date();
  now.setDate(now.getDate() + 7); // Add 7 days to the current date
  const berlinTime = new Date(now.toLocaleString("en-US", {timeZone: "Europe/Berlin"}));
  const date = berlinTime.getFullYear()+'-'+('0'+(berlinTime.getMonth()+1)).slice(-2)+'-'+('0'+berlinTime.getDate()).slice(-2);
  const time = ('0'+berlinTime.getHours()).slice(-2) + ":" + ('0'+berlinTime.getMinutes()).slice(-2);
  return `${date}T${time}`;
};


function Gewinnspiel() {

  const BtnAlignLeft = createButton(
    'Align left',
    <FontAwesomeIcon
      icon={faAlignLeft}
      size="1x"
      className="actionIcon"
    />,
    'justifyLeft');

  const BtnAlignCenter = createButton(
    'Align center',
    <FontAwesomeIcon
      icon={faAlignCenter}
      size="1x"
      className="actionIcon"
    />,
    'justifyCenter');

  const BtnAlignRight = createButton(
    'Align right',
    <FontAwesomeIcon
      icon={faAlignRight}
      size="1x"
      className="actionIcon"
    />,
    'justifyRight');

  const { user } = useContext(MainContext);

  const [spinner, setSpinner] = useState(false);
  const [alertSuccess, setAlertSuccess] = useState("");
  const [alertError, setAlertError] = useState("");
  const [allGewinnspiele, setAllGewinnspiele] = useState([]);
  const [showHelper, setShowHelper] = useState(false);

  const [isActive, setIsActive] = useState(false);


  const [image, setImage] = useState(null);
  const [pdfFile, setPdfFile] = useState(null);

  const [changed, setChanged] = useState(false);
  const [reload, setReload] = useState(false);

  const [gewinnspiel, setGewinnspiel] = useState({
    isActive: false,
    startDate: getCurrentDateTime(),
    endDate: getCurrentDateTimeEnd(),
  });


  async function getGewinnspieleFromUser() {
    try {
      const res = await AuthAxios.get("/gewinnspiel");
      //console.log(response.data);
      setAllGewinnspiele(res.data);
    } catch (err) {
      console.log(err);
    }
  }

  

  useEffect(() => {
    getGewinnspieleFromUser();
  }, []);


  const renderTooltip = props => (
    <Tooltip {...props}>Tooltip for the Register Button</Tooltip>
  );


  async function createNewGewinnspiele() {

    try {
      if (!gewinnspiel.title || !gewinnspiel.message || !gewinnspiel.startDate || !gewinnspiel.endDate || !image) {
        Swal.fire({
          icon: "info",
          title: "Pflichtfelder",
          html: "Bitte fülle alle Pflichtfelder aus und klicke erneut auf speichern.",
        });
        return;
      }

      setSpinner(true);

      const formData = new FormData();
      formData.append("isActive", gewinnspiel.isActive);
      formData.append("title", gewinnspiel.title);
      formData.append("message", gewinnspiel.message);

      formData.append("publishDate", gewinnspiel.publishDate);

      formData.append("startDate", gewinnspiel.startDate);
      formData.append("endDate", gewinnspiel.endDate);


      //Dateien
      if(image) {
        formData.append("image", image);
      }
      //optional
      if(pdfFile) {
        formData.append("pdfFile", pdfFile);
      }

      AuthAxios({
        method: 'post',
        url: "/gewinnspiel",
        headers: { "Content-Type": "multipart/form-data" }, // Mixed Data with Files and JSON
        data: formData
      })
      .then(res => {

        setAllGewinnspiele([...allGewinnspiele, res.data])

        Swal.fire({
          icon: "success",
          title: "Erfolg",
          html: "Das Gewinnspiel wurde erfolgreich angelegt.",
        });
        
        setSpinner(false);

      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Formular",
          html: "Es ist ein Fehler aufgetreten. Bitte erneut versuchen.",
        });
        setSpinner(false);
      });
      
      
    }
    catch(error) {
      Swal.fire({
        icon: "error",
        title: "Fehler",
        html: "Das Gewinnspiel konnte nicht erstellt werden",
      });
      setSpinner(false);
    }

  }


  const handleUpdateGewinnspielData = (updatedGewinnspiel) => {

    alert("update table..")
    /*
    setAllGewinnspiele((prevGewinnspiele) => {
      // Find the index of the newsletter with the matching ID
      const updatedIndex = prevGewinnspiele.findIndex(
        (gewinnspiel) => gewinnspiel._id === updatedGewinnspiel._id
      );
  
      if (updatedIndex === -1) {
        // If the newsletter with the given ID doesn't exist, return the original array
        return prevGewinnspiele;
      }
  
      // Create a new array with the updated newsletter at the specified index
      const updatedGewinnspiel = [
        ...prevGewinnspiele.slice(0, updatedIndex),
        updatedGewinnspiel,
        ...prevGewinnspiele.slice(updatedIndex + 1),
      ];
  
      return updatedGewinnspiel;
    });
    */
  };





  const [imageUrl, setImageUrl] = useState(null);
  
  //const [showModal, setShowModal] = useState(false);
  //const [croppedImageUrl, setCroppedImageUrl] = useState();


  const handleImageInputChange = (event) => {
    setChanged(true);
    const file = event.target.files[0];

    // Size limit in bytes
    var maxSize = 5 * 1024 * 1024; // 5MB

    if (file.size > maxSize) {
        Swal.fire({
          icon: 'info',
          title: 'Gewinnspielbild',
          html: 'Das Bild darf max. 5 MB groß sein.'
        })
        return;
    }

    setImage(file);
    setImageUrl(URL.createObjectURL(file));

    /*
      const selectedFile = event.target.files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        setImageUrl(reader.result);
        //setShowModal(true); // crop Modal
      };
      reader.readAsDataURL(selectedFile);
    */
  };


  const handlePDFInputChange = (event) => {
    setChanged(true);
    const file = event.target.files[0];

    // Size limit in bytes
    var maxSize = 5 * 1024 * 1024; // 5MB

    if (file.size > maxSize) {
      Swal.fire({
        icon: 'info',
        title: 'PDF-Datei',
        html: 'Das PDF darf max. 5 MB groß sein.'
      })
      return;
    }


    setPdfFile(file);


    //setImageUrl(URL.createObjectURL(file));
    /*
      const selectedFile = event.target.files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        setImageUrl(reader.result);
        //setShowModal(true); // crop Modal
      };
      reader.readAsDataURL(selectedFile);
    */
  };




  const newGewinnspiel = () => {
    setGewinnspiel({});
  };



  const cancelEdit = () => {
    Swal.fire({
      icon: "info",
      title: "Änderungen verwerfen",
      html: "Willst du deine Änderungen wirklich verwerfen?",
      showDenyButton: true,
      confirmButtonText: 'Verwerfen',
      denyButtonText: `Abbrechen`
    })
    .then( async (result) => {
      if (result.isConfirmed) {
        setGewinnspiel({});
        setImageUrl(null);
        setPdfFile(null);
        setChanged(false);
        setReload(true);
      } 
    })
  }

  return (
    <>
      <Container fluid className="mt-5">
        <Row className="mainActions">
          <Col xs={12} sm={12}>
            <span>
              <FontAwesomeIcon
                icon={faPlus}
                size="2x"
                className="actionIcon"
                onClick={newGewinnspiel}
                title="Neues Gewinnspiel anlegen"
              />
            </span>
            {changed && 
              <>
                <span style={{paddingLeft: "8px"}}>
                  <FontAwesomeIcon
                    icon={faSave}
                    size="2x"
                    className="actionIcon"
                    onClick={createNewGewinnspiele}
                    title="Gewinnspiel speichern"
                  />
                </span>
                <span style={{paddingLeft: "8px"}}>
                  <FontAwesomeIcon
                    icon={faXmark}
                    size="2x"
                    className="actionIcon"
                    onClick={cancelEdit}
                    title="Änderungen verwerfen"
                  />
                </span>
              </>
            }
          </Col>
          <Col xs={12} sm={5} className="meldungen">
            {alertSuccess.length > 0 && (
              <Col xs={12} md={6}>
                <Alert
                  key={1}
                  variant={"success"}
                  onClick={() => setAlertSuccess("")}
                >
                  {alertSuccess}
                </Alert>
              </Col>
            )}
            {alertError.length > 0 && (
              <Col xs={12} sm={6} md={6}>
                <Alert
                  key={2}
                  variant={"danger"}
                  onClick={() => setAlertError("")}
                >
                  {alertError}
                </Alert>
              </Col>
            )}
          </Col>
        </Row>

        <div
          style={{
            marginLeft: "0px",
            marginTop: "0px",
            marginBottom: "0px",
            width: "100%",
          }}
        > 

          <div className="mainWork">
            <Container fluid>
              <p>
                Der Gewinner des Gewinnspiels wird automatisch nach dem Zufallsprinzip ermittelt und anschließend in der Tabelle sichtbar.<br />
                Außerdem wird an die hinterlegte E-Mail: <b><i>{user.username}</i></b> mit einem Hinweis zu dem Gewinner versendet.
              </p>
              <Form>
                <Row style={{ display: "flex" }}>
                  <Col xs={12} md={12} xl={4}>
                    <fieldset
                      className="border"
                      style={{ background: "white", borderRadius: "8px" }}
                    >
                      <legend
                        className="float-none w-auto p-2"
                        style={{
                          color: COLORS.primCol,
                          background: COLORS.primBG,
                          borderRadius: "8px",
                          fontSize: "12px",
                        }}
                      >
                        Neues Gewinnspiel
                      </legend>

                      <Row>
                        <Col>

                        <Form.Group controlId="exampleCheckbox">
                          <Form.Check
                            type="checkbox"
                            label="Gewinnspiel direkt veröffentlichen"
                            checked={gewinnspiel.isActive || false}
                            onChange={(e) => {
                              setChanged(true);
                              setGewinnspiel({
                                ...gewinnspiel,
                                isActive: e.target.checked,
                              });
                            }}
                          />
                        </Form.Group>

                        <Form.Label>Titel (Pflicht)</Form.Label>
                        <Form.Control
                          type="text"
                          name="titel"
                          value={gewinnspiel.title || ""}
                          placeholder="Titel"
                          onChange={(e) => { 
                            setChanged(true);
                            setGewinnspiel({
                              ...gewinnspiel,
                              title: e.target.value,
                            });
                          }}
                        />

                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlTextarea1"
                        >
                          <Form.Label>
                            Nachricht (Pflicht)
                          </Form.Label>
                         
                          <EditorProvider>
                            <Toolbar>
                                <BtnBold />
                                <BtnItalic />
                                <BtnAlignLeft />
                                <BtnAlignCenter />
                                <BtnAlignRight />
                                {/* Preview HTML Code <HtmlButton />*/}
                                <HtmlButton />
                              </Toolbar>
                            <Editor 
                             style={{height: "150px"}}
                             value={gewinnspiel.message || ""}
                             onChange={(e) => {
                               setChanged(true);
                               setGewinnspiel({
                                 ...gewinnspiel,
                                 message: e.target.value,
                               });
                             }}
                            >
                            </Editor>
                          </EditorProvider>
                         
                        </Form.Group>

                        {/*
                        <Button
                          onClick={() => {
                            setShowHelper(true);
                          }}
                          variant="primary"
                        >
                          KI-Unterstützung
                        </Button>
                        <br />
                        */}

                      <hr />

                      <Form.Label className="fw-bold">
                        Gewinnspiel als Vorschau in der App anzeigen<br />(in dieser Zeit noch keine Teilnahme möglich)
                      </Form.Label>
                      <Form.Select
                        area-label="Default select"
                        value={gewinnspiel.publishDate || ""}
                        name="publishDate"
                        onChange={(e) =>{ 
                          setChanged(true);
                          setGewinnspiel({
                            ...gewinnspiel,
                            publishDate: e.target.value,
                          });
                      }}>
                        <option value="0">kein Vorlauf</option>
                        <option value="1">1 Tag im Voraus</option>
                        <option value="2">2 Tage im Voraus</option>
                        <option value="3">3 Tage im Voraus</option>
                        <option value="4">4 Tage im Voraus </option>
                        <option value="5">5 Tage im Voraus</option>
                        <option value="6">6 Tage im Voraus</option>
                        <option value="7">7 Tage im Voraus</option>
                      </Form.Select>


                      <div className="mt-3">
                        <span className="fw-bold">Start & Ende der Teilnahme</span>
                      </div>
                      <Form.Label>
                        Gib ein Datum und eine Zeit ein, <b>ab wann</b> am Gewinnspiel teilgenommen werden kann.(Pflicht)
                      </Form.Label>
                      <Form.Control
                        type="datetime-local"
                        name="datum"
                        value={gewinnspiel.startDate || getCurrentDateTime()}
                        min={getCurrentDateTime()}
                        onChange={(e) =>{ 
                          setChanged(true);
                          setGewinnspiel({
                            ...gewinnspiel,
                            startDate: e.target.value,
                          });
                        }}
                      />

                      <Form.Label>
                        Gib ein Datum und eine Zeit ein, <b>bis wann</b> am Gewinnspiel teilgenommen werden kann.(Pflicht)
                      </Form.Label>
                      <Form.Control
                        type="datetime-local"
                        name="datum"
                        value={gewinnspiel.endDate || getCurrentDateTimeEnd()}
                        min={getCurrentDateTime()}
                        onChange={(e) =>{ 
                          setChanged(true);
                          setGewinnspiel({
                            ...gewinnspiel,
                            endDate: e.target.value,
                          });
                        }}
                      />


                      <Form.Label className="mt-3">Bild (.jpg, .png, max 5MB - Pflicht)</Form.Label>
                      {!imageUrl && 
                        <Form.Control
                          id="newsletterImage"
                          name="newsletterImage"
                          type="file"
                          accept="image/png, image/jpeg, image/jpg"
                          value={image ? image.originalname : ""}
                          onChange={handleImageInputChange}
                        />
                      }

                      {/*imageUrl && 
                        <>
                          <p className="mt-3">Aktuell hinterlegtes Bild</p>
                          <img
                            src={imageUrl}
                            alt="imgPreview"
                            style={{
                              width: "auto",
                              maxHeight: "200px",
                              border: "1px solid black",
                              margin: "10px 20px 10px 0px",
                            }}
                          />
                          <Button
                            style={{
                              margin: "10px 0px",
                              background: "red",
                              border: "none",
                            }}
                            onClick={() => {
                              setImage(null);
                              setImageUrl(null);
                            }}
                          >
                            Bild verwerfen
                          </Button>
                        </>
                      */}

                      <FilePreviewRemoveButton
                        imgUrl={imageUrl} 
                        handleRemove={() => {
                          setImage(null);
                          setImageUrl(null);
                        }}
                      />

                      <br />
                      <hr />
                      

                      <Form.Group className="mb-3">
                        <Form.Label>InfoPDF / Teilnahmebedingungen (max. 5MB - Pflicht)</Form.Label>
                        <Form.Control
                          id="pdf"
                          type="file"
                          name="pdf-file"
                          accept="application/pdf"
                          value={pdfFile ? pdfFile.originalname : ""}
                          onChange={handlePDFInputChange}
                        />
                        {pdfFile && 
                          <Button
                            style={{margin: "8px", background: "red", border: "none"}}
                            onClick={() => setPdfFile(null)}
                          >
                            PDF verwerfen
                          </Button>
                        }
                      </Form.Group>

       
                        </Col>
                      </Row>
                    </fieldset>
                  </Col>    

                  <Col xs={12} md={12} xl={8}>
                    <h5 style={{margin: "20px 10px 0px 10px"}}>Vorschau des Gewinnspiels</h5>
                      {Object.keys(gewinnspiel).length > 0 && 
                          <div key={gewinnspiel}>
                            <GewinnspielCard
                              image={imageUrl}
                              message={gewinnspiel.message ? gewinnspiel.message : ""}
                              title={gewinnspiel.title ? gewinnspiel.title : ""}
                              publishDate={gewinnspiel.publishDate ? gewinnspiel.publishDate : ""}
                              startDate={gewinnspiel.startDate ? gewinnspiel.startDate : ""}
                              endDate={gewinnspiel.endDate ? gewinnspiel.endDate : ""}
                              pdf={pdfFile ? pdfFile : ""}
                            />
                            {/*<Neuigkeiten
                                image={imageUrl}
                                message={newsletter.message ? newsletter.message : ""}
                                title={newsletter.title ? newsletter.title : ""}
                                datum={newsletter.publishDate ? newsletter.publishDate : ""}
                                pdf={pdfFile ? pdfFile : ""}
                                url={newsletter.externeUrl ? "https://"+newsletter.externeUrl : ""}
                            />*/}
                          </div>
                      }
                      {Object.keys(gewinnspiel).length == 0 &&
                        <div style={{padding: "10px 10px 0px 10px"}}>
                          <i>Fülle Felder für ein neues Gewinnspiel aus, um die Vorschau zu generieren.</i>
                        </div>
                      }
                  </Col>

                </Row>
              </Form>

             



              <Row style={{ display: "flex" }}>
                <Col xs={12} md={12} xl={12}>
                  <fieldset
                    className="border"
                    style={{ background: "white", borderRadius: "8px" }}
                  >
                    <legend
                      className="float-none w-auto p-2"
                      style={{
                        color: COLORS.primCol,
                        background: COLORS.primBG,
                        borderRadius: "8px",
                        fontSize: "12px",
                      }}
                    >
                      Deine Gewinnspiele
                    </legend>

                    <Row>
                      <Col style={{overflowX: "auto"}}>
                        <GewinnspielTable
                          getGewinnspieleFromUser={getGewinnspieleFromUser}
                          currentGewinnspiel={allGewinnspiele}
                          updateGewinnspielData={handleUpdateGewinnspielData}
                        />
                      </Col>
                    </Row>
                  </fieldset>
                </Col>
              </Row>
            </Container>
          </div>

          {/*
          <CropImageModal
            src={imageUrl}
            showModal={showModal}
            setShowModal={setShowModal}
            setCroppedImageUrl={setCroppedImageUrl}
            croppedImageUrl={croppedImageUrl}
          />
          */}

          {/* 
          <GewinnspieleHelperModal
            open={showHelper}
            setOpen={setShowHelper}
            gewinnspiel={gewinnspiel}
            setGewinnspiel={setGewinnspiel}
          />*/}

        </div>

        {spinner &&
          <Spinner 
            message={"Bitte warten, Gewinnspiel wird angelegt."}
          />
        }

      </Container>
    </>
  );
}

export default Gewinnspiel;
