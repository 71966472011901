import React, { useState, useEffect } from "react";
//import { MainContext } from '../../contexts/MainContext';

import Swal from "sweetalert2";
import AuthAxios from "../../utils/AuthAxios";
import CardDokument from "../Kunden/DateienBausteine/CardDokument";
import CardNachricht from "../../Bausteine/Cards/CardNachricht";

const Analyse = () => {

  const [bewertung, setBewertung] = useState(0);
  const [nachrichten, setNachrichten] = useState([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function getBewertung() {
      AuthAxios.get("/partner/bewertung")
        .then((res) => {
          setBewertung(res.data);
        })
        .catch((err) => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Fehler",
            html: "Bewertung konnte nicht geladen werden.",
          });
        });
    }

    getBewertung();

    async function getNachrichten() {
        AuthAxios.get("/partner/mitteilungen")
        .then((res) => {
            setNachrichten(res.data);
            setLoading(false);
        })
        .catch((err) => {
            console.log(err);
            Swal.fire({
                icon: "error",
                title: "Fehler",
                html: "Nachrichten konnten nicht geladen werden.",
            });
        });
    }
    getNachrichten();


  }, []);

  
  

  return (
    <div
      style={{
        marginLeft: "0px",
        marginTop: "30px",
        marginBottom: "0px",
        width: "100%",
        height: "100%",
      }}
    >
      <div className="mainWork">
        <h5 className="p-2">Analyse</h5>

        {!bewertung && <i className="m-2">Hier siehst du die Kundenbewertung wenn der erste Kunde dich bewertet hat.</i>}
        {bewertung && <p className="m-2">Kundenbewertung: {bewertung.avgBewertung.toFixed(1)} / 5 &nbsp; | &nbsp; Anzahl Bewertungen: {bewertung && bewertung.count}</p>}

        <br />

        {loading && <i>Nachrichten werden geladen...</i>}
        {!loading &&    
            <>
            {(!nachrichten || nachrichten.length == 0) && <i className="m-2" style={{paddingTop: "10px"}}>Wenn Kunden eine Mitteilung hinterlassen haben, wird diese hier sichtbar.</i>}
            {nachrichten.length > 0 &&
                <>
                    <p className="m-2">Nachrichten von Kunden:</p>
                    {nachrichten.map(n => 
                        <CardNachricht key={n._id} nachricht={n.nachricht} createdAt={n.createdAt}  />
                    )}
                </>
            }
            </>
        }

      </div>
    </div>
  );
};

export default Analyse;
