import React, { useState, useEffect } from "react";
//import { MainContext } from '../../contexts/MainContext';
import { useParams, useNavigate } from 'react-router-dom';

import "./Kategorien.css";

//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import { faCirclePlus, faPlus, faSave, faCheckCircle, faCheck } from "@fortawesome/free-solid-svg-icons";

import { Tab, Tabs } from "react-bootstrap";

import MitarbeiterEdit from "./MitarbeiterEdit";
import MitarbeiterListe from "./MitarbeiterListe";
import Gruppenverwaltung from "./Gruppenverwaltung";

import Swal from "sweetalert2";
import AuthAxios from "../../utils/AuthAxios";
import AufgabenArtenVerwaltung from "./AufgabenArtenVerwaltung";

const Mitarbeiter = () => {

  let { id } = useParams();

  const [userId, setUserId] = useState("");

  useEffect(() => {
    if(id) {
      setUserId(id);
    }
  }, [id])

  const [mitarbeiter, setMitarbeiter] = useState([]);
  const [lizenzen, setLizenzen] = useState(0);

  //const [selectedMitarbeiter, setSelectedMitarbeiter] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(1);


  async function getMitarbeiter() {
    AuthAxios.get("/user")
      .then((res) => {
        setMitarbeiter(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Fehler",
          html: "Mitarbeiter konnten nicht geladen werden.",
        });
      });
  }


  async function getLizenzen() {
    AuthAxios.get("/user/lizenzen")
    .then((res) => {
      setLizenzen(res.data);
    })
    .catch((err) => {
      console.log(err);
      Swal.fire({
        icon: "error",
        title: "Fehler",
        html: "Lizenzen konnten nicht geladen werden.",
      });
    });
  }

  useEffect(() => {
    getMitarbeiter();
    getLizenzen();
  }, []);

  // Update DataGrid mit neuem Mitarbeiter
  const saveNewMitarbeiter = (newMitarbeiter) => {
    AuthAxios.post("/user", newMitarbeiter)
    .then((res) => {
      Swal.fire({
        icon: "success",
        title: "Erfolg",
        html: "Mitarbeiter erfolgreich gespeichert.",
      });
      window.location.reload();

      // Find the index of the object to be replaced
      const index = mitarbeiter.findIndex((ma) => ma._id === newMitarbeiter._id);

      if (index !== -1) {
        // Create a new array with the object replaced
        const newMitarbeiterList = [
          ...mitarbeiter.slice(0, index),
          newMitarbeiter,
          ...mitarbeiter.slice(index + 1),
        ];
        setMitarbeiter(newMitarbeiterList);
      }

    })
    .catch((err) => {
      if(err.response.status == 400) {
        Swal.fire({
          icon: "info",
          title: "Lizenzen",
          html: "Anzahl Lizenzen überschritten. Benutzer kann nicht angelegt/rekativiert werden.",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Fehler",
          html: "Fehler beim Abspeichern: " + err,
        });
      }
    });
  };




  return (
    <div
      style={{
        marginLeft: "0px",
        marginTop: "30px",
        marginBottom: "0px",
        width: "100%",
        height: "100%",
      }}
    >
      <div className="mainWork">
        <Tabs
          activeKey={activeTab}
          onSelect={(key) => setActiveTab(parseInt(key))}
          //defaultActiveKey="mitarbeiter"
          id="fill-tab-example"
          fill
          style={{
            display: "inline-flex",
            width: "auto",
            marginTop: "0px",
            padding: "0px",
          }}
        >
          <Tab
            style={{
              background: "white",
              position: "relative",
              paddingTop: "15px",
            }}
            eventKey="1"
            title="Übersicht Mitarbeiter"
          >
            <MitarbeiterListe
              data={mitarbeiter}
              lizenzen={lizenzen}
              loading={loading}
              setActiveTab={setActiveTab}
              activeTab={activeTab}
            />
          </Tab>
          <Tab
            style={{
              background: "white",
              position: "relative",
              paddingTop: "15px",
            }}
            eventKey="2"
            title="Mitarbeiter anlegen/bearbeiten"
          >
            <MitarbeiterEdit
              data={mitarbeiter}
              saveNewMitarbeiter={saveNewMitarbeiter}
              setActiveTab={setActiveTab}
              activeTab={activeTab}
              id={userId}
              resetId={() => setUserId("")}
            />
          </Tab>

          <Tab
            style={{
              background: "white",
              position: "relative",
              paddingTop: "15px",
            }}
            eventKey="3"
            title="Gruppen"
          >
            <Gruppenverwaltung
              data={mitarbeiter}
              setActiveTab={setActiveTab}
              activeTab={activeTab}
            />
          </Tab>

          <Tab
            style={{
              background: "white",
              position: "relative",
              paddingTop: "15px",
            }}
            eventKey="4"
            title="Aufgaben-Arten"
          >
            <AufgabenArtenVerwaltung
              data={mitarbeiter}
              setActiveTab={setActiveTab}
              activeTab={activeTab}
            />
          </Tab>
          

        </Tabs>
      </div>
    </div>
  );
};

export default Mitarbeiter;
