import React, { useState, useEffect } from "react";
//import { MainContext } from "../../contexts/MainContext";
import { useNavigate, useParams } from "react-router-dom";

import { Container, Row, Col, Form, Button } from "react-bootstrap";
//import { COLORS } from '../../constants'

import AuthAxios from "../../utils/AuthAxios";
import Swal from "sweetalert2";
import SelectGruppen from "../../Bausteine/SelectGruppen";
import SelectRole from "../../Bausteine/SelectRole";

function MitarbeiterEdit(props) {
  /*const [mitarbeiterId, setMitarbeiterId] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [vorname, setVorname] = useState("");
  const [nachname, setNachname] = useState("");
    const { user } = useContext(MainContext);
  const navigate = useNavigate();
    const { jwt, setJwt } = useContext(MainContext);
  const [geburtsdatum, setGeburtsdatum] = useState("1970-01-01");*/

  let { id } = props;

  // Tracking ob es Änderungen gab
  const [changed, setChanged] = useState(false);

  const [mitarbeiter, setMitarbeiter] = useState({});

  async function getMitarbeiter(id) {
    AuthAxios.get("/user/" + id)
      .then((res) => {
        setMitarbeiter(res.data);
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Fehler",
          html: "Mitarbeiter konnte nicht gefunden werden.",
        });
      });
  }

  useEffect(() => {
      if (id !== undefined && !id == "") {
        getMitarbeiter(id);
      } else {
        setMitarbeiter({
          roles: ["Administrator"],
        });
      }
  }, [id]);


  const handleInputChange = (event) => {
    const value = event.target.value;
    setMitarbeiter({
      ...mitarbeiter,
      [event.target.name]: value,
    });
  };

  // React Multi Select Zuweisung
  const setSelectMulti = (name, selection) => {

    Swal.fire({
      icon: "info",
      title: "Coming Soon",
      html: "Bald verfügbar!"
    });
    return;

    
    //setChanged(true);
    let newVals = [];

    selection?.forEach((val) => {
      newVals.push(val.value);
    });

    setMitarbeiter({
      ...mitarbeiter,
      [name]: newVals,
    });
  };

  // CB einzeln auswerten, da nicht e.target.value, sondern e.target.checked!
  const setCBs = (e) => {
    // Wenn das erste mal verändert, Speicherdiskette einblenden!!
    setChanged(true);
    setMitarbeiter({
      ...mitarbeiter,
      [e.target.name]: e.target.checked,
    });
  };


  const handleSaveMitarbeiter = (e) => {

    e.preventDefault();
    // Neu + Update
    props.saveNewMitarbeiter(mitarbeiter);
    //setMitarbeiter({});
    //props.setActiveTab(1);
  };


  const handleNewMitarbeiter = () => {
    setMitarbeiter({});

    // Get the current URL
    const currentUrl = window.location.href;

    // Split the URL into an array of segments
    const urlSegments = currentUrl.split('/');

    // Remove the last segment from the array
    urlSegments.pop();

    // Join the segments back together into a new URL
    const newUrl = urlSegments.join('/');

    // Replace the current URL with the new URL, without causing a page reload
    window.history.replaceState(null, null, newUrl);

    props.resetId();
  }

  return (
    <Container style={{ maxWidth: "600px", margin: "15px 15px 15px 15px" }}>
      {mitarbeiter._id && (
        <Button onClick={handleNewMitarbeiter} className="mb-4">
          Neuen Mitarbeiter anlegen
        </Button>
      )}

    <form onSubmit={handleSaveMitarbeiter}>

      <div>
        <h6>Persönliches</h6>

        <Row className="mt-4">
          <Col xs={12} sm={3} md={4}>
            <Form.Label>Personalnummer *</Form.Label>
          </Col>
          {id ? (
            <Col xs={12} sm={9} md={8}>
              <Form.Control
                className="readOnly"
                type="number"
                placeholder="Personalnummer (eindeutig)"
                required
                name="mitarbeiterId"
                value={
                  mitarbeiter.mitarbeiterId ? mitarbeiter.mitarbeiterId : ""
                }
                onChange={() => {}}
              />
            </Col>
          ) : (
            <Col xs={12} sm={9} md={8}>
              <Form.Control
                type="number"
                placeholder="Personalnummer (eindeutig)"
                name="mitarbeiterId"
                required
                value={
                  mitarbeiter.mitarbeiterId ? mitarbeiter.mitarbeiterId : ""
                }
                onChange={(e) => handleInputChange(e)}
              />
            </Col>
          )}
        </Row>

        <Row className="mt-4">
          <Col xs={12} sm={3} md={4}>
            <Form.Label>Vorname *</Form.Label>
          </Col>
          <Col xs={12} sm={9} md={8}>
            <Form.Control
              type="text"
              placeholder="Vorname"
              name="vorname"
              required
              value={mitarbeiter.vorname ? mitarbeiter.vorname : ""}
              onChange={(e) => handleInputChange(e)}
            />
          </Col>
        </Row>

        <Row className="mt-4">
          <Col xs={12} sm={3} md={4}>
            <Form.Label>Name *</Form.Label>
          </Col>
          <Col xs={12} sm={9} md={8}>
            <Form.Control
              type="text"
              placeholder="Nachname"
              name="nachname"
              required
              value={mitarbeiter.nachname ? mitarbeiter.nachname : ""}
              onChange={(e) => handleInputChange(e)}
            />
          </Col>
        </Row>

        <Row className="mt-4">
          <Col xs={12} sm={3} md={4}>
            <Form.Label>E-Mail *</Form.Label>
          </Col>
          <Col xs={12} sm={9} md={8}>
            <Form.Control
              type="email"
              placeholder="email@company.de"
              name="username"
              required
              value={mitarbeiter.username ? mitarbeiter.username : ""}
              onChange={(e) => handleInputChange(e)}
            />
          </Col>
        </Row>

        {/*
          <Row className="mt-4">
            <Col xs={12} sm={3} md={4}>
              <Form.Label>Geburtsdatum</Form.Label>
            </Col>
            <Col xs={12} sm={9} md={8}>
              <Form.Control
                type="date"
                name="geburtsdatum"
                value={
                  mitarbeiter.geburtsdatum
                    ? mitarbeiter.geburtsdatum.substring(0, 10)
                    : ""
                }
                onChange={(e) => handleInputChange(e)}
              />
            </Col>
          </Row>
        */}

        </div>

        <hr />

        <div>
          <h6>Passwort</h6>
          <Row className="mt-4">
            <Col xs={12} sm={3} md={4}>
              <Form.Label>Initiales Passwort festlegen *</Form.Label>
            </Col>
            <Col xs={12} sm={9} md={8}>
              {!mitarbeiter._id && (
                <Form.Control
                  type="password"
                  placeholder="**********"
                  name="password"
                  required
                  value={mitarbeiter.password ? mitarbeiter.password : ""}
                  onChange={(e) => handleInputChange(e)}
                />
              )}
              {mitarbeiter._id && (
                <i>
                  Passwort durch Benutzer im Loginbereich per "Passwort vergessen"
                  zurücksetzbar.
                </i>
              )}
            </Col>
          </Row>
        </div>

        <hr />

        <div>
          <h6>Zuordung</h6>
          <Row className="mt-4">
            <p style={{fontSize: "90%"}}>Zur Steuerung von Aufgaben</p>
            <Col xs={12} sm={3} md={4}>
              <Form.Label>Gruppe/-n (optional)</Form.Label>
            </Col>

            <Col xs={12} sm={9} md={8}>
              <SelectGruppen
                setSelectMulti={setSelectMulti}
                selectedValues={
                  mitarbeiter.groups
                    ? mitarbeiter.groups.sort((a, b) =>
                        a.label > b.label ? 1 : -1
                      )
                    : ""
                }
              />
            </Col>
          </Row>
        </div>

        <hr />

        <div>
          <h6>Benutzerrolle - Coming Soon</h6>
          <Row className="mt-4">
            <Col xs={12} sm={3} md={4}>
              <Form.Label>Benutzerrolle</Form.Label>
            </Col>
            <Col xs={12} sm={9} md={8}>
              <SelectRole
                placeholder={"Wähle eine oder mehrere Rollen"}
                setSelectMulti={setSelectMulti}
                selectedValues={mitarbeiter.roles} // ? mitarbeiter.roles.sort((a, b) => a.label > b.label ? 1 : -1) : ""}
              />
            </Col>
          </Row>

          <hr />

          <Row className="mt-4">
            <Col xs={12} sm={3} md={4}>
              <Form.Label>Benutzer inaktiv schalten</Form.Label>
            </Col>
            <Col xs={12} sm={9} md={8}>
              <Form.Check
                style={{ marginTop: "10px" }}
                type="checkbox"
                id="loginGesperrt"
                label="Login gesperrt"
                name="loginGesperrt"
                checked={mitarbeiter.loginGesperrt ? true : false}
                onChange={(e) => setCBs(e)}
              />
            </Col>
          </Row>
        </div>

        <Row className="mt-4 pb-5">
          <Button variant="primary" type="submit">
            Speichern
          </Button>
        </Row>

      </form>

    </Container>
  );
}

export default MitarbeiterEdit;

/*
  <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h6">
            Neuen Benutzer registrieren für Login
          </Typography>
          <form
            className={classes.form}
            noValidate
            onSubmit={(e) => handleSubmit(e)}
          >
            <TextField
              variant="standard"
              margin="normal"
              required
              fullWidth
              name="mitarbeiterId"
              label="Mitarbeiter Nummer"
              type="mitarbeiterId"
              id="mitarbeiterId"
              onChange={(e) => handleChange(e)}
            />

            <TextField
              variant="standard"
              margin="normal"
              required
              fullWidth
              id="username"
              label="Benutzername (aktuell E-Mail)"
              name="username"
              autoFocus
              onChange={(e) => handleChange(e)}
            />

            <TextField
              variant="standard"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Passwort"
              type="password"
              id="password"
              onChange={(e) => handleChange(e)}
            />

            <TextField
              variant="standard"
              margin="normal"
              required
              fullWidth
              name="vorname"
              label="Vorname"
              id="vorname"
              onChange={(e) => handleChange(e)}
            />

            <TextField
              variant="standard"
              margin="normal"
              required
              fullWidth
              name="nachname"
              label="Nachname"
              id="nachname"
              onChange={(e) => handleChange(e)}
            />

            <TextField
              variant="standard"
              margin="normal"
              required
              fullWidth
              name="geburtsdatum"
              label="Geburtsdatum"
              type="date"
              id="geburtsdatum"
              onChange={(e) => handleChange(e)}
              InputLabelProps={{ shrink: true }}
            />

            {showAlert ? (
              <Alert style={{ whiteSpace: "pre-line" }} severity="warning">
                {alertText}
              </Alert>
            ) : null}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Neuen Benutzer anlegen
            </Button>
            <Grid container></Grid>
          </form>
        </div>
      </Container>
      <Container>
        <div style={{marginTop: "50px"}} >
          <h4>
            Hier wird eine Rechteverwaltung zu einem neuen/bereits Bestehenden
            MItarbeiter eingebaut
          </h4>
        </div>
      </Container>
      */
