import React, { useEffect, useState } from "react"

import { useNavigate } from 'react-router-dom';

import {deDE, GridToolbar, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton, GridToolbarColumnsButton, GridToolbarQuickFilter } from '@mui/x-data-grid';
import StripedDataGrid from "../../Bausteine/StripedDataGrid";
import Box from '@mui/material/Box';

import {Row, Col} from 'react-bootstrap'





function MitarbeiterListeInaktiv(props) {

  const navigate = useNavigate();

  const [inaktiveMitarbeiter, setInaktiveMitarbeiter] = useState([]);
  
  useEffect(() => {

    setInaktiveMitarbeiter(props.data.data.filter((f) => f.loginGesperrt === true))
  }, [props])




  const handleNavigation = (e) => {
    props.data.setActiveTab(2);
    navigate("/dash/mitarbeiter/"+e.id);
  }


  const columns = [
    {
      field: "mitarbeiterId",
      headerName: "ID",
      minWidth: 60,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "username",
      headerName: "E-Mail/Username",
      minWidth: 220,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "vorname",
      headerName: "Vorname",
      minWidth: 120,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "nachname",
      headerName: "Nachname",
      minWidth: 120,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    /*{
      field: "geburtsdatum",
      headerName: "Geburtsdatum",
      minWidth: 110,
      flex: 1,
      renderCell: (params) => (
        <>
          {(params.row.geburtsdatum && params.row.geburtsdatum != "0000-00-00" ) && <span>{new Date(params.row.geburtsdatum).toLocaleDateString('de-De', {day: '2-digit', month: '2-digit', year: 'numeric'}).substring(0,10)}</span>}
          {(!params.row.geburtsdatum || params.row.geburtsdatum == "0000-00-00") && <span>nicht hinterlegt</span>}
        </>
    ),
      headerClassName: "super-app-theme--header",
    },*/
    {
      field: "groups",
      headerName: "Gruppen",
      minWidth: 200,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "roles",
      headerName: "Rechte",
      minWidth: 300,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "createdAt",
      headerName:"Erstellt",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <span>{new Date(params.row.createdAt).toLocaleString("de-De")}</span>
      ),
      headerClassName: "super-app-theme--header",
    }
  ];



  
  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <Row style={{ display: "flex", justifyContent: "flex-start", width: "100%" }}>
          <Col xs={12} lg={2}>
            <GridToolbarQuickFilter />
          </Col>
          <Col xs={6} lg={1}>
            <GridToolbarFilterButton />
          </Col>
          <Col xs={6} lg={1}>
            <GridToolbarColumnsButton />
          </Col>
        </Row>
      </GridToolbarContainer>
    );
  }

  



    return (
      <div style={{ height: `90%`, width: "100%", marginBottom: "30px" }}>
      {props.data.activeTab === 1 && 
        <Box
          sx={{
            maxHeight: '800px',
            width: '100%',
            '& .super-app-theme--header': {
              backgroundColor: '#c58164',
            },
            '& .MuiDataGrid-toolbarContainer': {
              padding: '10px'
            },
            margin: "0px",
            //padding: "5px 10px",
            fontWeight: 'bold',
            //boxShadow: 3,
          }}
        >

          <StripedDataGrid
            autoHeight
            density={'compact'}
            rows={inaktiveMitarbeiter}
            columns={columns}
            getRowId={(row) => row._id}
            onRowClick={handleNavigation}
            pageSize={20}
            rowsPerPageOptions={[20]}
            localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
            loading={props.loading}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
            }
            //components={{ Toolbar: GridToolbar }}
            components={{ Toolbar: CustomToolbar }}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 100 },
              },
            }}
            initialState={{
              sorting: {
                sortModel: [{ field: 'mitarbeiterId', sort: 'asc' }],
              },
            }}
            sx={{
              /*
              marginTop: "20px",
              boxShadow: 2,
              border: "2px solid black",
              padding: "5px 10px",
              borderColor: 'primary.dark',
              '& .MuiDataGrid-cell:hover': {
                color: 'primary.main',
              },
              bgcolor: 'background.paper'
              */
            }}
          />

        </Box>
      }
    </div>
    );
  }
  

export default MitarbeiterListeInaktiv