import React, { useState, useRef } from "react";
import { Modal, Button, Form, Col } from "react-bootstrap";
//import Cropper from "react-cropper";
//import "cropperjs/dist/cropper.css";
import { getCurrentDateTime } from "./Newsletter";
import Swal from "sweetalert2";
import FilePreviewRemoveButton from "../../Bausteine/FilePreviewRemoveButton";
import { faNewspaper } from "@fortawesome/free-solid-svg-icons";

function EditNewsletterModal({
  show,
  handleClose,
  selectedNewsletter,
  onUpdate,
  deleteNewsLetter,
}) {

  const [title, setTitle] = useState(selectedNewsletter.title);
  const [message, setMessage] = useState(selectedNewsletter.message);

  let editURL = (selectedNewsletter.externeUrl && selectedNewsletter.externeUrl.length >=8) ? selectedNewsletter.externeUrl.substring(8) : "";
  const [externeUrl, setExternalUrl] = useState(editURL);

  
  /*const [publishDate, setPublishDate] = useState(
    selectedNewsletter.publishDate
  );
  */
  const [isActive, setIsActive] = useState(
    selectedNewsletter.isActive
  );
  const [isAdvertisement, setIsAdvertisement] = useState(
    selectedNewsletter.isAdvertisement
  );

  const [changed, setChanged] = useState(false);
  
  //const [imageName, setImageName] = useState(selectedNewsletter.imageName);
  const [imageCleared, setImageCleared] = useState(false);
  const [image, setImage] = useState(selectedNewsletter.imageUrl);
  const [imageUrl, setImageUrl] = useState(selectedNewsletter.imageUrl);
  
  const [pdfCleared, setPdfCleared] = useState(false);
  const [pdfName, setPdfName] = useState(selectedNewsletter.pdfName);
  const [pdfFile, setPdfFile] = useState(selectedNewsletter.pdfFileUrl);

  //const [showModal, setShowModal] = useState(false);
  //const [croppedImageUrl, setCroppedImageUrl] = useState();
  //const cropperRef = useRef(null);

  const [newImage, setNewImage] = useState(null);
  const [newPdfFile, setNewPdfFile] = useState(null);


  const handleSubmit = () => {
    /*
    const now = getCurrentDateTime();
    if (publishDate < now) {
      Swal.fire({
        icon: "error",
        title: "Fehler",
        html: "Das Datum darf nicht in der Vergangenheit liegen",
      });
      return;
    }
    */



    if (!selectedNewsletter.title || !selectedNewsletter.message || (selectedNewsletter.imageCleared && !selectedNewsletter.newImage) ) {
      Swal.fire({
        icon: "info",
        title: "Pflichtfelder",
        html: "Bitte fülle alle Pflichtfelder aus und klicke erneut auf speichern.",
      });
      return;
    }

    onUpdate(
      {
        ...selectedNewsletter,
        title,
        message,
        externeUrl,
        //publishDate,
        isActive: isActive, //veröffentlicht
        isAdvertisement: isAdvertisement,
        imageCleared: imageCleared,
        //image: image, // alte Werte zum löschen, werden vom Backend behandelt
        //pdfFile: pdfFile, // alte Werte zum löschen, werden vom Backend behandelt
        pdfCleared: pdfCleared, // wenn pdfCleared && !newPdfFile pdf löschen!
        newImage: newImage, // Tracken, ob neue Datei
        newPdfFile: newPdfFile, // Tracken, ob neue Datei
      }
    );
    handleClose();
  };

  const handleImageInputChange = (event) => {
    setChanged(true);
    const file = event.target.files[0];
    setNewImage(file);
    setImageUrl(URL.createObjectURL(file));
    /*
      const selectedFile = event.target.files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        setImageUrl(reader.result);
        //setShowModal(true); // crop Modal
      };
      reader.readAsDataURL(selectedFile);
    */
  };

  const handlePDFInputChange = (event) => {
    setChanged(true);
    const file = event.target.files[0];
    setNewPdfFile(file);
    //setImageUrl(URL.createObjectURL(file));
    /*
      const selectedFile = event.target.files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        setImageUrl(reader.result);
        //setShowModal(true); // crop Modal
      };
      reader.readAsDataURL(selectedFile);
    */
  };


  /*
  const handleFileInputChange = (event) => {
    console.log("selected");
    const selectedFile = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setImageUrl(reader.result);
      setShowModal(true);
    };

    reader.readAsDataURL(selectedFile);
  };
  */

  /*
  const handleCrop = () => {
    const cropper = cropperRef.current.cropper;
    const croppedImageUrl = cropper.getCroppedCanvas().toDataURL("image/jpeg");
    setCroppedImageUrl(croppedImageUrl);
    setShowModal(false);
  };
  */

  const formatDateTime = (dateVal) => {
    
    const date = new Date(dateVal);
    const timezoneOffsetInMinutes = date.getTimezoneOffset();
    date.setMinutes(date.getMinutes() - timezoneOffsetInMinutes);

    const tag = date.toISOString().slice(0, 10);
    const zeit = date.toISOString().slice(11, 16);
    return `${tag}T${zeit}`;
  };
  
  
  
  
  




  return (
    <Modal show={show} onHide={handleClose} size="lg" style={{marginBottom:"30px"}}>
      <Modal.Header closeButton>
        <Modal.Title>Newsletter bearbeiten</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Alle Änderungen werden erst wirksam, wenn du den "Änderungen Speichern" Button drückst.</p>
        <Form>
          <Form.Group controlId="isActiveModal">
            <Form.Check
              type="checkbox"
              label="Newsletter veröffentlichen"
              id="modal-isActive"
              checked={isActive}
              onChange={(e) => {
                setChanged(true);
                if (isAdvertisement && isActive) {
                  Swal.fire({
                    icon: "info",
                    title: "Status",
                    text: "Angebote müssen direkt veröffentlicht werden.",
                  });
                } else {
                  setIsActive(e.target.checked);
                }
              }}
            />
          </Form.Group>
          <Form.Group controlId="isAdvertisementModal">
            <Form.Check
              type="checkbox"
              label="Beinhaltet Angebot (Newsletter ist nach speichern nicht mehr bearbeitbar!)"
              checked={isAdvertisement}
              onChange={(e) => {
                setChanged(true);
                setIsAdvertisement(e.target.checked);
                setIsActive(true);
              }}
            />
          </Form.Group>

          <Form.Group controlId="formTitle">
            <Form.Label>Titel (Pflicht)</Form.Label>
            <Form.Control
              type="text"
              value={title}
              onChange={(e) => {
                setChanged(true);
                setTitle(e.target.value);
              }}
            />
          </Form.Group>

          <Form.Group controlId="formMessage">
            <Form.Label>Nachricht (Pflicht)</Form.Label>
            <Form.Control
              as="textarea"
              rows={10}
              value={message}
              onChange={(e) => {
                setChanged(true);
                setMessage(e.target.value);
              }}
            />
          </Form.Group>

         
         {/*
          <Form.Label>
            Gib ein Datum ein, ab wann die News in der App
            angezeigt werden sollen. (Pflicht)
          </Form.Label>
          <Form.Control
            type="datetime-local"
            name="datum"
            value={publishDate ? formatDateTime(publishDate) : formatDateTime(new Date())}
            min={new Date()}
            onChange={(e) =>{ 
              setChanged(true);
              setPublishDate(e.target.value);
            }}
          />
          */}
          
          {/*
          <Form.Group>
            <Form.Label>Bild (.png .jpg)</Form.Label>
            <Form.Control
              type="file"
              accept="image/png, image/jpeg, image/jpg"
              id="newsletterImageUpdate"
              onChange={handleFileInputChange}
            />
          </Form.Group>
          */}

          <br />
          <hr />

          <Form.Label className="mt-3">Bild (.jpg, .png - Pflicht)</Form.Label>
          {!imageUrl && 
            <Form.Control
              id="newsletterImage"
              name="newsletterImage"
              type="file"
              accept="image/png, image/jpeg, image/jpg"
              value={image ? image.originalname : null}
              onChange={handleImageInputChange}
            />
          }

          {/*imageUrl && 
            <>
              <p className="mt-3">Aktuell hinterlegtes Bild</p>
              <img
                src={imageUrl}
                alt="imgPreview"
                style={{
                  width: "auto",
                  maxHeight: "200px",
                  border: "1px solid black",
                  margin: "10px 20px 10px 0px",
                }}
              />
              <Button
                style={{
                  margin: "10px 0px",
                  background: "red",
                  border: "none",
                }}
                onClick={() => {
                  setImage(null);
                  setImageUrl(null);
                }}
              >
                Bild verwerfen
              </Button>
            </>
          */}

          <FilePreviewRemoveButton
            imgUrl={imageUrl}
            handleRemove={() => {
              setImage(null);
              setImageUrl(null);
              setImageCleared(true);
            }}
          />




          {/*
          <Col xs={12}>
            {imageUrl && showModal && (
              <div>
                <Cropper
                  src={imageUrl}
                  style={{ height: 400, width: "100%" }}
                  initialAspectRatio={1}
                  guides={false}
                  ref={cropperRef}
                />
                <Button variant="primary" onClick={handleCrop}>
                  Crop Image
                </Button>
              </div>
            )}
            {croppedImageUrl && <img src={croppedImageUrl} alt="modified" />}
          </Col>
          */}

          <br />
          <hr />

          <Form.Group>
            <Form.Label>PDF</Form.Label>
            {(!newPdfFile && !pdfFile) &&
              <Form.Control
                className="mb-3"
                id="pdf"
                type="file"
                name="pdf-file"
                accept="application/pdf"
                value={pdfFile ? pdfFile.originalname : ""}
                onChange={handlePDFInputChange}
              />
            }
          </Form.Group>

            {(pdfFile && !newPdfFile) &&
              <>
                <p
                  style={{display: "inline"}}
                >
                  {pdfName?.substring(36)}
                </p>
                <Button
                  variant={"danger"}
                  style={{
                    margin: "8px",
                    border: "none",
                  }}
                  onClick={() => {
                    setChanged(true);
                    setPdfFile(null);
                    setPdfCleared(true);
                  }}
                >
                  PDF verwerfen
                </Button>
              </>
            }

            {newPdfFile &&
              <>
                <p
                  style={{display: "inline", paddingRight: "15px"}}
                >
                  {newPdfFile.name}
                </p>
                <Button
                  variant={"danger"}
                  style={{
                    margin: "8px",
                    border: "none",
                  }}
                  onClick={() => setNewPdfFile(null)}
                >
                  PDF verwerfen
                </Button>
              </>
            }
 
         


          <Form.Group>
            <Form.Label>Externe Url (Blogeinträge, ...)</Form.Label>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">https://</span>
                </div>
                <Form.Control
                  type="text"
                  name="externeUrl"
                  value={externeUrl ? externeUrl : ""}
                  placeholder="Externe URL"
                  onChange={(e) =>{ 
                    setChanged(true);
                    setExternalUrl(e.target.value);
                  }}
                />
              </div>
          </Form.Group>

        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="danger"
          onClick={() => deleteNewsLetter(selectedNewsletter._id)}
        >
          Newsletter löschen
        </Button>
        <Button variant="secondary" onClick={handleClose}>
          Schließen
        </Button>

        {changed && (
          <Button variant="primary" onClick={handleSubmit}>
            Änderungen Speichern
          </Button>
        )}
        {!changed && (
          <Button variant="primary" style={{ opacity: "0.5" }}>
            Änderungen Speichern
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default EditNewsletterModal;
