import React from "react";
import App from "./App";

// React bis V17
import ReactDOM from "react-dom";

// React ab v18
//import ReactDOM from 'react-dom/client';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

/* 

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
  <App />
</React.StrictMode>,
);
*/
