import React from 'react'
import { Row, Col } from "react-bootstrap"
import { COLORS } from '../constants'

import SelectBetreuer from "../Bausteine/SelectBetreuer";


function InputKontaktdaten(props) {


  return (
    <fieldset className="border" style={{background: "white", borderRadius: "8px"}}>
      <legend className="float-none w-auto p-2" style={{color: COLORS.primCol, background: COLORS.primBG, borderRadius: "8px", fontSize: "12px"}}>
        Kontakt
      </legend>
      <Row>
        <Col xs={12} md={12}>

          <SelectBetreuer
            label={"Mitarbeiter Kunden-Betreuung"}
            setSelect={props.setSelect}
            selectedValue={props.data.mitarbeiter? props.data.mitarbeiter.value : ""}
            mitarbeiter={props.alterMitarbeiter}
          />

          {JSON.stringify(props.alterMitarbeiter)}
          <br />

          <p className="">Sprache: <span>{props.data.sprache ? props.data.sprache : "deutsch"}</span></p>

          <p>
            {props.data.email && <><a href={'mailto:'+props.data.email} style={{color: "#c58164"}}>E-Mail</a>: </>}
            <span>{props.data.email ? props.data.email : <i>keine E-Mail hinterlegt</i>}</span>
          </p>

          <p>
          {props.data.telefon && <><a href={'tel:'+props.data.telefon} style={{color: "#c58164"}}>Telefon</a>: </>}
            <span> {props.data.telefon ? props.data.telefon : <i>kein Telefon hinterlegt</i>}</span>
          </p>

          <p>
            {props.data.mobil && <><a href={'tel:'+props.data.mobil} style={{color: "#c58164"}}>Mobil</a>: </>}
            <span> {props.data.mobil ? props.data.mobil : <i>keine Mobilnummer hinterlegt</i>}</span>
          </p>
              
          <p>
            {props.data.webseite && <><a href={props.data.webseite} target="_blank" rel="nofollow noreferrer" style={{color: "#c58164"}}>Webseite</a>: </>}
            <span> {props.data.webseite ? props.data.webseite : <i>keine Webseite hinterlegt</i>}</span>
          </p>

          <p>Erreichbar:
              {props.data.erreichbar?.map((erreichbar, i, {length}) => {
                
                  if(i == 0) {
                    return(<span> {erreichbar}</span>);
                  }
                  else if(length -1 === i) {
                    return(<span> {erreichbar}</span>);
                  }
                  else {
                    return(<span>, {erreichbar}</span>);
                  }
                
            })}
            {(!props.data.erreichbar || props.data.erreichbar.length == 0) && 
              <span><i> keine Erreichbarkeit hinterlegt</i></span>
            }
          </p>

         
        </Col>
      </Row>
    </fieldset>
  );
}
export default InputKontaktdaten