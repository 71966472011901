import React, { useEffect, useState, useRef } from "react";
import { Modal, Button, Form, Col } from "react-bootstrap";
//import Cropper from "react-cropper";
//import "cropperjs/dist/cropper.css";
import { getCurrentDateTime, getCurrentDateTimeEnd } from "./Gewinnspiel";
import Swal from "sweetalert2";
import FilePreviewRemoveButton from "../../Bausteine/FilePreviewRemoveButton";

function EditGewinnspielModal({
  show,
  handleClose,
  selectedGewinnspiel,
  onUpdate,
  deleteGewinnspiel,
}) {

  const [isActive, setIsActive] = useState(
    selectedGewinnspiel.isActive
  );

  const [title, setTitle] = useState(selectedGewinnspiel.title);
  const [message, setMessage] = useState(selectedGewinnspiel.message);

  const [publishDate, setPublishDate] = useState(
    selectedGewinnspiel.publishDate
  );
  const [startDate, setStartDate] = useState(selectedGewinnspiel.startDate);
  const [endDate, setEndDate] = useState(selectedGewinnspiel.endDate);


  const [changed, setChanged] = useState(false);

  const [imageCleared, setImageCleared] = useState(false);
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(selectedGewinnspiel.imageUrl);

  const [pdfCleared, setPdfCleared] = useState(false);
  const [pdfName, setPdfName] = useState(selectedGewinnspiel.pdfName);
  const [pdfFile, setPdfFile] = useState(selectedGewinnspiel.pdfFileUrl);

  const [newImage, setNewImage] = useState(null);
  const [newPdfFile, setNewPdfFile] = useState(null);

  //const [showModal, setShowModal] = useState(false);
  //const [croppedImageUrl, setCroppedImageUrl] = useState();
  //const cropperRef = useRef(null);


  const [initialLoad, setInitialLoad] = useState(true);
  useEffect(() => {
    if(initialLoad) {
      setPublishDate(valuePublishDate(publishDate, startDate));
      setInitialLoad(false);
    }
  }, [publishDate, startDate]);



  const handleSubmit = () => {
    const now = getCurrentDateTime();
    if (endDate < now) {
      Swal.fire({
        icon: "error",
        title: "Fehler",
        html: "Das Datum darf nicht in der Vergangenheit liegen",
      });
      return;
    }
    onUpdate({
      ...selectedGewinnspiel,
      isActive: isActive, //veröffentlicht
      title,
      message,
      publishDate,
      startDate,
      endDate,
      imageCleared: imageCleared,
      //image: image, // alte Werte zum löschen, werden vom Backend behandelt
      //pdfFile: pdfFile, // alte Werte zum löschen, werden vom Backend behandelt
      pdfCleared: pdfCleared, // wenn pdfCleared && !newPdfFile pdf löschen!
      newImage: newImage, // Tracken, ob neue Datei
      newPdfFile: newPdfFile, // Tracken, ob neue Datei
    });
  };

  const handleImageInputChange = (event) => {
    setChanged(true);
    const file = event.target.files[0];
    setNewImage(file);
    setImageUrl(URL.createObjectURL(file));
    /*
      const selectedFile = event.target.files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        setImageUrl(reader.result);
        //setShowModal(true); // crop Modal
      };
      reader.readAsDataURL(selectedFile);
    */
  };

  const handlePDFInputChange = (event) => {
    setChanged(true);
    const file = event.target.files[0];
    setNewPdfFile(file);
    //setImageUrl(URL.createObjectURL(file));
    /*
      const selectedFile = event.target.files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        setImageUrl(reader.result);
        //setShowModal(true); // crop Modal
      };
      reader.readAsDataURL(selectedFile);
    */
  };

  /*
  const handleFileInputChange = (event) => {
    console.log("selected");
    const selectedFile = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setImageUrl(reader.result);
      setShowModal(true);
    };

    reader.readAsDataURL(selectedFile);
  };
  */

  /*
  const handleCrop = () => {
    const cropper = cropperRef.current.cropper;
    const croppedImageUrl = cropper.getCroppedCanvas().toDataURL("image/jpeg");
    setCroppedImageUrl(croppedImageUrl);
    setShowModal(false);
  };
  */


  // Function to calculate the number of days between two dates
  function getDaysBetweenDates(dateString1, dateString2) {
    const date1 = new Date(dateString1);
    const date2 = new Date(dateString2);

    const diffInMilliseconds = Math.abs(date2 - date1);
    return Math.ceil(diffInMilliseconds / (1000 * 60 * 60 * 24));
  }


  const getCorrectedDateTime = (timeStr) => {
    const now = new Date(timeStr);
    const berlinTime = new Date(now.toLocaleString("en-US", {timeZone: "Europe/Berlin"}));
    const date = berlinTime.getFullYear()+'-'+('0'+(berlinTime.getMonth()+1)).slice(-2)+'-'+('0'+berlinTime.getDate()).slice(-2);
    const time = ('0'+berlinTime.getHours()).slice(-2) + ":" + ('0'+berlinTime.getMinutes()).slice(-2);
    return `${date}T${time}`;  
  };



  function isDate(value) {
    if (value instanceof Date) {
      // Check if it's a valid Date object
      return !isNaN(value);
    } else if (typeof value === 'string') {
      // Check if it's a valid ISO date string
      return /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/.test(value);
    }
    return false;
  }

  // Function to get the value for the Form.Select based on publishDate
  function valuePublishDate(publishDate, startDate) {
    if (isDate(publishDate) && isDate(startDate)) {
      // Calculate the days between publishDate and startDate
      const publish = new Date(publishDate);
      const start = new Date(startDate);
      const diffTime = Math.abs(publish - start);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
      return diffDays.toString();
    } else if (!isDate(publishDate)) {
      // If publishDate is not a valid Date or ISO date string, return the original publishDate value
      return publishDate;
    } else {
      // If publishDate is null, return null
      return null;
    }
  }
  


  return (
    <Modal show={show} onHide={handleClose} size="lg" style={{marginBottom:"30px"}}>
      <Modal.Header closeButton>
        <Modal.Title>Gewinnspiel bearbeiten</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="exampleCheckbox">
            <Form.Check
              type="checkbox"
              id="isActive"
              name="isActive"
              label="Gewinnspiel veröffentlichen"
              checked={isActive || false}
              onChange={(e) => {
                setChanged(true);
                setIsActive(e.target.checked);
              }}
            />
          </Form.Group>

          <Form.Group controlId="formTitle">
            <Form.Label>Titel (Pflicht)</Form.Label>
            <Form.Control
              type="text"
              value={title}
              onChange={(e) => {
                setChanged(true);
                setTitle(e.target.value);
              }}
            />
          </Form.Group>

          <Form.Group controlId="formMessage">
            <Form.Label>Nachricht (Pflicht)</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={message}
              onChange={(e) => {
                setChanged(true);
                setMessage(e.target.value);
              }}
            />
          </Form.Group>

          <hr />

          <Form.Label className="fw-bold">
            Gewinnspiel als Vorschau in der App anzeigen<br />(in dieser Zeit noch keine Teilnahme möglich)
          </Form.Label>
          <Form.Select
            aria-label="Default select"
            value={initialLoad ? valuePublishDate(publishDate, startDate) : publishDate}
            name="publishDate"
            onChange={(e) => {
              setChanged(true);
              setPublishDate(e.target.value);
            }}
          >
            <option>kein Vorlauf</option>
            <option value="1">1 Tag im Voraus</option>
            <option value="2">2 Tage im Voraus</option>
            <option value="3">3 Tage im Voraus</option>
            <option value="4">4 Tage im Voraus </option>
            <option value="5">5 Tage im Voraus</option>
            <option value="6">6 Tage im Voraus</option>
            <option value="7">7 Tage im Voraus</option>
          </Form.Select>

         
          <span className="fw-bold">Start & Ende der Teilnahme</span>

          <Form.Label>
            Gib ein Datum und eine Zeit ein, <b>ab wann</b> am Gewinnspiel
            teilgenommen werden kann.(Pflicht)
          </Form.Label>
          <Form.Control
            type="datetime-local"
            name="datum"
            value={getCorrectedDateTime(startDate) || getCurrentDateTime()}
            min={getCurrentDateTime()}
            onChange={(e) => {
              setChanged(true);
              setStartDate(e.target.value);
            }}
          />

          <Form.Label>
            Gib ein Datum und eine Zeit ein, <b>bis wann</b> am Gewinnspiel
            teilgenommen werden kann.(Pflicht)
          </Form.Label>
          <Form.Control
            type="datetime-local"
            name="datum"
            value={getCorrectedDateTime(endDate) || getCurrentDateTimeEnd()}
            min={getCurrentDateTime()}
            onChange={(e) => {
              setChanged(true);
              setEndDate(e.target.value);
            }}
          />

          <Form.Label className="mt-3">Bild (.jpg, .png - Pflicht)</Form.Label>
          {!imageUrl && (
            <Form.Control
              id="gewinnspielImage"
              name="gewinnspielImage"
              type="file"
              accept="image/png, image/jpeg, image/jpg"
              value={image ? image.originalname : null}
              onChange={handleImageInputChange}
            />
          )}

          {/*imageUrl && 
                        <>
                          <p className="mt-3">Aktuell hinterlegtes Bild</p>
                          <img
                            src={imageUrl}
                            alt="imgPreview"
                            style={{
                              width: "auto",
                              maxHeight: "200px",
                              border: "1px solid black",
                              margin: "10px 20px 10px 0px",
                            }}
                          />
                          <Button
                            style={{
                              margin: "10px 0px",
                              background: "red",
                              border: "none",
                            }}
                            onClick={() => {
                              setImage(null);
                              setImageUrl(null);
                            }}
                          >
                            Bild verwerfen
                          </Button>
                        </>
                      */}

          <FilePreviewRemoveButton
            imgUrl={imageUrl}
            handleRemove={() => {
              setImage(null);
              setImageUrl(null);
              setImageCleared(true);
            }}
          />

          {/*
          <Col xs={12}>
            {imageUrl && showModal && (
              <div>
                <Cropper
                  src={imageUrl}
                  style={{ height: 400, width: "100%" }}
                  initialAspectRatio={1}
                  guides={false}
                  ref={cropperRef}
                />
                <Button variant="primary" onClick={handleCrop}>
                  Crop Image
                </Button>
              </div>
            )}
            {croppedImageUrl && <img src={croppedImageUrl} alt="modified" />}
          </Col>
          */}

          <br />
          <hr />

          <Form.Group>
            <Form.Label>PDF</Form.Label>
            {(!newPdfFile && !pdfFile) &&
              <Form.Control
                className="mb-3"
                id="pdf"
                type="file"
                name="pdf-file"
                accept="application/pdf"
                value={pdfFile ? pdfFile.originalname : ""}
                onChange={handlePDFInputChange}
              />
            }
          </Form.Group>

            {(pdfFile && !newPdfFile) &&
              <>
                <p
                  style={{display: "inline"}}
                >
                  {pdfName?.substring(36)}
                </p>
                <Button
                  variant={"danger"}
                  style={{
                    margin: "8px",
                    border: "none",
                  }}
                  onClick={() => {
                    setChanged(true);
                    setPdfFile(null);
                    setPdfCleared(true);
                  }}
                >
                  PDF verwerfen
                </Button>
              </>
            }

            {newPdfFile &&
              <>
                <p
                  style={{display: "inline", paddingRight: "15px"}}
                >
                  {newPdfFile.name}
                </p>
                <Button
                  variant={"danger"}
                  style={{
                    margin: "8px",
                    border: "none",
                  }}
                  onClick={() => setNewPdfFile(null)}
                >
                  PDF verwerfen
                </Button>
              </>
            }

        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="danger"
          onClick={() => deleteGewinnspiel(selectedGewinnspiel._id)}
        >
          Gewinnspiel Löschen
        </Button>
        <Button variant="secondary" onClick={handleClose}>
          Schließen
        </Button>

        {changed && (
          <Button variant="primary" onClick={handleSubmit}>
            Änderungen Speichern
          </Button>
        )}
        {!changed && (
          <Button variant="primary" style={{ opacity: "0.5" }}>
            Änderungen Speichern
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default EditGewinnspielModal;
