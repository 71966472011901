/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
//import { MainContext } from "../contexts/MainContext";
import { useNavigate, useParams, Link } from "react-router-dom";

import Swal from "sweetalert2";
import axios from "axios";

//import './Login.css'

const PasswordReset = () => {
  const { id, token } = useParams();

  const [newPassword, setNewPassword] = useState("");
  /*const [resetData, setResetData] = useState('');

  
  const { jwt, setJwt, user, setUser } = useContext(MainContext)*/

  const navigate = useNavigate();

  // Display message for amount of time
  /*
  const loading = (time) => new Promise((resolve) => setTimeout(resolve, time))
  const alertToggle = async (msg) => {
    setAlertText(msg)
    setShowAlert(true)
    await loading(3000)
    setShowAlert(false)
  }
  */

  const handleChange = (event) => {
    switch (event.target.name) {
      case "newPassword":
        setNewPassword(event.target.value);
        break;
      default:
        break;
    }
  };

  const checkNewPassword = (password) => {
    const minLength = 10;
    const complexityRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#!_\-=?])[A-Za-z0-9#!_\-=?]{10,}$/;

    if (password.length < minLength) {
      alert("Das neue Passwort muss min 10 Zeichen lang sein!");
      return false;
    }

    if (!complexityRegex.test(password)) {
      alert("Das neue Passwort enspricht nicht Anforderungen.");
      return false;
    }

    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!checkNewPassword(newPassword)) {
      return;
    }

    axios
      .post(process.env.REACT_APP_BACKEND + "/api/v1/user/new-password", {
        newPassword: newPassword,
        id: id,
        token: token,
      })
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Erfolg",
          html: "Neues Passwort wurde erfolgreich übernommen. Du kannst dich ab sofort damit einloggen.",
        });
        navigate("/");
      })
      .catch((err) => {
        if (err.response?.data?.message) {
          Swal.fire({
            icon: "error",
            title: "Fehler",
            html: err.response.data.message,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Fehler",
            html: "Das neue Passwort entspricht nicht den Passwort-Richtlinien.",
          });
        }
      });
  };

  // Mit useParams
  useEffect(() => {
    if (!id || !token) {
      // Wenn kein Token und ID Redirect zum Login.
      navigate("/");
    }
  }, [id, token]);

  return (
    <div className="Auth-form-container">
      <form className="Auth-form">
        <div className="Auth-form-content">
          <h3 className="Auth-form-title">Passwort Reset</h3>
          <p className="text-center">Anforderungen an ein neues Passwort</p>
          <ul className="mt-1">
            {newPassword.length >= 10 ? (
              <li style={{ color: "#4BB543" }}>
                <b>min. 10 Zeichen</b>
              </li>
            ) : (
              <li>min. 10 Zeichen</li>
            )}
            {/^(?=.*[A-Z]).*$/.test(newPassword) ? (
              <li style={{ color: "#4BB543" }}>
                <b>min. 1 Großbuchstabe</b>
              </li>
            ) : (
              <li>min. 1 Großbuchstabe</li>
            )}
            {/^(?=.*[a-z]).*$/.test(newPassword) ? (
              <li style={{ color: "#4BB543" }}>
                <b>min. 1 Kleinbuchstabe</b>
              </li>
            ) : (
              <li>min. 1 Kleinbuchstabe</li>
            )}
            {/\d+/.test(newPassword) ? (
              <li style={{ color: "#4BB543" }}>
                <b>min. 1 Zahl</b>
              </li>
            ) : (
              <li>min. 1 Zahl</li>
            )}
            {/^(?=.*[#!_\-=?]).*$/.test(newPassword) ? (
              <li style={{ color: "#4BB543" }}>
                <b>min. 1 Sonderzeichen: #!_-?=</b>
              </li>
            ) : (
              <li>min. 1 Sonderzeichen: #!_-?=</li>
            )}
          </ul>
          <div className="form-group mt-3">
            <label>Neues Passwort festlegen</label>
            <input
              type="password"
              className="form-control mt-1"
              placeholder="Neues Password"
              label="Neues Password festlegen"
              id="newPassword"
              name="newPassword"
              value={newPassword}
              onChange={(e) => handleChange(e)}
              required
            />
          </div>
          <div className="d-grid gap-2 mt-3">
            {/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#!_\-=?])[a-zA-Z0-9#!_\-=?]{10,}$/.test(
              newPassword
            ) ? (
              <button
                type="submit"
                className="btn btn-primary"
                onClick={handleSubmit}
              >
                Passwort festlegen
              </button>
            ) : (
              <button type="submit" className="btn btn-primary disabled">
                Passwort festlegen
              </button>
            )}
          </div>
          <p className="text-center mt-4">
            <Link to="/">Zurück zum Login</Link>
          </p>
        </div>
      </form>
    </div>
  );
};

export default PasswordReset;
