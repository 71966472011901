import React, { useState, useEffect, useContext } from "react";
import "./SideNavBar.css";

import { Scrollbar } from 'react-scrollbars-custom';

import { useNavigate } from "react-router-dom";
import { MainContext } from "../../contexts/MainContext";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faLineChart, faUser, faUsers, faList, faPenNib, faPenToSquare, faArrowRightArrowLeft, faCog, faSignOut, faGift, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { faCommentDots } from "@fortawesome/free-regular-svg-icons";

const SideNavBar = (props) => {

	const navigate = useNavigate();
	
	const { user } = useContext(MainContext);

	const [userRole, setUserRole] = useState("");

	useEffect(() => {
	  
	  if(user.roleId) {
		setUserRole(user.roleId);
	  }
	  else {
		setUserRole("0");
	  }
		
	}, [user]);



	const [isExpanded, setExpendState] = useState(false);


	const menuItems = [
		{
			text: "Home",
			icon: faHome,
			access: "read",
            navigate: "/dash/home"
		},
		{
			text: "Analyse",
			icon: faCommentDots,
            access: "readPageAnalyse",
            navigate: "/dash/analyse"
		},
		{
			text: 'Abo Verwaltung',
			icon: faUserPlus,
            access: "readPageKundenAbo",
            navigate: "/dash/abo"
		},
		/*{
			text: "Kunde Details",
			icon: faUser,
            access: "readPageKundeEdit",
            navigate: "/dash/kunde"
		},
		*/
		{
			text: "Kunden",
			icon: faUsers,
            access: "readPageKunden",
            navigate: "/dash/kunden"
		},
		{
			text: "Kunden Bestätigungen",
			icon: faPenNib,
            access: "readPageBestaetigungen",
            navigate: "/dash/bestaetigungen"
		},
        {
			text: "Aufgaben",
			icon: faList,
            access: "readPageAufgaben",
            navigate: "/dash/aufgabe"
		},
		{
			text: "Newsletter",
			icon: faPenToSquare,
            access: "readPageNewsletter",
            navigate: "/dash/newsletter"
		},
		/*{
			text: "Gewinnspiele",
			icon: faGift,
            access: "readPageGewinnspiel",
            navigate: "/dash/gewinnspiele"
		},
		*/
		{
			text: "Einstellungen",
			icon: faCog,
            access: "readPageEinstellungen",
            navigate: "/dash/mitarbeiter"
		}
	];


	const handleNavigation = (targetURL) => {
		navigate(targetURL);
	}

	return (
		<div
			className={
				isExpanded
					? "side-nav-container"
					: "side-nav-container side-nav-container-NX"
			}
			style={{zIndex: "110"}}
		>
			<div className="nav-upper">
				<div className="nav-heading">
					{isExpanded && (
						<div className="nav-brand">
							{/*<img src="images/VIN1.png" alt="" srcSet="" />*/}
							<h4 className="mt-4" style={{paddingLeft: "30px"}}>Menü</h4>
						</div>
					)}
					<button
						className={
							isExpanded ? "hamburger hamburger-in" : "hamburger hamburger-out"
						}
						onClick={() => setExpendState(!isExpanded)}
					>
						<span></span>
						<span></span>
						<span></span>
					</button>
				
				</div>

			  <Scrollbar style={{ width: "auto", height: `calc(100vh - 110px)` }}>

				<div className="nav-menu">

					{menuItems.map((item) => {
						
						// if user.access != item.access => Benutzerrechte müssen Seite freigeben.
						if( (item.access == 'readPageEinstellungen' || item.access == 'readPageExklusivmakler' || item.access == 'readPageVorteilspartner' ) && userRole != 1) {
							return null; // skip element
						}
						//added in login action to user Context!
						else if( (item.access == 'readPageKundenAbo' ) && !user.partnerAboNeedsAccept) {
							return null; // skip element
						}
						else {
							return (
								<a
									key={item.navigate}
									className={isExpanded ? "menu-item" : "menu-item menu-item-NX"}
									onClick={() => handleNavigation(item.navigate)}
								>
									<FontAwesomeIcon
										className="menu-item-icon"
										style={{paddingRight: isExpanded ? "15px" : "0px"}}
										icon={item.icon}
										size="xl"
									/>
									{/*<img className="menu-item-icon" src={item.icon} alt="" srcSet="" />*/}
									{isExpanded && <p>{item.text}</p>}
								</a>
							)
						}
					}
					)}


					<a
						key={"logout"}
						className={isExpanded ? "menu-item" : "menu-item menu-item-NX"}
						onClick={props.handleLogout}
					>
						<FontAwesomeIcon
							onClick={props.handleLogout}
							className="menu-item-icon"
							style={{paddingRight: isExpanded ? "15px" : "0px"}}
							icon={faSignOut}
							size="lg"
						/>
						{isExpanded && <p>Logout</p>}
					</a>
				{/*<img className="logout-icon" src="icons/logout.svg" alt="" srcSet="" />*/}


		


				</div>
			</Scrollbar>

			</div>
			{/*<div className="nav-footer">
				{isExpanded && (
					<h5 style={{display: "flex", justifyContent: "center", alignItems: "center", paddingLeft: "15px", margin: "0px"}}>Logout</h5>
				)}

				<FontAwesomeIcon
					onClick={props.handleLogout}
					className="logout-icon"
					style={{marginLeft: "8px", width: "1.2em", height: "1.2em"}}
					icon={faSignOut}
					size="lg"
				/>
				{//<img className="logout-icon" src="icons/logout.svg" alt="" srcSet="" />}
			</div>*/}
		</div>
	);
};

export default SideNavBar;