import React, {useState, useEffect} from "react";
import ReactSelect, { createFilter }  from "react-select";

import { Button, Col, Row, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

//import Länder from '../Data/LaenderListe';

import AuthAxios from "../utils/AuthAxios";
import Swal from 'sweetalert2'

function SelectBetreuer (props) {

    const [mitarbeiter, setMitarbeiter] = useState([]);

    const [selected, setSelected] = useState("");

    useEffect(() => {
      
        async function getMitarbeiter () {
            try {
                const res = await AuthAxios.get('/user');

                const mitarbeiterList = [];
                
                let aktiveMitarbeiter = res.data.filter((ma) => ma.loginGesperrt !== true);

                aktiveMitarbeiter.map(ma => {
                    mitarbeiterList.push({"value": ma.mitarbeiterId, "label": ma.mitarbeiterId+" | "+ma.vorname+" "+ma.nachname});
                });

                setMitarbeiter(mitarbeiterList);
            }
            catch(err) {
                console.log(err);
                Swal.fire({
                    icon: 'error',
                    title: "Fehler",
                    html: "Mitarbeiter konnten nicht geladen werden."
                });
            }
        }

        getMitarbeiter();
      
    }, []);


    const handleRemove = () => {
        setSelected("");
        props.setSelect("mitarbeiter", {});
    }


    // selectedOption ist wie "event"
    const handleChange = (name, selection) => {

        setSelected({label: name, value: selection.value})

        // Object
        props.setSelect(name, {label: selection.label, value: selection.value});

        //nur ID..
        //props.setSelect(name, selection.value);
    };


    return (
        <Row>
            <Form.Label>{props.label ?? "Verknüpfter Mitarbeiter"}</Form.Label>
            <Col xs={12}>
                <ReactSelect
                  value={props.selectedValue ? mitarbeiter.filter((f) => f.value == props.selectedValue)  : ""}
                  onChange={(selection, e) => {
                    if (e.action === 'clear') {
                        handleRemove();
                    } else {
                        handleChange(e.name, selection);
                    }
                  }}
                  placeholder={<div className="select-placeholder-text">Suche nach Mitarbeiter</div>}
                  options={mitarbeiter}
                  noOptionsMessage={() => "Suche nach Mitarbeiter um Optionen zu erhalten."}
                  filterOption={createFilter({ignoreAccents: false})}
                  name="mitarbeiter"
                  //onInputChange={handleInputChange}
                  isClearable={true}
                />
                {props.alterMitarbeiter && !mitarbeiter.includes(props?.alterMitarbeiter?.value) &&
                    <span style={{fontSize: "90%"}}>Inaktiver Mitarbeiter: {props.alterMitarbeiter.label}</span>
                }

            </Col>
            {/*
            <Col xs={1} md={1}>
            {selected !== "" &&
                <Button style={{maxWidth: "40px", aspectRatio: "1"}} onClick={handleRemove}>
                    <FontAwesomeIcon
                        icon={faXmark}
                        size="1x"
                        title="Betreuer entfernen"
                    />
                </Button>
            }
            </Col>*/}
        </Row>
    );
      
}

export default SelectBetreuer