
import { Row, Col } from "react-bootstrap";

import MitarbeiterListeAktiv from "./MitarbeiterListeAktiv";
import MitarbeiterListeInaktiv from "./MitarbeiterListeInaktiv";

import moment from 'moment';



function MitarbeiterListe(props) {

  const aktiveMitarbeiter = (props.data.filter((f) => f.loginGesperrt !== true));


  return (
    <>
      <Row className="pb-4">
        <Col style={{paddingLeft: "30px"}}>
          <h5>Lizenzen</h5>
          <p><b>{aktiveMitarbeiter.length ?? 1} von {props.lizenzen?.lizenzen ?? "x"} Lizenzen</b>
          {" "}in Verwendung. Lizenzlaufzeit bis: <b>{moment(props.lizenzen?.lizenzLaufzeit).format('DD.MM.YYYY')}</b>. Als verwendete Lizenz zählt jeder aktive Mitarbeiter.</p>

        </Col>
      </Row>
      <Row className="pb-4">
        <h5 style={{paddingLeft: "30px"}}>Aktive Mitarbeiter</h5>
        <MitarbeiterListeAktiv data={props} />
      </Row>
      <Row>
        <h5 style={{paddingLeft: "30px"}}>Inaktive Mitarbeiter - kein Login möglich</h5>
        <MitarbeiterListeInaktiv data={props} />
      </Row>
      
  
    </>
  );

  }
  

export default MitarbeiterListe