import React, { useContext, useState, useEffect } from "react";
import { MainContext } from '../contexts/MainContext';
import { useNavigate } from 'react-router-dom';
import AuthService from '../services/auth.service';

import 'bootstrap/dist/css/bootstrap.min.css';

//https://reactrouter.com/en/main/components/outlet
import { Outlet } from 'react-router-dom';

import MainNavbar from "../Layout/MainNavbar";
import SideNavBar from "../Layout/Sidebar/SideNavBar";



const Dash = () => {

  const { jwt, setJwt, user, setUser } = useContext(MainContext);
  //const [parsedData, setParsedData] = useState('')
  const navigate = useNavigate();
  const logout = () => {
    AuthService.logout()
    setJwt('')
    setUser('')
    return navigate('/')
  }

  //Refresh Page Stay Logged In
  useEffect(() => {

    try
    {
      setJwt(sessionStorage.getItem('token'));
      setUser(JSON.parse(sessionStorage.getItem('user')));
    }
    catch (error)
    {
      setJwt('');
      setUser('')
    }
  }, []);


  /*
  useEffect(() => {
    try {
      return setParsedData(JSON.parse(atob(jwt.split('.')[1])))
    } catch (error) {
      AuthService.logout()
      setJwt('');
      setUser('');
       Swal.fire({
        icon: "error",
        title: "Fehler",
        html: "Anmeldung nicht im richtigen Format!"
      });
      return navigate('/');
    }
  }, [jwt, navigate, setJwt])
  */


  const handleLogout = () => {
    logout();
    navigate('/')
  }   

  return (
    <>
      {/* Rendert in jede Unterseite (nach Login) die Haupt und die Seitennavigation */}

      <MainNavbar data={user} handleLogout={handleLogout} />
      <SideNavBar handleLogout={handleLogout}/>
 
      {/* Rendert den Rest der Components, ähnlich wie Childs */}
      <div style={{ marginLeft: "85px", width: "calc(100% - 85px)" }}>
        <Outlet />
      </div>
    </>
  );
};

export default Dash;