import Table from "react-bootstrap/Table";
import { downloadPDF } from "../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import EditGewinnspielModal from "./EditGewinnspielModal";
import Swal from "sweetalert2";
import AuthAxios from "../../utils/AuthAxios";
import b64toBlob from "../../utils/cropper";
import { Button } from "react-bootstrap";


function GewinnspielTable({ currentGewinnspiel, getGewinnspieleFromUser, updateGewinnspielData }) {

  const [selectedGewinnspiel, setSelectedGewinnspiel] = useState();
  const [showEditModal, setShowEditModal] = useState(false); // Add this state for the modal

  const [spinner, setSpinner] = useState(false);

  async function deleteGewinnspiel(gewinnspielId) {
    try {
      const response = await AuthAxios.delete(`/gewinnspiel/${gewinnspielId}`);
      console.log(response);
      setShowEditModal(false);
      Swal.fire({
        icon: "success",
        title: "Gewinnspiel erfolgreich gelöscht",
        showConfirmButton: false,
        timer: 1500,
      });
      getGewinnspieleFromUser();
    } catch (error) {
      console.log(error);
    }
  }


  const handleUpdate = async (updatedGewinnspiel, croppedImageAsBase64) => {

    try {

      if (!updatedGewinnspiel.title || !updatedGewinnspiel.message || !updatedGewinnspiel.startDate || !updatedGewinnspiel.endDate || !updatedGewinnspiel.publishDate || (updatedGewinnspiel.imageCleared && !updatedGewinnspiel.newImage) ) {
        Swal.fire({
          icon: "info",
          title: "Pflichtfelder",
          html: "Bitte fülle alle Pflichtfelder aus und klicke erneut auf speichern.",
        });
        return;
      }

      setSpinner(true);
  
      const formData = new FormData();
      formData.append("isActive", updatedGewinnspiel.isActive);
      formData.append("title", updatedGewinnspiel.title);
      formData.append("message", updatedGewinnspiel.message);
      
      formData.append("publishDate", updatedGewinnspiel.publishDate);
      formData.append("startDate", updatedGewinnspiel.startDate);
      formData.append("endDate", updatedGewinnspiel.endDate);

      //Dateien
      if(updatedGewinnspiel.newImage) {
        formData.append("newImage", updatedGewinnspiel.newImage);
      }

      // Altes PDF löschen + durch neues ersetzen
      if(updatedGewinnspiel.newPdfFile) {
        formData.append("newPdfFile", updatedGewinnspiel.newPdfFile);
      }

      // Altes PDF löschen
      if(updatedGewinnspiel.pdfCleared && !updatedGewinnspiel.newPdfFile) {
        formData.append("pdfCleared", updatedGewinnspiel.pdfCleared);
      } 

      AuthAxios({
        method: 'post',
        url: "/gewinnspiel/"+updatedGewinnspiel._id,
        headers: { "Content-Type": "multipart/form-data" }, // Mixed Data with Files and JSON
        data: formData
      })
      .then((res) => {

        //Update Newsletter in Table
        updateGewinnspielData(res.data);

        Swal.fire({
          icon: "success",
          title: "Erfolg",
          html: "Gewinnspiel erfolgreich aktualisiert!",
        });
        
        setSpinner(false);

      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Formular",
          html: "Es ist ein Fehler aufgetreten. Bitte erneut versuchen.",
        });
        setSpinner(false);
      });
    }

    catch(error) {
      Swal.fire({
        icon: "error",
        title: "Fehler",
        html: "Gewinnspiel konnte nicht aktualisiert werden.",
      });
      setSpinner(false);
    }

    /*

      const formData = new FormData();
      
      let gewinnspielImageFile =
        document.getElementById("gewinnspielImage").files[0];
      if (croppedImageAsBase64) {
        const base64Image = croppedImageAsBase64.split(",")[1];
        const blob = b64toBlob(base64Image, "image/jpeg");

        // Convert Blob to File
        const fileName = "croppy.jpg";
        const file = new File([blob], fileName, {
          type: "image/jpeg",
        });
        formData.append("files", file);
      } else {
        formData.append("files", gewinnspielImageFile);
      }
      const pdfImage = document.getElementById("pdfImageUpdate");
      formData.append("title", updatedGewinnspiel.title);
      formData.append("gewinnspielId", updatedGewinnspiel._id);
      formData.append("isActive", updatedGewinnspiel.isActive);
      formData.append("message", updatedGewinnspiel.message);
      formData.append("publishDate", updatedGewinnspiel.publishDate);

      const pdfImageFile = pdfImage.files[0];

      formData.append("files", pdfImageFile);
      console.log(formData);

      const response = await AuthAxios.put("/gewinnspiel", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      Swal.fire({
        icon: "success",
        title: "Erfolg",
        html: "Gewinnspiel erfolgreich gelöscht",
        timer: 1500,
      });

      console.log(response);
      getGewinnspieleFromUser();
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Gewinnspiel konnte nicht aktualisiert werden",
        showConfirmButton: false,
        timer: 1500,
      });
    }

    /* Update the gewinnspiel list, you may need to adjust this to work with your data handling
    const updatedGewinnspiel = currentGewinnspiel.map((newsletter) =>
      gewinnspiel._id === updatedGewinnspiel._id ? updatedGewinnspiel : gewinnspiel
    );
    setCurrentGewinnspiel(updatedGewinnspiel);*/
  };


  const handleGewinnAusgeliefert = (gewinnspielId) => {

    
    AuthAxios.post('/gewinnspiel/ausgeliefert/'+gewinnspielId)
    .then(res => {

    })
    .catch(err => {
      console.log(err);
    })
    
  }


  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Vorschau</th>
          <th>Start</th>
          <th>Ende</th>
          <th>Bild</th>
          <th>PDF</th>
          <th>Titel</th>
          <th>Nachricht</th>
          <th>Gewinner</th>

          <th>Status</th>
          <th>Aktionen:</th>
        </tr>
      </thead>
      <tbody>
        {currentGewinnspiel.map((gewinnspiel) => (
          <tr key={gewinnspiel._id}>
            <td>{gewinnspiel.publishDate &&
              new Intl.DateTimeFormat('de-DE', {
              month: '2-digit',
              day: '2-digit',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
              }).format(new Date(gewinnspiel.publishDate))}
            </td>
            <td>{gewinnspiel.startDate &&
              new Intl.DateTimeFormat('de-DE', {
              month: '2-digit',
              day: '2-digit',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
              }).format(new Date(gewinnspiel.startDate))}
            </td>
            <td>{gewinnspiel.endDate &&
              new Intl.DateTimeFormat('de-DE', {
              month: '2-digit',
              day: '2-digit',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
              }).format(new Date(gewinnspiel.endDate))}
            </td>
            <td>
              {gewinnspiel.imageUrl ? (
                <img
                  style={{
                    height: "100px",
                    width: "auto",
                    cursor: "pointer"
                  }}
                  src={gewinnspiel.imageUrl}
                  alt=""
                />
              ) : (
                <>-</>
              )}
            </td>

            {gewinnspiel.pdfFileUrl ? (
              <td onClick={() => downloadPDF(gewinnspiel.pdfFileUrl)}>
                <a href=''>
                  PDF öffnen
                </a>
              </td>
            ) : (
              <td>-</td>
            )}

            <td>{gewinnspiel.title}</td>
            <td>{gewinnspiel.message}</td>
            <td style={{minWidth: "200px"}}>
              {(gewinnspiel.gewinner && new Date() >= new Date(gewinnspiel.endDate) ) ? 
                <div style={{fontSize: "90%"}}>
                  <span>KdNr: {gewinnspiel.gewinner.kdnr}</span><br />
                  <span>{gewinnspiel.gewinner.vorname} {gewinnspiel.gewinner.nachname}</span><br />
                  <span>{gewinnspiel.gewinner.anschrift}</span><br />
                  <span>{gewinnspiel.gewinner.plz} {gewinnspiel.gewinner.stadt}</span><br />
                  <p>{gewinnspiel.gewinner.land}</p>
                  <span><a href={'mailto:'+gewinnspiel.gewinner.email} style={{color: "#c58164"}}>{gewinnspiel.gewinner.email}</a></span><br />
                  <span><a href={'tel:'+gewinnspiel.gewinner.telefon} style={{color: "#c58164"}}>{gewinnspiel.gewinner.telefon}</a></span>
                </div>
                : "-"
              }
            </td>

            <td>
              {new Date() < new Date(gewinnspiel.endDate) &&
                <>

                

                  {gewinnspiel.isActive ? (
                    <>
                      {gewinnspiel.isApproved && 
                        <p style={{ color: "green" }}>Sichtbar</p>
                      }
                      {!gewinnspiel.isApproved && !gewinnspiel.notApprovedMessage && 
                        <p style={{ color: "orange" }}>In Überprüfung</p>
                      }
                      {!gewinnspiel.isApproved && gewinnspiel.notApprovedMessage && 
                        <p>Abgelehnt: {gewinnspiel.notApprovedMessage}</p>
                      }
                    </>
                  ) : (
                    <p style={{ color: "red" }}>Entwurf</p>
                  )}
                </>
              }
              {new Date() > new Date(gewinnspiel.endDate) &&
                <p style={{ color: "green" }}>Gewinnspiel beendet</p>
              }
            </td>
            <td>
              {new Date() > new Date(gewinnspiel.endDate) && !gewinnspiel.gewinnAusgeliefert &&
                <Button
                  variant="success"
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => handleGewinnAusgeliefert(gewinnspiel._id)}
                >
                  Gewinn als ausgeliefert hinterlegen
                </Button>
              }

              {new Date() > new Date(gewinnspiel.endDate) && gewinnspiel.gewinnAusgeliefert &&
                  <p>Du hast den Gewinn als ausgeliefert markiert - {new Date(gewinnspiel.gewinnAusgeliefert).toLocaleString("de-De")}</p>
              }

              {new Date() < new Date(gewinnspiel.endDate) &&
                <Button
                  variant="primary"
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setSelectedGewinnspiel(gewinnspiel);
                    setShowEditModal(true);
                  }}
                >
                  Editieren
                </Button>
              }
            </td>

          </tr>
        ))}
      </tbody>


      {selectedGewinnspiel && 
        <EditGewinnspielModal
          deleteGewinnspiel={deleteGewinnspiel}
          show={showEditModal}
          handleClose={() => {
            setShowEditModal(false);
            setSelectedGewinnspiel(null);
          }}
          selectedGewinnspiel={selectedGewinnspiel}
          onUpdate={handleUpdate}
        />
      }

    </Table>
  );
}

export default GewinnspielTable;
