import React, { useEffect, useState } from "react";
import { useNavigate, Link } from 'react-router-dom';

import { Container, Row, Col, Form, Button } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";

import { deDE, GridToolbarContainer, GridToolbarFilterButton, GridToolbarQuickFilter, GridToolbarColumnsButton, GridFooterContainer, GridFooter } from '@mui/x-data-grid';
import StripedDataGrid from "../../Bausteine/StripedDataGrid";
//import { Link } from '@mui/material';
import Box from '@mui/material/Box';

import CustomModal from "../../Bausteine/CustomModal";

import AuthAxios from "../../utils/AuthAxios";
import Swal from 'sweetalert2'


function BestaetigungsListeAngenommen(props) {

  const navigate = useNavigate();

  const [docs, setDocs] = useState([]);
  const [loading, setLoading] = useState(true);

  //Modal Data
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState({});

  /*
    created: new Date(),
    kdnr: 112000,
    firma: "",
    vorname: "Max",
    nachname: "Mustermann",
    dokument: "Angebot Autoreparatur.pdf",
    kategorie: "Rechnung",
    bestaetigung: true,
    bestaetigung_art: "bestaetigung", //bestaetigung, unterschrift
    bearbeiter: "Martin Meier", // req.user.vorname+" "req.user.nachname
    // nicht relevant, da nicht "offener Status"
    //bestaetigt: true,
    //bestaetigt_am: {_seconds: new Date().getTime()},
    //abgelehnt: "",
  });
*/

  useEffect(() => {

    async function getDocs() {
      AuthAxios.get('/doc-vorgang/angenommen')
        .then(res => {
          setDocs(res.data);
          setLoading(false);
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: 'error',
            title: "Fehler",
            html: "Angenommene Bestätigungen konnten nicht geladen werden."
          });
        });
    }
    getDocs();

  }, []);



  const handleNavigation = (e) => {
    //console.log(e.id);
    //navigate("/dash/kunde/" + e.id);

    setData(docs.find(k => k.id == e.id));

    // Aufgebe öffnen??
    setShowModal(true);

  }



  const columns = [
    {
      field: "kdnr",
      headerName: "KdNr",
      minWidth: 80,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "firma",
      headerName: "Firma",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => (
        <>
        {params.row.firma &&
          <span>{params.row.firma}</span>
        }
        {!params.row.firma &&
          <span>-</span>
        }
      </>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "vorname",
      headerName: "Vorname",
      minWidth: 100,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "nachname",
      headerName: "Nachname",
      minWidth: 100,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "category",
      headerName: "Kategorie",
      minWidth: 90,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "title",
      headerName: "Dokument",
      minWidth: 120,
      flex: 1,
      renderCell: (params) => (
        <span>{params.row.title.substring(36)}</span>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "customerActionType",
      headerName: "Bestätigung-Art",
      minWidth: 110,
      flex: 1,
      renderCell: (params) => (
        <>
          {params.row.customerActionType === "bestaetigung" &&
            <span>Bestätigung</span>
          }
          {params.row.customerActionType === "unterschrift" &&
            <span>Unterschrift</span>
          }
        </>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "created",
      headerName: "Erstellt",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => (
        <span>{new Date(params.row.created._seconds *1000).toLocaleString("de-De", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "numeric",
          minute: "numeric"
        })}</span>
      ),
      headerClassName: "super-app-theme--header",
    }
  ];


  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <Row style={{ display: "flex", justifyContent: "flex-start", width: "100%" }}>
          <Col xs={12} lg={2}>
            <GridToolbarQuickFilter />
          </Col>
          <Col xs={6} lg={1}>
            <GridToolbarFilterButton />
          </Col>
          <Col xs={6} lg={1}>
            <GridToolbarColumnsButton />
          </Col>

        </Row>

      </GridToolbarContainer>
    );
  }


  const CustomFooter = () => {
    return (
      <GridFooterContainer>
      {/* Add what you want here */}
      <GridFooter sx={{
          border: 'none', // To delete double border.
          alignItems: 'flex-start'
        }} />
    </GridFooterContainer>
    );
  }




  return (
      <div style={{ height: `calc(100vh - 125px)`, width: "100%" }}>
        {props.activeTab === 2 && 
          <>
            <p style={{padding: "0px 0px 0px 15px", margin: "0px"}}>Hier findest eine Übersicht aller angenommenen Dokumenten-Bestätigungen von deinen Kunden.</p>
            <Box
              sx={{
                height: `calc(100% - 70px)`,
                width: '100%',
                '& .super-app-theme--header': {
                  backgroundColor: '#c58164',
                },
                '& .MuiDataGrid-toolbarContainer': {
                  padding: '10px'
                },
                margin: "5px 0px 0px 0px",
                fontWeight: 'bold',
                boxShadow: 0,
              }}
            >
            
              {/* Server side pagination wenn zu viele Daten - https://mui.com/x/react-data-grid/pagination/ */}
              <StripedDataGrid
                //checkboxSelection
                //onSelectionModelChange={handleCheckboxSelection}
                disableSelectionOnClick
                //autoHeight
                density={'compact'}
                rows={docs}
                columns={columns}
                getRowId={(row) => row.id}
                onRowClick={handleNavigation}
                //pageSize={20}T
                //rowsPerPageOptions={[20]}
                autoPageSize
                //onFilterModelChange={onFilterModelChange}
                localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                loading={loading}
                getRowClassName={(params) =>
                  params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                }
                components={{ Toolbar: CustomToolbar }}
                componentsProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 100 },
                  },
                }}
                initialState={{
                  sorting: {
                    sortModel: [{ field: 'kdnr', sort: 'desc' }],
                  },
                }}
                sx={{/*
                  boxShadow: 2,
                  border: 2,
                  borderColor: 'primary.dark',
                  '& .MuiDataGrid-cell:hover': {
                    color: 'primary.main',
                  },
                  bgcolor: 'background.paper'
                */}}
              />

            </Box>
          </>
          
        }





        {showModal && 
          <CustomModal 
              title={"Angenommene Bestätigung"}
              visible={showModal}
              setVisible={setShowModal}
              abbrechen={"Schließen"}
          >
            <Row>
              <Col>
                <p className="mb-2">Kunde:&nbsp;
                  <b>
                    <Link to={`/dash/kunde/${data.kdnr}`} style={{color: '#c58164'}}>{data.kdnr +" "+data.vorname+" "+data.nachname}</Link>
                  </b>
                </p>

                <p className="mb-4">Dokument an Kunde übermittelt:&nbsp;<b>
                  {new Date(data.created._seconds*1000).toLocaleString("de-DE", {
                              year: "numeric",
                              month: "2-digit",
                              day: "2-digit",
                              hour: "numeric",
                              minute: "numeric"
                            })
                  }
                  </b>
                </p>

                <p>
                  Bestätigung-Art:
                  <b>
                    {data.customerActionType == "bestaetigung" && <span> Bestätigung</span>}
                    {data.customerActionType == "unterschrift" && <span> Unterschrift</span>}
                  </b>
                  <br />
                  Kategorie: <b>{data.category}</b>
                  <br />
                  Dokument:  <b>
                    <a href="" onClick={() =>
                        navigate(`/dash/kunde/${data.kdnr}`, { state: { props:
                          {
                            activeTab: 2,
                            kategorie: data.category,
                            dokument: data.title
                          }
                        }})
                      }
                      style={{color: '#c58164'}}
                    >
                      {data.title?.substring(36)}
                    </a>
                  </b>
                </p>

                <p className="mb-4">Bearbeiter: <b>{data.bearbeiter}</b></p>

                {/*<p style={{margin: "0px"}}><b>Status</b></p>*/}
            
                {/*(data.bestaetigung && !data.bestaetigt && !data.abgelehnt ) && <span> - warte auf Kundenbestätigung -</span>*/}
                {/*(data.bestaetigung && data.abgelehnt) &&<span>Abgelehnt: {data.abgelehnt}</span>*/}
                {/*(data.bestaetigung && data.bestaetigt && data.bestaetigt_am) && <span>Bestätigt: {new Intl.DateTimeFormat('de-DE', {
                    month: '2-digit',
                    day: '2-digit',
                    year: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric'
                  }).format(new Date(data.bestaetigt_am._seconds *1000))}</span>*/}
            
              </Col>
            </Row>
    
          </CustomModal>
        }

      </div>
  );
}


export default BestaetigungsListeAngenommen