import { useLocation } from "react-router-dom";

import Swal from "sweetalert2";
import AuthAxios from "../../utils/AuthAxios";

import GruppenListe from "./GruppenListe";

import React, { useEffect, useMemo, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";

import GroupModal from "../../Bausteine/GroupModal";


const GruppenListeMemo = React.memo(GruppenListe);

function Gruppenverwaltung(props) {
  
  const activeTabMemo = useMemo(() => props.activeTab);

  const [gruppen, setGruppen] = useState([]);
  const [newGroup, setNewGroup] = useState({});

  const [showCreateGroup, setShowCreateGroup] = useState(false);

  useEffect(() => {
    AuthAxios.get("/group")
      .then((res) => {
        setGruppen(res.data);
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Fehler",
          html: "Gruppen konnten nicht abgerufen werden.",
        });
      });
  }, []);


  const createNewGroup = () => {

    if (
      newGroup.groupId === undefined ||
      newGroup.gruppenname === "" ||
      newGroup.gruppenname === undefined
    ) {
      Swal.fire({
        icon: "error",
        title: "Fehler",
        html: "Vergib eine Zahl als Gruppen ID und einen Namen für die Gruppe und versuche es erneut.",
      });
      return;
    }

    AuthAxios.post("/group", newGroup)
      .then((res) => {
        setGruppen([
          ...gruppen,
          {
            _id: res.data._id,
            groupId: res.data.groupId,
            gruppenname: res.data.gruppenname,
            beschreibung: res.data.beschreibung,
            createdAt: res.data.createdAt,
          },
        ]);
        setNewGroup({});

        Swal.fire({
          icon: "success",
          title: "Erfolg",
          html: "Die Gruppe wurde erfolgreich angelegt!",
        });
      })
      .catch((err) => {
        
        if (err.response) {
          if ("groupId" in err.response.data.error.keyValue) {
            Swal.fire({
              icon: "error",
              title: "Fehler",
              html: "Die Gruppe konnte nicht angelegt werden. Die ID ist bereits vergeben.",
            });
          }

          if ("gruppenname" in err.response.data.error.keyValue) {
            Swal.fire({
              icon: "error",
              title: "Fehler",
              html: "Die Gruppe konnte nicht angelegt werden. Der Gruppenname ist bereits vergeben.",
            });
          }
        } else {
          Swal.fire({
            icon: "error",
            title: "Fehler",
            html: "Der Service steht momentan nicht zur Verfügung.",
          });
        }
      });
  };

  return (
    <Container fluid>
       <Row className="mb-3" style={{paddingLeft: "10px"}}>
        <Col>
          <p>Gruppen dienen zur Origanisierung von Aufgaben in verschiedene Firmenbereiche. Gruppen können einzelenen Mitarbeitern im "Mitarbeiter anlegen/bearbeiten" Tab hinterlegt werden. Dazu den Mitarbeiter in der Tabelle der Übersicht auswählen.</p>
          <p>Gruppen sind momentan nur durch die IT veränderbar, wenn diese angelegt wurden.</p>
        </Col>
      </Row>
      <Row className="mb-3" style={{paddingLeft: "10px"}}>
        <Col>
          <Button onClick={() => setShowCreateGroup(true)}>Neue Gruppe anlegen</Button>
        </Col>
      </Row>
      <Row>    
        <Col>
          <GruppenListeMemo data={gruppen} activeTab={activeTabMemo} createNewGroup={createNewGroup} newGroup={newGroup} setNewGroup={setNewGroup} />
        </Col>
      </Row>


      {showCreateGroup &&
        <GroupModal title={"Gruppe erstellen"} setChanged={props.setChanged} visible={showCreateGroup} setVisible={setShowCreateGroup} newGroup={newGroup} setNewGroup={setNewGroup} createNewGroup={createNewGroup} />
      }

    </Container>
  );
}

export default Gruppenverwaltung;
