
import React from 'react'

import style from "./CardNachricht.module.css"
import classLister from 'css-module-class-lister';

import { Row, Col } from 'react-bootstrap';

import moment from 'moment'

function CardNachricht(props) {

  //const classes = classLister(style);

  return (
   
      <div className={style.cardContainerUpload} style={{marginBottom: "20px"}}>
        <Row style={{width:"100%"}}>
            <Col style={{display: "flex", flex: "1", flexDirection: "column", alignItems: "flex-start", padding: "10px 0px 0px 15px"}}>

            <span style={{fontSize: "80%"}}>
              {moment(props.createdAt, moment.ISO_8601, true).isValid() ?
                <>{new Intl.DateTimeFormat('de-DE', {
                    month: '2-digit',
                    day: '2-digit',
                    year: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric'
                    }).format(new Date(props.createdAt))}</>
                    :
                   <i>invalid Date</i>
                }
              </span>     
                <p style={{marginBottom: "5px", textAlign: "left"}}>{props.nachricht}</p>
            </Col>
        </Row>
      </div>
  )
}

export default CardNachricht
