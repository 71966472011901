import React, { Fragment, useState } from 'react'
import { useNavigate } from 'react-router-dom';

//import WebViewModal from "../Bausteine/WebViewModal/WebViewModal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp, faCircleCheck } from "@fortawesome/free-solid-svg-icons"

import { Row, Col, Button } from 'react-bootstrap';
import moment from 'moment';

import style from "./GewinnspielCard.module.css"

function GewinnspielCard(props) {

    const [weiterlesen, setWeiterlesen] = useState([]);

    const [showModal, setShowModal] = useState(false);
    const [modalUrl, setModalUrl] = useState(false);


    const handlePDF = (url) => {
        window.open(url, 'noopener nofollow');
    }


    const handleExternalURL = (url) => {
        setShowModal(false);
        window.scrollTo({
            top: 0,
            behavior: "instant"
            });
        setModalUrl(url);
        setShowModal(true);
    }
    

      
  function downloadPDF() {

    alert("PDF Download nur in der App möglich");
    /*
    const linkSource = base64pdf;
    const downloadLink = document.createElement("a");
    const fileName = "VIN1-Gewinnspiel.pdf";

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
    */
  }

  /*
  message={gewinnspiel.message ? gewinnspiel.message : ""}
  title={gewinnspiel.title ? gewinnspiel.title : ""}
  publishDate={gewinnspiel.publishDate ? gewinnspiel.publishDate : ""}
  startDate={gewinnspiel.publishDate ? gewinnspiel.publishDate : ""}
  endDate={gewinnspiel.publishDate ? gewinnspiel.publishDate : ""}
  pdf={pdfFile ? pdfFile : ""}
  */

  function isValidDate(dateString) {
    const date = new Date(dateString);
    return !isNaN(date);
  }



  function countTextChars(htmlString) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const text = doc.body.textContent || "";
    return text.length;
}

  
  return (

    <Row className={style.cardContainerNews} style={{marginTop: "20px"}}>
        <Col xs={12} md={2} className={style.newsImageContainer}>
            <img className={style.newsImage} src={props.image} alt="" />
        </Col>
        <Col xs={12} md={10} style={{marginTop: "10px", paddingLeft: "16px", paddingRight: "16px"}}>
       
            <div className={style.textContainer}>
                <span style={{color: "#808080", marginBottom: "8px"}}>
                    Aktionszeitraum: &nbsp;

                        {isValidDate(props.startDate) && props.startDate != "1970-01-01 00:00:00" &&
                            <>
                                {moment(new Date(props.startDate)).format('DD.MM.YY, HH:mm')}
                            </>
                        }
                    &nbsp;-&nbsp;
                        {isValidDate(props.endDate) &&
                            <>
                              {moment(new Date(props.endDate)).format('DD.MM.YY, HH:mm')}
                            </>
                        }
                </span>

                <h5 className="fw-bold">{props.title}</h5>

                Länge: {countTextChars(props.message.length)}

                {countTextChars(props.message.length) <= 400 && !weiterlesen.includes(props.id) && 
                    <p dangerouslySetInnerHTML={{__html: props.message}}></p>
                }

                {props.message.length > 400 && !weiterlesen.includes(props.id) &&
                    <Fragment>
                        <p dangerouslySetInnerHTML={{__html: props.message.substring(0,400)+" ..."}}></p>
                        <div className={style.textBtn} onClick={() => setWeiterlesen([...weiterlesen, props.id])}>
                            <FontAwesomeIcon className={style.icon} icon={faChevronDown} size="lg" /><span style={{paddingLeft: "10px"}}>Weiterlesen</span>
                        </div>
                    </Fragment>
                }

                {weiterlesen.includes(props.id) && 
                    <Fragment>
                        <p dangerouslySetInnerHTML={{__html: props.message}}></p>
                        <div className={style.textBtn} onClick={() => setWeiterlesen([...weiterlesen.filter((item) => item !== props.id)])}>
                            <FontAwesomeIcon className={style.icon} icon={faChevronUp} size="lg" /><span style={{paddingLeft: "10px"}}>Einklappen</span>
                        </div>
                    </Fragment>
                }
              
                
            </div>
        </Col>
        <Col xs={12} md={12} className={style.btnContainer}>
            {props.pdf && <Button className="fw-bold" style={{background: "rgb(83, 178, 83)", border: "none", marginRight: "10px"}} onClick={() => downloadPDF()}>Info PDF</Button>}
            <Button className="fw-bold" style={{background: "#520da7"}} onClick={() =>  alert("Teilnehmen") }>Jetzt teilnehmen</Button>      
        </Col>

        
    </Row>
  )
}

export default GewinnspielCard