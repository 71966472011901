import React, { useEffect, useState, useContext } from "react";
//import Input from "../../Input/Input";
import { MainContext } from "../../contexts/MainContext";

import { Container, Row, Col, Button } from "react-bootstrap";
import "../../Input/Input.module.scss"


import CardSub from "./DateienBausteine/CardSub"
import CardUpload from "./DateienBausteine/CardUpload"
import CardDokument from "./DateienBausteine/CardDokument"

import style from './Dateien.module.css'

import { makeID } from "../../utils/makeID";
import Swal from 'sweetalert2'
import AuthAxios from "../../utils/AuthAxios";



function Dateien(props) {

  const { user } = useContext(MainContext);

  const [input, setInput] = useState("");
  const [googleUID, setGoogleUID] = useState("");
  const [email, setEmail] = useState("");

  const [customerAction, setCustomerAction] = useState([]); // Objects with action + notes for files
  
  const [isUploading, setIsUploading] = useState(false);


  useEffect(() => {

    // Wenn von "außen" Kategorie gesetzt wird
    if (props.dateiKategorie) {
      setSelectedMainCat(props.dateiKategorie);
    }

    if (props.activeTab == 2) {
      if (props.data.googleuid && props.data.googleuid !== "") {
        setGoogleUID(props.data.googleuid);

        if(!props.data.email) {
          Swal.fire({
            icon: "info",
            title: "E-Mail",
            html: "Keine E-Mail in CRM hinterlegt. Benachrichtigungen über neue Dokumente können bei Datei-Uploads nicht versendet werden. Bitte an IT-Support wenden."
          })
        }
        else
        {
          setEmail(props.data.email);
        }

      }
      else
      {
        Swal.fire({
          icon: "error",
          title: "Dateien",
          html: "Kunde ist noch nicht in der VIN App registriert!<br />"
            + "Kunde zunächst in der VIN-App registrieren lassen."
        })
      }

    }
 
  }, [props])


  const [mainCategory, setMainCategory] = useState([]);

  const [selectedMainCat, setSelectedMainCat] = useState("");

  useEffect(() => {

    AuthAxios.get('/partner') // Zuordung über user.partner_name
    .then(res => {
      setMainCategory(res.data.kategorien);
    }) 
    .catch(err => {
      console.log(err);
    });

  }, [])



  const handleMainCategory = (name) => {
    setSelectedMainCat(name);
    //console.log(kategorienData.filter((key) => key.mainCategory === name)[0].category);
  }


  const [pickedFiles, setPickedFiles] = useState([]);
  const [privateDocs, setPrivateDocs] = useState([]);


  
  useEffect(() => {

    // Get Dokumente
    async function getPrivateDocs() {

      let documents = await AuthAxios.post('/dokumente/docs', {
        googleUID: googleUID,
        category: selectedMainCat
      })

      let DocumentsArr = [];
      documents.data.forEach(doc => {
        //console.log(doc.data());
        DocumentsArr.push({
          id: doc.id,
          data: doc
        }
        );
      })
      setPrivateDocs(DocumentsArr);
      

    }

    // Abruf, nur wenn eine Kategorie ausgewählt wurde.
    if (selectedMainCat != "") {
      getPrivateDocs();
    }

  }, [googleUID, selectedMainCat])


  function deleteFileAndDoc(id, filename) {

    AuthAxios.post("/dokumente/delete", {
      googleUID: googleUID,
      metaDocID: id,
      //newsfeedDocID: newsfeeddocid,
      filename: filename,
    })
    .then(res => {

      setPrivateDocs(privateDocs.filter(doc => doc.id !== id));

      Swal.fire({
        icon: "success",
        title: "Erfolg",
        html: "Dokument erfolgreich gelöscht."
      });

    })
    .catch(err => {
      console.log(err);
      Swal.fire({
        title: "Dokument löschen",
        text: "Es gab einen Fehler beim Löschen der Dateien.",
        icon: "error",
      });
    })
  }



  function removeFileUploadHandler(id) {

    //Multiple Files
    setPickedFiles(pickedFiles.filter(doc => doc.id !== id));
    setCustomerAction(customerAction.filter(action => action.id !== id));

    //Remove Bestätigungsfeld Wert
    //setCustomerAction(customerAction.filter(doc => doc.id !== id));

    // Single File
    //setPickedFiles([]);
  }




  function handleFile(e) {

    let data = [];
    
    Array.from(e.target.files).forEach(file => {
      // Max Dateien pro Upload: 5
      if(pickedFiles.length + e.target.files.length <= 5) {

        data = [...data, {
            id: makeID(8),
            file: file,
            name: file.name,
            size: file.size,
            type: file.type
          }
        ];
      }
      else
      {
        Swal.fire({
          icon: "info",
          title: "Anzahl Dateien",
          html: "Die max. Anzahl an Dateiuploads ist auf 5 Dateien pro Vorgang festgelegt."
        })
      }
    });

    setPickedFiles([...pickedFiles, ...data]);
  }


  const handleCustomerAction = ({ fileId, value, type }) => {
    let updatedCustomerAction = [];
  
    if (type === "action") {
      if (value === "") {
        updatedCustomerAction = customerAction.filter(
          (action) => action.id !== fileId
        );
      } else {
        updatedCustomerAction = customerAction.map((action) =>
          action.id === fileId
            ? { ...action, action: value }
            : action
        );
  
        const existingAction = updatedCustomerAction.find(
          (action) => action.id === fileId
        );
  
        if (!existingAction) {
          updatedCustomerAction.push({ id: fileId, action: value, note: "" });
        }
      }
    } else if (type === "note") {
      updatedCustomerAction = customerAction.map((action) =>
        action.id === fileId
          ? { ...action, note: value }
          : action
      );
    }
  
    setCustomerAction(updatedCustomerAction);
  };



  const handleFileUpload = () => {

    const formData = new FormData();

    pickedFiles.forEach((f, index) => {
      formData.append("files", f.file);
    });
    
   //formData.append("files", pickedFiles[0]);
 
    formData.append('customerActions', JSON.stringify(customerAction));

    formData.append("googleUID", googleUID);
    formData.append("email", props.data.email);
    
    formData.append("kdnr", props.data.kdnr);
    formData.append("firma", props.data.firma);
    formData.append("vorname", props.data.vorname);
    formData.append("nachname", props.data.nachname);
    formData.append("main_category", selectedMainCat);
    formData.append("name", selectedMainCat); // Bezeichnung Dokumentennewsfeed!

    // Debug Output
    /*
    const formDataObject = {};
    for (let pair of formData.entries()) {
      formDataObject[pair[0]] = pair[1];
    }
    console.log(formDataObject);
    */
    

    if (pickedFiles.length >= 1) {

      setIsUploading(true);

      AuthAxios({
        method: "post",
        url: "/dokumente/upload",
        headers: { "Content-Type": "multipart/form-data" }, // Mixed Data with Files and JSON
        data: formData // data so übergeben, sonst geht es nicht
      })
      .then(res => {

        if(!res.data.docs) {
          Swal.fire({
            title: "Dokument Upload",
            text: "Es gab einen Fehler beim Upload deiner Dateien. Dokument nicht zurückgegeben.",
            icon: "error",
          });
          setIsUploading(false);
          return;
        }

        //Einbau Dokumente in aktueller Ansicht
        let newDocs = [];
        res.data.docs.forEach(doc => {
          newDocs.push(doc);
        });

        setPrivateDocs([...newDocs, ...privateDocs]);

        Swal.fire({
          title: "Erfolg",
          html: "Alle Dateien wurden erfolgreich hochgeladen.",
          icon: "success",
        });

        setIsUploading(false);
        setPickedFiles([]);

      })
      .catch(err => {

        Swal.fire({
          title: "Dokument Upload",
          text: "Es gab einen Fehler beim Upload deiner Dateien.",
          icon: "error",
        });
        setIsUploading(false);
      })
        
    }
   
    // Spinner bzw. Upload Fortschritt einbauen
  }



  return (

    <Container style={{  minHeight: "100%" }} className="pb-4">

      {googleUID && email &&

        <>
          <Row style={{ display: "flex", justifyContent: "center", marginBottom: "50px" }} >
            <p className="text-center" style={{ margin: "0px", fontWeight: "bold"}}>Kunde: {props.data.kdnr +" - "+ props.data.vorname+" "+props.data.nachname}</p>
            <p className="text-center" style={{ margin: "0px" }}>Wähle einen Hauptbereich</p>

            {mainCategory.map(name => (
              <Col
                key={name}
                xs={12} sm={6} md={3}
                style={{ marginTop: "20px" }}
                onClick={() => handleMainCategory(name)}
              >
                {(selectedMainCat === name) && (
                  <CardSub key={name} title={name} selected={1} color={"#520da7"} />
                )}
                {(selectedMainCat !== name) && (
                  <CardSub key={name} title={name} />
                )}
              </Col>
            ))
            }
          </Row>




          {/* Dokument Upload nur anzeigen, wenn alle angezeigten Kategorien gewählt sind!*/}
          {googleUID != "" && selectedMainCat != "" &&
      
            <>

              <Row style={{marginTop: "16px", justifyContent: "center", marginBottom: "50px" }} >
                <Col
                  xs={12} md={12}
                  className={style.uploadContainer}
                >

                  <h4 className="text-center">Dateiupload für {input.label}</h4>
                  <p>1. Wähle Dateien aus (.pdf .jpg .png) - pro Vorgang max. 5 Dateien.</p>

                  <label htmlFor="docUpload" className={style.uploadBtn}>
                    Datei/-en auswählen
                    <input
                      id="docUpload"
                      type="file"
                      multiple
                      accept='application/pdf, image/png, image/jpeg'
                      style={{ display: "none" }}
                      onChange={handleFile}
                    />
                  </label>

                  {pickedFiles.length > 0 &&
                    <div className="text-center" style={{ marginTop: "20px", width: "100%", maxWidth: "800px" }}>
                      {/*<p>2. Gib der Datei eine kurze und knackige Beschreibung (z.B. "Perso" oder "Fahrzeugbrief")</p>*/}
                      <p>2. Lade die Dateien hoch</p>

                      {pickedFiles.map(file => 

                            <CardUpload
                              key={file.id}
                              data={file}
                              customerAction={customerAction.find((action) => action.id === file.id)} // pass the corresponding customerAction for the file
                              setCustomerAction={(fileId, value, type) => handleCustomerAction({fileId, value, type})}
                              removeFileUploadHandler={removeFileUploadHandler}
                            />
                      )}

                      {!isUploading && <button className={style.uploadBtn} onClick={handleFileUpload}>Dokumente hochladen</button>}
                      {isUploading && <button className={style.uploadBtn} style={{opacity: "0.7"}} disabled>Dokumente werden hochgeladen...</button>}
     
                    </div>
                  }

                </Col>
              </Row>

              <Row style={{marginTop: "16px", justifyContent: "center", marginBottom: "60px", marginTop: pickedFiles.length > 0 ? "50px" : "0px" }} >
                {/* Dokumenten Liste */}
                <Col
                  xs={12} md={12}
                  className="text-center"
                >
                  <h4 className="text-center" style={{ marginBottom: "0px" }}>Uploads zum Kunden</h4>
                  <p className="text-center" style={{ marginBottom: "20px" }}>Es sind nur von <b>{user.partner_name}</b> hochgeladene Dokumente sichtbar!</p>

                  {privateDocs.length == 0 && <p style={{ marginTop: "10px" }}>Noch keine Dokumente vorhanden.</p>}

                  {privateDocs.sort((a, b) => (b.data.created._seconds > a.data.created._seconds ? 1 : -1)).map((doc) =>   
                    <CardDokument key={doc.id} id={doc.id} data={doc.data} googleUID={googleUID} deleteFileAndDoc={deleteFileAndDoc} />  
                  )}

                </Col>
              </Row>
            </>
          }

        </>
      }

      {!googleUID && 
        <h5>
          Kunde ist noch nicht in der VIN1 App registriert. Daher kann nicht auf die Dateien zurückgegriffen werden.
        </h5>
      }
      {!email &&
        <h5>
          Es hinterliegt keine E-Mail im Kundenkonto, damit dieser Bereich funktioniert.
        </h5>
      }


    </Container>
  );
}
export default Dateien