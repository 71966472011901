import React, {useState, useEffect} from "react";
import ReactSelect, { createFilter } from "react-select";

import { Col, Form } from 'react-bootstrap'

import AuthAxios from "../utils/AuthAxios";
import Swal from "sweetalert2";

function SelectGruppen (props) {

    const [selectedValues, setSelectedValues] = useState([]);

    const [gruppen, setGruppen] = useState([]);

    /*
    const handleValue = (selected) => {
        selected.map(s => {
            console.log({value: s, label: s});    
            setSelectedValues(prevState => ({
                selectedValues: [{value: s, label: s}, ...prevState.selectedValues]
              }));       
         })
    }
    */

    useEffect(() => {

        async function getGruppen () {
        
            try {
                const res = await AuthAxios.get('/group');

                const gruppenListe = [];
                res.data.map(g => {
                    gruppenListe.push({"value": "G"+g.groupId, "label": "G"+g.groupId+" | "+g.gruppenname});
                });
                setGruppen(gruppenListe);
            }
            catch (err) {
                console.log(err);
                Swal.fire({
                    icon: 'error',
                    title: "Fehler",
                    html: "Gruppen konnten nicht geladen werden."
                });
            }               
        }
        getGruppen();  

    },[]);

    useEffect(() => {

        setSelectedValues(gruppen.filter(group => props.selectedValues.includes(group.value)) );
       
    },[props.selectedValues]);
    

    // selectedOption ist wie "event"
    const handleChange = (name, selection) => {

        props.setSelectMulti(name, selection);
    };

    /*
    const formatOptionLabel = ({ value, label }) => (
        <div style={{ display: "flex" }}>
          <div>{label}</div>
        </div>
    );
    */

    return (
        <Col xs={12} md={12}>
            <ReactSelect
                isMulti
                className="basic-multi-select"
                classNamePrefix="select"
                value={selectedValues ? selectedValues : ""}
                //formatOptionLabel={formatOptionLabel} // hat nur relevanz wenn value nicht verwendet wird
                onChange={(selection, e) => handleChange(e.name, selection)}
                options={gruppen}
                noOptionsMessage={() => "Keine Gruppen gefunden"}
                placeholder={<div className="select-placeholder-text">Wähle eine oder mehrere Gruppen</div>}
                name="groups"
            />
        </Col>
    );
   
    
}

export default SelectGruppen