import Table from "react-bootstrap/Table";
import { showImgModal, downloadPDF } from "../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import EditNewsletterModal from "./EditNewsLetterModal";
import Swal from "sweetalert2";
import AuthAxios from "../../utils/AuthAxios";
import b64toBlob from "../../utils/cropper";
import { Button } from "react-bootstrap";
import Spinner from "../../Bausteine/Spinner/Spinner"

function NewsletterTable({ currentNewsletter, getNewsLetterFromUser, updateNewsletterData }) {

  const [selectedNewsLetter, setSelectedNewsLetter] = useState();
  const [showEditModal, setShowEditModal] = useState(false); // Add this state for the modal

  const [spinner, setSpinner] = useState(false);


  async function deleteNewsLetter(newsletterId) {
    try {
      const response = await AuthAxios.delete(`/newsletter/${newsletterId}`, {
        type: "Newsletter"
      });

      setShowEditModal(false);
      Swal.fire({
        icon: "success",
        title: "Erfolg",
        html: "Newsletter erfolgreich gelöscht",
        timer: 1500,
      });
      getNewsLetterFromUser();
    } catch (error) {
      console.log(error);
    }
  }



  const handleUpdate = async (updatedNewsletter, croppedImageAsBase64) => {

    try {
      
      if (!updatedNewsletter.title || !updatedNewsletter.message || !updatedNewsletter.publishDate || (updatedNewsletter.imageCleared && !updatedNewsletter.newImage) ) {
        Swal.fire({
          icon: "info",
          title: "Pflichtfelder",
          html: "Bitte fülle alle Pflichtfelder aus und klicke erneut auf speichern.",
        });
        return;
      }

  
        setSpinner(true);
  
        const formData = new FormData();
        formData.append("isActive", updatedNewsletter.isActive);
        formData.append("isAdvertisement", updatedNewsletter.isAdvertisement);
        formData.append("title", updatedNewsletter.title);
        formData.append("message", updatedNewsletter.message);
        //formData.append("publishDate", updatedNewsletter.publishDate); nicht anpassen.
  
        if(updatedNewsletter.externeUrl) {
          formData.append("externeUrl", "https://"+updatedNewsletter.externeUrl);
        }
        else {
          formData.append("externeUrl", "");
        }
  
        //Dateien
        if(updatedNewsletter.newImage) {
          formData.append("newImage", updatedNewsletter.newImage);
        }

        // Altes PDF löschen + durch neues ersetzen
        if(updatedNewsletter.newPdfFile) {
          formData.append("newPdfFile", updatedNewsletter.newPdfFile);
        }

        // Altes PDF löschen
        if(updatedNewsletter.pdfCleared && !updatedNewsletter.newPdfFile) {
          formData.append("pdfCleared", updatedNewsletter.pdfCleared);
        } 
  
        AuthAxios({
          method: 'post',
          url: "/newsletter/"+updatedNewsletter._id,
          headers: { "Content-Type": "multipart/form-data" }, // Mixed Data with Files and JSON
          data: formData
        })
        .then((res) => {

          //Update Newsletter in Table
          updateNewsletterData(res.data);
  
          Swal.fire({
            icon: "success",
            title: "Erfolg",
            html: "Newsletter erfolgreich aktualisiert!",
          });
          
          setSpinner(false);
  
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Formular",
            html: "Es ist ein Fehler aufgetreten. Bitte erneut versuchen.",
          });
          setSpinner(false);
        });
      }

      catch(error) {
        Swal.fire({
          icon: "error",
          title: "Fehler",
          html: "Newsletter konnte nicht aktualisiert werden.",
        });
        setSpinner(false);
      }

        /*
      const formData = new FormData();
      let newsletterImageFile = document.getElementById("newsletterImage").files[0];
      if (croppedImageAsBase64) {
        const base64Image = croppedImageAsBase64.split(",")[1];
        const blob = b64toBlob(base64Image, "image/jpeg");

        // Convert Blob to File
        const fileName = "croppy.jpg";
        const file = new File([blob], fileName, {
          type: "image/jpeg",
        });
        formData.append("files", file);
      } else {
        formData.append("files", newsletterImageFile);
      }
      const pdfImage = document.getElementById("pdfImageUpdate");
      formData.append("title", updatedNewsletter.title);
      formData.append("newsLetterId", updatedNewsletter._id);
      formData.append("isActive", updatedNewsletter.isActive);
      formData.append("isAdvertisement", updatedNewsletter.isAdvertisement);
      formData.append("message", updatedNewsletter.message);
      formData.append("publishDate", updatedNewsletter.publishDate);
      formData.append("externeUrl", updatedNewsletter.externeUrl);

      const pdfImageFile = pdfImage.files[0];

      formData.append("files", pdfImageFile);
      console.log(formData);

      const response = await AuthAxios.put("/newsletter", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      Swal.fire({
        icon: "success",
        title: "Newsletter erfolgreich aktualisiert",
        showConfirmButton: false,
        timer: 1500,
      });

      console.log(response);
      getNewsLetterFromUser();
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Newsletter konnte nicht aktualisiert werden",
        showConfirmButton: false,
        timer: 1500,
      });
    }
    */

    /* Update the newsletter list, you may need to adjust this to work with your data handling
    const updatedNewsletters = currentNewsletter.map((newsletter) =>
      newsletter._id === updatedNewsletter._id ? updatedNewsletter : newsletter
    );
    setCurrentNewsletter(updatedNewsletters);*/
  };


  return (
    <>
      {spinner &&
        <Spinner 
          message={"Bitte warten, Newsletter wird geupdated."}
        />
      }

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Live am:</th>
            <th>Aufgerufen</th>
            <th>Bild</th>
            <th>PDF</th>
            <th>Titel</th>
            <th>Nachricht</th>
            <th>Externe URL</th>

            <th>Status</th>
            <th>Aktionen:</th>
          </tr>
        </thead>
        <tbody>
          {!currentNewsletter &&
            <td colSpan={9} className="text-center" style={{background: "#eee", padding: "20px"}}>
              Noch kein Newsletter angelegt</td>
          }
          {currentNewsletter.map((newsletter) => (
            <tr key={newsletter._id}>
              <td>{newsletter.publishDate &&
                new Intl.DateTimeFormat('de-DE', {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                }).format(new Date(newsletter.publishDate))}
              </td>

              <td>
                {newsletter.gelesen && newsletter.isActive ? (
                <p>200</p>
                ) : (
                  <>0</>
                )}
              </td>

              <td>
                {newsletter.imageUrl ? (
                  <>
                    <img
                      style={{
                        height: "100px",
                        width: "auto",
                        cursor: "pointer"
                      }}
                      alt=""
                      src={newsletter.imageUrl}
                      onClick={() => showImgModal(newsletter.imageUrl)}
                    />
                  </>
                ) : (
                  <>-</>
                )}
              </td>

              {newsletter.pdfFileUrl ? (
                <td >
                  <a href="#" onClick={() => downloadPDF(newsletter.pdfFileUrl) }>
                    PDF öffnen
                  </a>
                </td>
              ) : (
                <td>-</td>
              )}

              <td>{newsletter.title}</td>
              <td>{newsletter.message}</td>
              <td>
              {newsletter.externeUrl ? 
                <a href={newsletter.externeUrl} target="_blank" rel="noreferrer">
                  {newsletter.externeUrl}
                </a>
                : <p className="text-center">-</p>
              }
              </td>

              <td>
                {newsletter.isActive ? (
                  <>
                    {newsletter.isApproved && 
                      <p style={{ color: "green" }}>Sichtbar</p>
                    }
                    {!newsletter.isApproved && !newsletter.notApprovedMessage && 
                      <p style={{ color: "orange" }}>In Überprüfung</p>
                    }
                    {!newsletter.isApproved && newsletter.notApprovedMessage && 
                      <p>Abgelehnt: {newsletter.notApprovedMessage}</p>
                    }
                  </>
                ) : (
                  <p style={{ color: "red" }}>Entwurf</p>
                )}
              </td>
              <td>
                {newsletter.isAdvertisement &&
                  <span>&#045;Angebot&#045;</span>
                }
                {!newsletter.isAdvertisement &&
                  <Button
                    variant="primary"
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (newsletter.isAdvertisement) {
                        return alert(
                          "Dieser Newsletter ist eine Werbung und kann nicht bearbeitet werden"
                        );
                      }
                      setSelectedNewsLetter(newsletter);
                      setShowEditModal(true);
                    }}
                  >
                    Editieren
                  </Button>
                }
              </td>
            </tr>
          ))}
        </tbody>


        {selectedNewsLetter && 
          <EditNewsletterModal
            deleteNewsLetter={deleteNewsLetter}
            show={showEditModal}
            handleClose={() => {
              setShowEditModal(false);
              setSelectedNewsLetter(null);
            }}
            selectedNewsletter={selectedNewsLetter}
            onUpdate={handleUpdate}
          />
        }

      </Table>

    </>

  );
}

export default NewsletterTable;
