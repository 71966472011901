import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

import { Container, Row, Col, Button, NavItem } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { faFileAlt as farFileAlt } from '@fortawesome/free-regular-svg-icons'

import { deDE, GridToolbar, GridApi, gridClasses, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton, GridToolbarQuickFilter, GridToolbarColumnsButton, GridFooterContainer, GridFooter } from '@mui/x-data-grid';
import StripedDataGrid from '../../Bausteine/StripedDataGrid';

import { Link } from '@mui/material';
import Box from '@mui/material/Box';

import AuthAxios from "../../utils/AuthAxios";
import Swal from 'sweetalert2'

function KundenListe() {

  const navigate = useNavigate();

  const [kunden, setKunden] = useState([]);

  const [loading, setLoading] = useState(true);


  useEffect(() => {

    async function getKunden() {
      AuthAxios.get('/kunde')
        .then(res => {
          setKunden(res.data);
          setLoading(false);
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: 'error',
            title: "Fehler",
            html: "Kunden konnten nicht geladen werden."
          });
        });
    }
    getKunden();

  }, []);



  const handleNavigation = (e) => {
    //console.log(e.id);
    navigate("/dash/kunde/" + e.id);
  }
  



  const columns = [
    {
      field: "kdnr",
      headerName: "KdNr",
      minWidth: 80,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "anrede",
      headerName: "Anrede",
      minWidth: 65,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "firma",
      headerName: "Firma",
      minWidth: 100,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "vorname",
      headerName: "Vorname",
      minWidth: 100,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "nachname",
      headerName: "Nachname",
      minWidth: 110,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "geburtsdatum",
      headerName: "Geburtsd.",
      type: "number",
      minWidth: 90,
      flex: 1,
      renderCell: (params) => (
        <>
          {(params.row.geburtsdatum && params.row.geburtsdatum != "0000-00-00" ) && <span>{new Date(params.row.geburtsdatum).toLocaleDateString('de-De', {day: '2-digit', month: '2-digit', year: 'numeric'}).substring(0,10)}</span>}
          {(!params.row.geburtsdatum || params.row.geburtsdatum == "0000-00-00") && <span>-</span>}
        </>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "anschrift",
      headerName: "Anschrift",
      minWidth: 160,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "plz",
      headerName: "PLZ",
      minWidth: 70,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "stadt",
      headerName: "Stadt",
      minWidth: 130,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    /*
    {
      field: "land",
      headerName: "Land",
      minWidth: 100,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    
    {
      field: "email",
      headerName: "Email",
      minWidth: 200,
      flex: 1,
      renderCell: (params) => (
        <Link href={`mailto:${params.row.email}`}>{params.row.email}</Link>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "telefon",
      headerName: "Telefon",
      minWidth: 120,
      flex: 1,
      renderCell: (params) => (
        <Link href={`tel:${params.row.telefon}`}>{params.row.telefon}</Link>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "mobil",
      headerName: "Mobil",
      minWidth: 120,
      flex: 1,
      renderCell: (params) => (
        <Link href={`tel:${params.row.mobil}`}>{params.row.mobil}</Link>
      ),
      headerClassName: "super-app-theme--header",
    },
    */
    {
      field: "updatedAt",
      headerName: "Zul. Geändert",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <span>{new Date(params.row.updatedAt).toLocaleString("de-De")}</span>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "action",
      headerName: "Quick-Links",
      sortable: false,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        
        const onClick = (e) => {
          navigate("/dash/kunde/" + params.id, { state: { target: 'files' }});
          e.stopPropagation(); // don't select this row after clicking
        };
  
        return (
          <Button onClick={onClick}>
            <FontAwesomeIcon
              icon={farFileAlt}
            />
          </Button>
        )
      }
    },

    /*
    {
      field: "webseite",
      headerName: "Webseite",
      minWidth: 155,
      flex: 1,
      valueGetter: (params) => (
        <Link href={`${params.row.webseite}`}>{params.row.webseite}</Link>
      ),
      headerClassName: "super-app-theme--header",
    }*/
  ];


  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <Row style={{ display: "flex", justifyContent: "flex-start", width: "100%" }}>
          <Col xs={12} lg={2}>
            <GridToolbarQuickFilter />
          </Col>
          <Col xs={6} lg={1}>
            <GridToolbarFilterButton />
          </Col>
          <Col xs={6} lg={1}>
            <GridToolbarColumnsButton />
          </Col>

        </Row>

      </GridToolbarContainer>
    );
  }


  const CustomFooter = () => {
    return (
      <GridFooterContainer>
      {/* Add what you want here */}
      <GridFooter sx={{
          border: 'none', // To delete double border.
          alignItems: 'flex-start'
        }} />
    </GridFooterContainer>
    );
  }




  return (
    <>
      <div style={{ width: "100%" }}>
        <Box
          sx={{
            height: `calc(100vh - 35px)`,
            width: '100%',
            '& .super-app-theme--header': {
              backgroundColor: '#c58164',
            },
            '& .MuiDataGrid-toolbarContainer': {
              padding: '10px'
            },
            margin: "35px 0px 0px 0px",
            fontWeight: 'bold',
            boxShadow: 0,
          }}
        >
          {/* Server side pagination wenn zu viele Daten - https://mui.com/x/react-data-grid/pagination/ */}
          <StripedDataGrid
            //checkboxSelection
            //onSelectionModelChange={handleCheckboxSelection}
            disableSelectionOnClick
            //autoHeight
            density={'compact'}
            rows={kunden}
            columns={columns}
            getRowId={(row) => row._id}
            onRowClick={handleNavigation}
            //pageSize={20}
            //rowsPerPageOptions={[20]}
            autoPageSize
            //onFilterModelChange={onFilterModelChange}
            localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
            loading={loading}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
            }
            components={{ Toolbar: CustomToolbar }}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 100 },
              },
            }}
            initialState={{
              sorting: {
                sortModel: [{ field: 'kdnr', sort: 'desc' }],
              },
            }}
            sx={{/*
              boxShadow: 2,
              border: 2,
              borderColor: 'primary.dark',
              '& .MuiDataGrid-cell:hover': {
                color: 'primary.main',
              },
              bgcolor: 'background.paper'
            */}}
          />

        </Box>


      </div>

    </>
  );
}


export default KundenListe