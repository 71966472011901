import React from 'react'

import style from "./FilePreviewRemoveButton.module.css";

function FilePreviewRemoveButton(props) {
  return (
    <>
    {props.imgUrl &&
      <div className={style.imageArea}>
        <img
          src={props.imgUrl}
          alt="Newsletter Bild"
        />
        <a className={style.removeImage} onClick={props.handleRemove}>&#215;</a>
      </div>
    }
    </>
  )
}

export default FilePreviewRemoveButton