import React, { useState } from 'react'
import { Container, Row, Col, Tab, Tabs } from 'react-bootstrap'

import BestaetigungenListeOffen from './BestaetigungenListeOffen'
import BestaetigungsListeAngenommen from './BestaetigungsListeAngenommen'
import BestaetigungsListeAbgelehnt from './BestaetigungsListeAbgelehnt'

function BestaetigungenListe() {

  const [activeTab, setActiveTab] = useState(1);

  return (
    <div style={{ marginLeft: "0px", marginTop: "30px", width: "96%" }}>
       
        <div className="mainWork">
            <Tabs
                activeKey={activeTab}
                onSelect={(key) => setActiveTab(parseInt(key))}
                //defaultActiveKey="neueaufgabe"
                id="fill-tab-example"
                fill
                style={{ display: "inline-flex", width: "auto", marginTop: "0px", padding: "0px"}}
            >

                <Tab style={{ background: "white", position: "relative", paddingTop: "15px" }} eventKey="1" title="Offene Bestätigungen">
                    <div style={{ minHeight: "400px", overflowY: "auto"}} id="test">
                        <BestaetigungenListeOffen activeTab={activeTab} />
                    </div>
                </Tab>
                <Tab style={{ background: "white", position: "relative", paddingTop: "15px" }} eventKey="2" title="Angenommen">
                    <BestaetigungsListeAngenommen activeTab={activeTab} />
                </Tab>

                <Tab style={{ background: "white", position: "relative", paddingTop: "15px" }} eventKey="3" title="Abgelehnt">
                    <BestaetigungsListeAbgelehnt activeTab={activeTab} />
                </Tab>
            
            </Tabs>

        </div>
    </div>
  )
}

export default BestaetigungenListe