function downloadPDF(base64pdf) {
  const linkSource = base64pdf;
  const downloadLink = document.createElement("a");
  // open the link in a new tab
  downloadLink.target = "_blank";
  const fileName = "VIN1-Newsletter.pdf";

  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
}


function showImgModal(base64img) {
  const linkSource = base64img;
  const downloadLink = document.createElement("a");
  // open the link in a new tab
  downloadLink.target = "_blank";
  const fileName = "VIN1-Newsletterbild.png";

  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
}

module.exports = {
  downloadPDF,
  showImgModal
};
