import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import AuthAxios from "../../utils/AuthAxios";
import Swal from "sweetalert2";

function NewsLetterHelperModal({ open, setOpen, newsletter, setNewsletter }) {
  const handleClose = () => setOpen(false);

  const [newsletterText, setNewsletterText] = useState();
  const [totalWords, setTotalWords] = useState();
  const [isGenerating, setIsGenerating] = useState(false);
  const [generatedText, setGeneratedText] = useState("");

  async function generateNewsletterText() {
    if (!newsletterText || !totalWords) {
      return Swal.fire({
        icon: "error",
        title: "Bitte fülle alle Felder aus",
        showConfirmButton: false,
        timer: 1500,
      });
    }
    try {
      setGeneratedText("");
      setIsGenerating(true);
      const response = await AuthAxios.post(
        "/newsletter/generate-newsletter-content",
        {
          newsLetterText: newsletterText,
          totalWords: totalWords,
        }
      );
      console.log(response);
      setGeneratedText(response.data);
    } catch (error) {
      console.log(error);
    }
    setIsGenerating(false);
  }

  return (
    <Modal show={open} onHide={handleClose} fullscreen={true}>
      <Modal.Header closeButton>
        <Modal.Title>Generiere Newsletter Text</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="mb-3">
          <Form.Group controlId="formMessage">
            <Form.Label>Nachricht</Form.Label>
            <Form.Control
              value={newsletterText || ""}
              onChange={(e) => setNewsletterText(e.target.value)}
              as="textarea"
              rows={3}
              placeholder="Gebe hier deinen Text ein"
            />
          </Form.Group>
          <Form.Group controlId="totalWord">
            <Form.Label>Anzahl Wörter</Form.Label>
            <Form.Control
              value={totalWords || ""}
              onChange={(e) => setTotalWords(e.target.value)}
              type="number"
            />
          </Form.Group>
          {
            // Show the generated text if it exists
            generatedText && (
              <Form.Group>
                <Form.Label>Generierter Text</Form.Label>
                <Form.Control
                  defaultValue={generatedText || ""}
                  as="textarea"
                  rows={20}
                  placeholder="Generierter Text"
                />
              </Form.Group>
            )
          }
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          disabled={isGenerating}
          onClick={!isGenerating ? generateNewsletterText : null}
        >
          {isGenerating ? "Generiere Text..." : "Generiere Text"}
        </Button>
        {generatedText && (
          <>
            <Button
              variant="success"
              disabled={isGenerating}
              onClick={() => {
                setNewsletter({ ...newsletter, message: generatedText });
                handleClose();
              }}
            >
              Übernehmen
            </Button>
            <Button
              variant="warning"
              disabled={isGenerating}
              onClick={!isGenerating ? generateNewsletterText : null}
            >
              Nochmal
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default NewsLetterHelperModal;
