import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { MainContext } from '../contexts/MainContext';

import { Row, Col } from "react-bootstrap";
import { COLORS } from "../constants";

import { formatDate } from '../utils/date';

function InputKundendaten(props) {

  const { user } = useContext(MainContext);

  const navigate = useNavigate();

  /*
  useEffect(() => {
    if(user && Number.isInteger(parseInt(user.mitarbeiterId))) {
      props.setSelect("mitarbeiter", {"value":user.mitarbeiterId, label: user.mitarbeiterId+" | "+user.vorname+" "+user.nachname})
    }
  }, [])
  */


  const handleOpenTask = (id) => {
    navigate('/dash/aufgabe/'+id)
  }
  

  return (
    <>

      <Row>
        <Col xs={12} md={6} lg={6} xl={6}>
          <fieldset
            className="border"
            style={{ background: "white", borderRadius: "8px" }}
          >
            <legend
              className="float-none w-auto p-2"
              style={{
                color: COLORS.primCol,
                background: COLORS.primBG,
                borderRadius: "8px",
                fontSize: "12px",
              }}
            >
              Stammdaten
            </legend>

            <Row>
              
                {/*props.data._id && 
                  <p style={{ margin: "0px", fontSize: "0.9em" }}>
                    Kunde seit: {new Intl.DateTimeFormat('de-DE', {
                        month: '2-digit',
                        day: '2-digit',
                        year: 'numeric'
                      }).format(new Date(props.data.createdAt))}
                  </p>
                */}

              <Col xs={12} md={6}>
                <p>Kundennummer: <span>{props.data.kdnr ? props.data.kdnr : <i>nicht hinterlegt</i>}</span></p>

                <p>Kontaktart: <span style={{textTransform: "capitalize"}}>{props.data.kontaktart ?? props.data.kontaktart}</span></p>
            
                <b>Kontaktdaten</b><br />
                {props.data.firma && <><span>Firma: <span>{props.data.firma ? props.data.firma : ""}</span></span><br /></>}
                <span>
                    <span> {props.data.anrede ? props.data.anrede: <i>-Anrede nicht hinterlegt-</i>}</span>
                    <span> {props.data.ansprache ? props.data.ansprache : ""}</span>
                </span><br />
                
                <span>{props.data.vorname ? props.data.vorname : <i>-Vorname nicht hinterlegt-</i>}</span>
                <span> {props.data.nachname ? props.data.nachname : <i>-Nachname nicht hinterlegt-</i>}</span><br />
                    
                <span>Geburtsdatum: <span>{props.data.geburtsdatum && props.data.geburtsdatum != "0000-00-00" ? formatDate(props.data.geburtsdatum) : <i>-nicht hinterlegt-</i>}</span></span><br />
                <br />
              

                <b>Anschrift</b><br />
                <span>{props.data.anschrift ? props.data.anschrift : <i>-Anschrift nicht hinterlegt-</i>}</span><br />
                <span>{props.data.plz ? props.data.plz : <i>-PLZ nicht hinterlegt-</i>}</span>&nbsp;
                <span>{props.data.stadt ? props.data.stadt : <i>-Stadt nicht hinterlegt-</i>}</span><br />
                <span>{props.data.land ? props.data.land : <i>-Land nicht hinterlegt-</i>}</span><br />
              </Col>

            </Row>
          </fieldset>
        </Col>

        <Col xs={12} md={12} lg={6} xl={6} style={{ overflowX: "hidden" }}>
          <fieldset
            className="border"
            style={{
              background: "white",
              borderRadius: "8px",
              display: "flex",
              flexDirection: "column"
            }}
          >
            <legend
              className="float-none w-auto p-2"
              style={{
                color: COLORS.primCol,
                background: COLORS.primBG,
                borderRadius: "8px",
                fontSize: "12px",
              }}
            >
              Letzte Aktivität
            </legend>

            <ul style={{listStyleType: "none", fontSize: "15px", paddingLeft: "0px"}}>

              {props.letzteAktivität.length >= 1 ? props.letzteAktivität.map((aufgabe) => 

                <li key={aufgabe._id} className="mb-3" style={{cursor: "pointer"}} onClick={() => handleOpenTask(aufgabe._id)} >
                  <span style={{fontSize: "12px"}}>{new Date(aufgabe.createdAt).toLocaleDateString('de-De', {day: '2-digit', month: '2-digit', year: 'numeric'}).substring(0,10)}</span><br />
                  <span style={{textDecoration: "underline"}}>{aufgabe.betreff}</span>
                </li>
                )
                : 
                <li>
                  <p>Es liegt keine Aktivität vor.</p>
                </li>
              }

            </ul>

          </fieldset>
        </Col>
      </Row>
    </>
  );
}
export default InputKundendaten;
