import { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Tab, Tabs } from 'react-bootstrap';

import AboAnfragenOffenListe from './AboAnfragenOffenListe';
import AboAnfragenAbgelehntListe from './AboAnfragenAbgelehntListe';
import AboFortführenBeenden from './AboFortführenBeenden';


function AboAnfragen() {

  let { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [activeTab, setActiveTab] = useState(1);


  return (

    <div
        style={{
            marginLeft: "0px",
            marginTop: "30px",
            marginBottom: "0px",
            width: "100%",
            height: "100%",
        }}
    >


            <div className="mainWork">
                <Tabs
                    activeKey={activeTab}
                    onSelect={(key) => setActiveTab(parseInt(key))}
                    defaultActiveKey="stammdaten"
                    id="fill-tab-example"
                    fill
                    style={{ display: "inline-flex", width: "auto", marginTop: "0px", padding: "0px" }}
                >
                    <Tab style={{ background: "white", position: "relative", paddingTop: "2px" }} eventKey="1" title="Offene Abos">
                        <AboAnfragenOffenListe activeTab={activeTab} tab={"1"} />
                    </Tab>

                    <Tab style={{ background: "white", position: "relative", paddingTop: "1px" }} eventKey="2" title="Abgelehnte Abos">
                        <AboAnfragenAbgelehntListe activeTab={activeTab} tab={"2"} />
                    </Tab>

                    <Tab style={{ background: "white", position: "relative", paddingTop: "2px" }} eventKey="3" title="Akzeptierte Abos ändern">
                        <AboFortführenBeenden activeTab={activeTab} tab={"3"} />
                    </Tab>

                </Tabs>
            </div>
   

    </div>
  )
  
}

export default AboAnfragen;