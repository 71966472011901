import React, {useState, useEffect} from "react";
import ReactSelect, { createFilter }  from "react-select";

import { Button, Col, Row, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

//import Länder from '../Data/LaenderListe';

import AuthAxios from "../utils/AuthAxios";
import Swal from 'sweetalert2'

function SelectVerantwortung (props) {

    const [mitarbeiter, setMitarbeiter] = useState([]);
    const [mitarbeiterActive, setMitarbeiterActive] = useState([]);

    useEffect(() => {
      
        async function getMitarbeiterVerantwortung () {

            try {
                const res = await AuthAxios.get('/user');

                const activeMitarbeiterList = [];
                let aktiveMitarbeiter = res.data.filter((ma) => ma.loginGesperrt !== true);
                aktiveMitarbeiter.map(ma => {
                    activeMitarbeiterList.push({"value": ma._id, "label": ma.vorname+" "+ma.nachname});
                });
                setMitarbeiterActive(activeMitarbeiterList);

                const mitarbeiterList = [];
                res.data.map(ma => {
                    mitarbeiterList.push({"value": ma._id, "label": ma.vorname+" "+ma.nachname});
                });
                setMitarbeiter(mitarbeiterList);
            }
            catch(err) {
                console.log(err);
                Swal.fire({
                    icon: 'error',
                    title: "Fehler",
                    html: "Mitarbeiter konnten nicht geladen werden."
                });
            }
               
        }

        getMitarbeiterVerantwortung();
  
    }, []);

    useEffect(() => {
        // Warten bis Props geladen sind!
        if(props.selectedValue !== null && props.selectedValue !== undefined) {
            setMitarbeiter([{label: props.selectedValue.label, value: props.selectedValue.value}]);
        }
    },[props.selectedValue])

    const handleRemove = () => {
        props.setSelect("verantwortung", "");
    }

    const handleChange = (name, selection) => {
        props.setSelect(name, {label: selection.label, value: selection.value}); // Object
    };


    return (
        <Row>
            <Form.Label>Verantwortung</Form.Label>
            <Col xs={12} >
                <ReactSelect
                    value={props.selectedValue ? mitarbeiter.filter((f) => f.value == props.selectedValue?.value) : null}
                    onChange={(selection, e) => {
                        if (e.action === 'clear') {
                            handleRemove();
                        } else {
                            handleChange(e.name, selection);
                        }
                    }}
                    placeholder={<div className="select-placeholder-text">Suche nach Mitarbeiter</div>}
                    options={mitarbeiterActive}
                    noOptionsMessage={() => "Suche nach Mitarbeiter um Optionen zu erhalten."}
                    filterOption={createFilter({ignoreAccents: false})}
                    name="verantwortung"
                    //onInputChange={handleInputChange}
                    isClearable={true}
                />
            </Col>
            {/*
            <Col xs={1} md={1}>
            {selected !== "" &&
                <Button style={{maxWidth: "40px", aspectRatio: "1"}} onClick={handleRemove}>
                    <FontAwesomeIcon
                        icon={faXmark}
                        size="1x"
                        title="Verantwortlichen entfernen"
                    />
                </Button>
            }
            </Col>*/}
        </Row>
    );
      
}

export default SelectVerantwortung