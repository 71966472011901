import { useLocation } from "react-router-dom";

import Swal from "sweetalert2";
import AuthAxios from "../../utils/AuthAxios";


import React, { useEffect, useMemo, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";

import AufgabenartModal from "../../Bausteine/AufgabenartModal";


function AufgabenArtenVerwaltung(props) {
  
  const activeTabMemo = useMemo(() => props.activeTab);

  const [aufgabenarten, setAufgabenarten] = useState([]);
  const [newAufgabenart, setNewAufgabenart] = useState("");

  const [showCreateAufgabenart, setShowCreateAufgabenart] = useState(false);

  
  useEffect(() => {
    AuthAxios.get("/aufgabenart")
    .then((res) => {
      setAufgabenarten(res.data);
    })
    .catch((err) => {
      console.log(err);
      Swal.fire({
        icon: "error",
        title: "Fehler",
        html: "Aufgabenarten konnten nicht abgerufen werden.",
      });
    });
  }, []);

  /*
  const handleRemove = (aufgabenart) => {
    // noch nicht implementiert
    AuthAxios.post("/aufgabenart/remove", {
      art: aufgabenart
    })
    .then((res) => {
     let filteredArr = aufgabenarten.filter(element => element !== aufgabenart);
      setAufgabenarten(filteredArr);
    })
    .catch((err) => {
      console.log(err);
      Swal.fire({
        icon: "error",
        title: "Fehler",
        html: "Aufgabenart konnte nicht gelöscht werden.",
      });
    });
  }
  */


  const createNewAufgabenart = () => {

    if (
      newAufgabenart === undefined ||
      newAufgabenart === ""
    ) {
      Swal.fire({
        icon: "error",
        title: "Fehler",
        html: "Vergib einen Namen für die Aufgabenart und versuche es erneut.",
      });
      return;
    }

    AuthAxios.post("/aufgabenart", {
      aufgabenart: newAufgabenart
    })
      .then((res) => {
        setAufgabenarten([
          ...aufgabenarten,
          res.data.aufgabenart,
        ]);

        setNewAufgabenart("");

        Swal.fire({
          icon: "success",
          title: "Erfolg",
          html: "Die Aufgabenart wurde erfolgreich angelegt!",
        });
      })
      .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Fehler",
            html: "Der Service steht momentan nicht zur Verfügung.",
          });
      });
  };

  return (
    <Container fluid>
       <Row className="mb-3" style={{paddingLeft: "10px"}}>
        <Col>
          <p>Aufgabenarten werden dem Kunden in der VIN1 App angezeigt und dienen der Gruppierung der Aufgaben nach Anliegen.</p>
          {/*<p>Gruppen sind momentan nur durch die IT veränderbar, wenn diese angelegt wurden.</p>*/}
        </Col>
      </Row>
      <Row className="mb-3" style={{paddingLeft: "10px"}}>
        <Col>
          <Button onClick={() => setShowCreateAufgabenart(true)}>Neue Aufgabenart anlegen</Button>
        </Col>
      </Row>
      <Row>    
        <Col style={{paddingLeft: "30px"}}>
          <h6>Hier siehst du deine aktuell angelegten Aufgabenarten</h6>
          <table className="table" style={{maxWidth: "500px"}}>
            <thead>
              <tr>
                <th scope="col" colSpan={1}>Aufgabenart</th>
              </tr>
            </thead>
            <tbody>
              {aufgabenarten.length == 0 &&
               <tr>
                  {/*<th scope="row">2</th>*/}
                  <td><i>Keine Aufgabenarten vorhanden</i></td>
                </tr>
              }
              {aufgabenarten.map((art, index) => 
                <tr key={index}>
                  <td>{art}</td>
                  {/*<td className="text-right text-4 font-medium">
                      <a href="#" onClick={() => props.handleRemove(art)}>
                        Entfernen
                      </a>
                    </td>
                  */}
                </tr>
              )}
             
            </tbody>
          </table>  
        </Col>
      </Row>


      {showCreateAufgabenart &&
        <AufgabenartModal title={"Aufgabenart anlegen"} setChanged={props.setChanged} visible={showCreateAufgabenart} setVisible={setShowCreateAufgabenart} newAufgabenart={newAufgabenart} setNewAufgabenart={setNewAufgabenart} createNewAufgabenart={createNewAufgabenart} />
      }

    </Container>
  );
}

export default AufgabenArtenVerwaltung;
