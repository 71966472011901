import React, { useEffect, useState } from "react";
//import Input from "../../Input/Input";

import { useNavigate } from "react-router-dom";

import { Row, Col } from "react-bootstrap";

import { deDE, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton, GridToolbarQuickFilter, GridToolbarColumnsButton } from '@mui/x-data-grid';
import StripedDataGrid from "../../Bausteine/StripedDataGrid";
import Box from "@mui/material/Box";

import Swal from "sweetalert2";
import AuthAxios from "../../utils/AuthAxios";

function GruppenAufgabenListe(props) {

  const navigate = useNavigate();

  async function getAufgaben() {
    AuthAxios.get("/task/gruppen-aufgaben")
      .then((res) => {
        props.setAufgabenListe(res.data);
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Fehler",
          html: "Aufgaben konnten nicht geladen werden.",
        });
      });
  }



  useEffect(() => {
    getAufgaben();
  }, []);


  useEffect(() => {

    async function reloadAufgabenAusBereich() {
      await getAufgaben();
      props.setReloadTasks(false);
      Swal.fire({
        icon: "success",
        html: '"Abgeschlossene Aufgaben" wurden erneut geladen.'
      })
    }

    if(props.reloadTasks) {
      reloadAufgabenAusBereich();
    }
  }, [props.reloadTasks]);




  useEffect(() => {
    if (props.activeTab === 3) {

      getAufgaben();
    }
  }, [props.activeTab]);




  const handleNavigation = (e) => {
    //console.log(e.id);
    props.setActiveTab(1);
    navigate("/dash/aufgabe/" + e.id);
  };





  const columns = [
    {
      field: "kunde",
      headerName: "Verkn. Kunde",
      minWidth: 180,
      flex: 1,
      valueGetter: (params) =>
        params.row.kunde?.label ? params.row.kunde.label : "-",
      headerClassName: "super-app-theme--header",
    },
    {
      field: "art",
      headerName: "Art",
      minWidth: 150,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    /*
        {
          field: "project",
          headerName: "Proj.",
          width: 80,
          headerClassName: "super-app-theme--header",
        },
    */
    {
      field: "betreff",
      headerName: "Betreff",
      minWidth: 280,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    /*
      {
        field: "verantwortung",
        headerName: "Verantw.",
        width: 120,
        headerClassName: "super-app-theme--header",
      },
    */
    {
      field: "mitarbeiter2",
      headerName: "Bearbeiter",
      minWidth: 150,
      flex: 1,
      valueGetter: (params) =>
        params.row.mitarbeiter !== undefined
          ? params.row.mitarbeiter.label
          : "NaN",
      headerClassName: "super-app-theme--header",
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 120,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "deadline",
      headerName: "Deadline",
      minWidth: 120,
      flex: 1,
      renderCell: (params) => (
        <>
          {params.row.deadline ?
            <span
              style={{
                padding: "4px",
                background: new Date(params.row.deadline) <= new Date() ? "red" : "green",
                color: new Date(params.row.deadline) <= new Date() ? "white" : "white"
              }}
            >
              {new Date(params.row.deadline).toLocaleString("de-DE", {day: '2-digit', month: '2-digit', year: 'numeric'}).substring(0,10)}</span>
            :
              <span>-</span>
          }
        </>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "prio",
      headerName: "Prio.",
      minWidth: 80,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "createdAt",
      headerName: "Erstellt",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <span>{new Date(params.row.createdAt).toLocaleString("de-De")}</span>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "updatedAt",
      headerName: "Zul. Geändert",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <span>{new Date(params.row.updatedAt).toLocaleString("de-De")}</span>
      ),
      headerClassName: "super-app-theme--header",
    },
  ];


  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <Row style={{ display: "flex", justifyContent: "flex-start", width: "100%" }}>
          <Col xs={12} lg={2}>
            <GridToolbarQuickFilter />
          </Col>
          <Col xs={6} lg={1}>
            <GridToolbarFilterButton />
          </Col>
          <Col xs={6} lg={1}>
            <GridToolbarColumnsButton />
          </Col>
        </Row>
      </GridToolbarContainer>
    );
  }



  return (
    
   
      <div style={{ height: `calc(100vh - 20px)`, width: "100%" }}>
      {/*<div style={{width: "100%" }}>*/}
      
        {props.activeTab === 3 && (
          <>
          <Box
            sx={{
              height: `calc(100% - 70px)`,
              width: "100%",
              "& .super-app-theme--header": {
                backgroundColor: "#c58164",
              },
              "& .MuiDataGrid-toolbarContainer": {
                padding: "10px",
              },
              margin: "0px",
              padding: "5px 10px",
              fontWeight: "bold",
              boxShadow: 3,
            }}
          >
            <StripedDataGrid
              rows={props.data}
              columns={columns}
              getRowId={(row) => row._id}
              onRowClick={handleNavigation}
              pageSize={30}
              rowsPerPageOptions={[30]}
              density={"compact"}
              localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
              }
              components={{ Toolbar: CustomToolbar }}
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 100 },
                },
              }}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'kdnr', sort: 'desc' }],
                },
              }}
              sx={{/*
                boxShadow: 2,
                border: 2,
                borderColor: 'primary.dark',
                '& .MuiDataGrid-cell:hover': {
                  color: 'primary.main',
                },
                bgcolor: 'background.paper'
              */}}
            />
          </Box>
        </>
      )}

    </div>
      
  );
}

export default GruppenAufgabenListe;
