import React from 'react'

import { Progress } from 'antd';

import style from "./CardAnalytics.module.css"

import { Row, Col } from 'react-bootstrap';


function CardAnalytics(props) {

  const percent = props.usage/props.limit;

  return (
   
      <div className={style.cardContainerUpload} style={{marginBottom: "20px", padding: "10px 20px 0px 15px"}}>
         <Row style={{width:"100%"}}>
            <Col style={{display: "flex", flex: "1", flexDirection: "row", justifyContent: "space-between", padding: "10px 0px 0px 15px"}}>
               <span>{props.usage} {props.einheitProgress}</span>
               <span>{props.limit/props.faktor} {props.einheitLimit}</span>
            </Col>
        </Row>
        <Row style={{width:"100%"}}>
            <Col style={{display: "flex", flex: "1", flexDirection: "column", alignItems: "flex-start", padding: "10px 0px 0px 15px" }}>
                <Progress percent={percent} showInfo={false} />
            </Col>
        </Row>
        <Row style={{width:"100%"}}>
          <div style={{display: "flex", flex: "1", flexDirection: "row", justifyContent: "space-between", padding: "10px 0px 10px 15px"}}>
            <h5 style={{justifyContent: "left", display: "inline-flex"}}>{props.title}</h5>
            <span>
              {
                percent > 1 ?
                  percent.toFixed(1) : percent.toFixed(4)
              }
              %</span>
          </div>
        </Row>
      </div>
  )
}

export default CardAnalytics
