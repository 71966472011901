import React, { useState, useContext, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";
import axios from "axios";
import { MainContext } from '../contexts/MainContext';

import './Login.css';

export default function (props) {

  let [authModeLogin, setAuthModeLogin] = useState(true);
  const { jwt, setJwt, user, setUser } = useContext(MainContext);
  const navigate = useNavigate();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [resetMail, setResetMail] = useState('');

  const usernameRef = useRef();
  const passwordRef = useRef();
  const resetMailRef = useRef();


  const changeAuthMode = () => {
    setAuthModeLogin(!authModeLogin)
  }



  const handleLogin = async (event) => {
    event.preventDefault();

    let username = usernameRef.current.value;
    let password = passwordRef.current.value;

    try {
      const credentials = {
        username,
        password
      }

      let response = await axios.post(`${process.env.REACT_APP_BACKEND}/api/v1/user/login`, credentials)
    
      sessionStorage.setItem('token', response.data.token);
      sessionStorage.setItem('user', JSON.stringify(response.data.user));
      setJwt(response.data.token);
      setUser(response.data.user);
      navigate('/dash/home');
    } catch (err) {

      if(err.response.status == 403)
      {
        Swal.fire({
          icon: "info",
          title: "Login",
          html: "Benutzername oder Passwort fehlerhaft."
        });
      }
      if(err.response.status == 401)
      {
        Swal.fire({
          icon: "info",
          title: "Login",
          html: "Ein Login ist für dieses Benutzerkonto momentan nicht möglich."
        });
      }
      else
      {
        Swal.fire({
          icon: "info",
          title: "Login",
          html: "Login zur Zeit nicht möglich. Backend nicht erreichbar."
        });
      }
    }
  }


  const handleResetPassword = async (event) => {
    event.preventDefault();

    let resetMail = resetMailRef.current.value;

    axios.post(`${process.env.REACT_APP_BACKEND}/api/v1/user/forgot-password`, { email: resetMail} )
    .then(res => {

        Swal.fire({
          icon: "success",
          title: "Password Reset",
          html: "Du erhältst eine E-Mail mit einem Link, um dein Passwort zurückzusetzen."
        });

        setAuthModeLogin(false);
    })
    .catch(err => {
      Swal.fire({
        icon: "info",
        title: "Password Reset",
        html: "Kontrolliere deine Eingabe oder ein Rücksetzen des Passworts ist momentan nicht möglich."
      });
    })
  }

  useEffect(() => {
    if (jwt || jwt !== '') {
      return navigate('/dash')
    }
  }, [jwt, navigate])





  if (authModeLogin) {
    return (
      <section className="bg-gray-50 dark:bg-gray-900 w-100">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
            <a href="https://loka1.eu" target="_blank" className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
                <img className="w-auto h-14 mr-2" src="/Loka1_Logo.png" alt="logo" />    
            </a>  
            <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                    <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                        Login
                    </h1>
                    <form className="space-y-4 md:space-y-6" action="#">
                        <div>
                            <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">E-Mail</label>
                            <input type="email" ref={usernameRef} name="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="name@company.com" required="" />
                        </div>
                        <div>
                            <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Passwort</label>
                            <input type="password" ref={passwordRef} name="password" id="password" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required="" />
                        </div>
                        <div className="flex items-center justify-between">
                            <div className="flex items-start">
                              {/*
                                <div className="flex items-center h-5">
                                  <input id="remember" aria-describedby="remember" type="checkbox" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800" required="" />
                                </div>
                                <div className="ml-3 text-sm">
                                  <label htmlFor="remember" className="text-gray-500 dark:text-gray-300">Remember me</label>
                                </div>
                                */}
                            </div>
                            <div
                              className="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500"
                              onClick={() => setAuthModeLogin(false)}
                            >
                              Passwort vergessen?
                            </div>
                        </div>
                        <button
                          className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                          onClick={handleLogin}
                        >
                          Login
                        </button>
                        {/*
                        <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                            Don’t have an account yet? <a href="#" className="font-medium text-primary-600 hover:underline dark:text-primary-500">Sign up</a>
                        </p>
                        */}
                    </form>
                </div>
            </div>
        </div>
      </section>
    )
  }

  return (
     <section className="bg-gray-50 dark:bg-gray-900 w-100">
          <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
          <a href="https://loka1.eu" target="_blank" className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
              <img className="w-auto h-14 mr-2" src="/Loka1_Logo.png" alt="logo" />    
          </a>  
          <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
              <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                  <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                      Passwort vergessen
                  </h1>
                  <form className="space-y-4 md:space-y-6" action="#">
                      <div>
                          <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">E-Mail</label>
                          <input type="email" ref={resetMailRef} name="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="name@company.com" required="" />
                      </div>
                      <div className="flex items-center justify-between">
                          <div className="flex items-start">
                            {/*
                              <div className="flex items-center h-5">
                                <input id="remember" aria-describedby="remember" type="checkbox" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800" required="" />
                              </div>
                              <div className="ml-3 text-sm">
                                <label htmlFor="remember" className="text-gray-500 dark:text-gray-300">Remember me</label>
                              </div>
                              */}
                          </div>
                          <div className="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500"
                            onClick={() => setAuthModeLogin(true)}
                          >
                            Zurück zum Login
                          </div>
                      </div>
                      <button
                        className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                        onClick={handleResetPassword}
                      >
                        Passwort Wiederherstellung starten
                      </button>
                      {/*
                      <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                          Don’t have an account yet? <a href="#" className="font-medium text-primary-600 hover:underline dark:text-primary-500">Sign up</a>
                      </p>
                      */}
                  </form>
              </div>
          </div>
      </div>
    </section>
  )
}