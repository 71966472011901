import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
//import { MainContext } from '../../contexts/MainContext';
import {
  Alert,
  Container,
  Row,
  Col,
  Form,
  Modal,
  Button,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";

import Spinner from "../../Bausteine/Spinner/Spinner"

import { COLORS } from "../../constants";
import AuthAxios from "../../utils/AuthAxios";
import Swal from "sweetalert2";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSave, faXmark, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import NewsletterTable from "./NewsletterTable";
import CropImageModal from "./CropImageModal";
import b64toBlob from "../../utils/cropper";

import NewsLetterHelperModal from "./NewsLetterHelperModal";
import NewsletterCard from "./NewsletterCard";
import Neuigkeiten from "./Neuigkeiten";
import FilePreviewRemoveButton from "../../Bausteine/FilePreviewRemoveButton";


export const getCurrentDateTime = () => {
  const now = new Date();
  const date = now.toISOString().slice(0, 10);
  const time = now.toISOString().slice(11, 16);
  return `${date}T${time}`;
};


function Newsletter() {

  const [spinner, setSpinner] = useState(false);
  const [alertSuccess, setAlertSuccess] = useState("");
  const [alertError, setAlertError] = useState("");
  const [allNewsletter, setAllNewsletter] = useState([]);
  const [showHelper, setShowHelper] = useState(false);
  const [publishDirectly, setPublishDirectly] = useState(false);
  const [isAdvertisement, setIsAdvertisement] = useState(false);

  const [image, setImage] = useState(null);
  const [pdfFile, setPdfFile] = useState(null);

  const [changed, setChanged] = useState(false);
  const [reload, setReload] = useState(false);

  const [newsletter, setNewsletter] = useState({});
  async function getNewsLetterFromUser() {
    try {
      const response = await AuthAxios.get("/newsletter");
      //console.log(response.data);
      setAllNewsletter(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getNewsLetterFromUser();
  }, []);


  const renderTooltip = props => (
    <Tooltip {...props}>Tooltip for the Register button</Tooltip>
  );




  async function createNewNewsletter() {

    try {
      if (!newsletter.title || !newsletter.message || !newsletter.publishDate || !image) {
        Swal.fire({
          icon: "info",
          title: "Pflichtfelder",
          html: "Bitte fülle alle Pflichtfelder aus und klicke erneut auf speichern.",
        });
        return;
      }

      setSpinner(true);

      const formData = new FormData();
      formData.append("publishDirectly", publishDirectly);
      formData.append("isAdvertisement", isAdvertisement);
      formData.append("title", newsletter.title);
      formData.append("message", newsletter.message);
      formData.append("publishDate", newsletter.publishDate);

      if(newsletter.externeUrl) {
        formData.append("externeUrl", "https://"+newsletter.externeUrl);
      }

      //Dateien
      if(image) {
        formData.append("image", image);
      }
      //optional
      if(pdfFile) {
        formData.append("pdfFile", pdfFile);
      }


      AuthAxios({
        method: 'post',
        url: "/newsletter",
        headers: { "Content-Type": "multipart/form-data" }, // Mixed Data with Files and JSON
        data: formData
      })
      .then((res) => {

        //Update props to show in Newsletter Table!
        setAllNewsletter([
          res.data,
          ...allNewsletter
        ]);

        Swal.fire({
          icon: "success",
          title: "Erfolg",
          html: "Der Newsletter wurde erfolgreich angelegt.",
        });
        
        setSpinner(false);

      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Formular",
          html: "Es ist ein Fehler aufgetreten. Bitte erneut versuchen.",
        });
        setSpinner(false);
      });
      
      
    }
    catch(error) {
      Swal.fire({
        icon: "error",
        title: "Fehler",
        html: "Newsletter konnte nicht erstellt werden",
      });
      setSpinner(false);
    }
    
  }



  const handleUpdateNewsletterData = (updatedNewsletter) => {

    setAllNewsletter((prevNewsletters) => {
      // Find the index of the newsletter with the matching ID
      const updatedIndex = prevNewsletters.findIndex(
        (newsletter) => newsletter._id === updatedNewsletter._id
      );
  
      if (updatedIndex === -1) {
        // If the newsletter with the given ID doesn't exist, return the original array
        return prevNewsletters;
      }
  
      // Create a new array with the updated newsletter at the specified index
      const updatedNewsletters = [
        ...prevNewsletters.slice(0, updatedIndex),
        updatedNewsletter,
        ...prevNewsletters.slice(updatedIndex + 1),
      ];
  
      return updatedNewsletters;
    });
  };



  const [imageUrl, setImageUrl] = useState(null);
  
  //const [showModal, setShowModal] = useState(false);
  //const [croppedImageUrl, setCroppedImageUrl] = useState();


  const handleImageInputChange = (event) => {
    setChanged(true);
    const file = event.target.files[0];
    setImage(file);
    setImageUrl(URL.createObjectURL(file));
    /*
      const selectedFile = event.target.files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        setImageUrl(reader.result);
        //setShowModal(true); // crop Modal
      };
      reader.readAsDataURL(selectedFile);
    */
  };


  const handlePDFInputChange = (event) => {
    setChanged(true);
    const file = event.target.files[0];
    setPdfFile(file);
    //setImageUrl(URL.createObjectURL(file));
    /*
      const selectedFile = event.target.files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        setImageUrl(reader.result);
        //setShowModal(true); // crop Modal
      };
      reader.readAsDataURL(selectedFile);
    */
  };



  const newNewsletter = () => {
    setNewsletter({});
    setPublishDirectly(false);
    setIsAdvertisement(false);
  };



  const cancelEdit = () => {
    Swal.fire({
      icon: "info",
      title: "Änderungen verwerfen",
      html: "Willst du deine Änderungen wirklich verwerfen?",
      showDenyButton: true,
      confirmButtonText: 'Verwerfen',
      denyButtonText: `Abbrechen`
    })
    .then( async (result) => {
      if (result.isConfirmed) {
        setNewsletter({});
        setIsAdvertisement(false);
        setPublishDirectly(false);
        setImage(null);
        setImageUrl(null);
        setPdfFile(null);
        setChanged(false);
        setReload(true);
      } 
    })
  }

  return (
    <>
      <Container fluid className="mt-5">
        <Row className="mainActions">
          <Col xs={12} sm={12}>
            <span>
              <FontAwesomeIcon
                icon={faPlus}
                size="2x"
                className="actionIcon"
                onClick={newNewsletter}
                title="Neuen Newsletter anlegen"
              />
            </span>
            {changed && 
              <>
                <span style={{paddingLeft: "8px"}}>
                  <FontAwesomeIcon
                    icon={faSave}
                    size="2x"
                    className="actionIcon"
                    onClick={createNewNewsletter}
                    title="Newsletter speichern"
                  />
                </span>
                <span style={{paddingLeft: "8px"}}>
                  <FontAwesomeIcon
                    icon={faXmark}
                    size="2x"
                    className="actionIcon"
                    onClick={cancelEdit}
                    title="Änderungen verwerfen"
                  />
                </span>
              </>
            }
          </Col>
          <Col xs={12} sm={5} className="meldungen">
            {alertSuccess.length > 0 && (
              <Col xs={12} md={6}>
                <Alert
                  key={1}
                  variant={"success"}
                  onClick={() => setAlertSuccess("")}
                >
                  {alertSuccess}
                </Alert>
              </Col>
            )}
            {alertError.length > 0 && (
              <Col xs={12} sm={6} md={6}>
                <Alert
                  key={2}
                  variant={"danger"}
                  onClick={() => setAlertError("")}
                >
                  {alertError}
                </Alert>
              </Col>
            )}
          </Col>
        </Row>

        <div
          style={{
            marginLeft: "0px",
            marginTop: "0px",
            marginBottom: "0px",
            width: "100%",
          }}
        >
          <div className="mainWork">
            <Container fluid>
              <Form>
                <Row style={{ display: "flex" }}>
                  <Col xs={12} md={12} xl={4}>
                    <fieldset
                      className="border"
                      style={{ background: "white", borderRadius: "8px" }}
                    >
                      <legend
                        className="float-none w-auto p-2"
                        style={{
                          color: COLORS.primCol,
                          background: COLORS.primBG,
                          borderRadius: "8px",
                          fontSize: "12px",
                        }}
                      >
                        Neuer Newsletter
                      </legend>

                      <Row>
                        <Col>

                        <Form.Group controlId="publishDirectly">
                          <Form.Check
                            type="checkbox"
                            label="Newsletter direkt veröffentlichen"
                            checked={publishDirectly}
                            onChange={(e) => {
                              setChanged(true);
                              if(isAdvertisement){
                                Swal.fire({
                                  icon: "info",
                                  title: "Status",
                                  text: "Angebote müssen direkt veröffentlicht werden."
                                })
                              }
                              else {
                                setPublishDirectly(e.target.checked);
                              }
                            }}
                          />
                        </Form.Group>

                        <Form.Group controlId="isAdvertisement">
                          <Form.Check
                            type="checkbox"
                            label="Beinhaltet Angebot *"
                            checked={isAdvertisement}
                            onChange={(e) => {
                              setChanged(true);
                              setIsAdvertisement(e.target.checked);
                              setPublishDirectly(true);
                            }}
                          />
                          <span style={{fontSize: "90%"}}>
                            * Angebote können in der App gesondert gefiltert werden und
                            aus Gründen der Kundenzufriedenheit nach dem Speichern nicht mehr bearbeitet werden!
                            Sollte dennoch etwas schief laufen kann dies durch die Loka1 IT behoben werden.
                          </span>
                        </Form.Group>

                        {/*
                          <OverlayTrigger placement="top" overlay={renderTooltip}>
                            <FontAwesomeIcon
                              icon={faQuestionCircle}
                              size="2x"
                              className="actionIcon"
                              onClick={newNewsletter}
                              title="Neuen Newsletter anlegen"
                            />
                          </OverlayTrigger>
                        */}

                        <Form.Label>Titel (Pflicht)</Form.Label>
                        <Form.Control
                          type="text"
                          name="titel"
                          value={newsletter.title || ""}
                          placeholder="Titel"
                          onChange={(e) => { 
                            setChanged(true);
                            setNewsletter({
                              ...newsletter,
                              title: e.target.value,
                            });
                          }}
                        />

                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlTextarea1"
                        >
                          <Form.Label>
                            Nachricht (Pflicht)
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={6}
                            placeholder="Nachricht (Pflicht)"
                            name="nachricht"
                            value={newsletter.message ? newsletter.message : ""}
                            onChange={(e) =>{ 
                              setChanged(true);
                              setNewsletter({
                                ...newsletter,
                                message: e.target.value,
                              });
                            }}
                          />
                        </Form.Group>

                        {/*
                        <Button
                          onClick={() => {
                            setShowHelper(true);
                          }}
                          variant="primary"
                        >
                          KI-Unterstützung
                        </Button>
                        <br />
                        */}

                      <Form.Label>
                        Gib ein Datum ein, ab wann die News in der App
                        angezeigt werden sollen. (Pflicht)
                      </Form.Label>
                      <Form.Control
                        type="datetime-local"
                        name="datum"
                        value={newsletter.publishDate || new Date()}
                        min={getCurrentDateTime()}
                        onChange={(e) =>{ 
                          setChanged(true);
                          setNewsletter({
                            ...newsletter,
                            publishDate: e.target.value,
                          });
                        }}
                      />

                    <Form.Label>Bild (.jpg, .png - Pflicht)</Form.Label>
                    {!imageUrl &&
                      <Form.Control
                        id="newsletterImage"
                        name="newsletterImage"
                        type="file"
                        accept="image/png, image/jpeg, image/jpg"
                        value={image ? image.originalname : ""}
                        onChange={handleImageInputChange}
                      />
                    }

                      {/*imageUrl && 
                        <>
                          <img
                            src={imageUrl}
                            alt="imgPreview"
                            style={{
                              width: "auto",
                              maxHeight: "200px",
                              border: "1px solid black",
                              margin: "10px 20px 10px 0px"
                            }}
                          />
                          <Button
                            style={{margin: "10px 0px", background: "red", border: "none"}}
                            onClick={() => {
                              setImage(null);
                              setImageUrl(null);
                            }}
                          >
                            Bild verwerfen
                          </Button>
                        </>
                      */}

                      <div className="mt-3">
                        <FilePreviewRemoveButton
                          imgUrl={imageUrl}
                      
                          handleRemove={() => {
                            setImage(null);
                            setImageUrl(null);
                          }}
                        />
                      </div>

                      <br />
                      <hr />
                      

                      <Form.Group className="mb-3">
                        <Form.Label>PDF</Form.Label>
                        <Form.Control
                          id="pdf"
                          type="file"
                          name="pdf-file"
                          accept="application/pdf"
                          value={pdfFile ? pdfFile.originalname : ""}
                          onChange={handlePDFInputChange}
                        />
                        {pdfFile && 
                          <Button
                            style={{margin: "8px", background: "red", border: "none"}}
                            onClick={() => setPdfFile(null)}
                          >
                            PDF verwerfen
                          </Button>
                        }
                      </Form.Group>





                      <Form.Group>
                        <Form.Label>Externe Url (Blogeinträge, ...)</Form.Label>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text">https://</span>
                            </div>
                            <Form.Control
                              type="text"
                              name="externeUrl"
                              value={newsletter.externeUrl || ""}
                              placeholder="Externe URL"
                              onChange={(e) =>{ 
                                setChanged(true);
                                setNewsletter({
                                  ...newsletter,
                                  externeUrl: e.target.value,
                                });
                              }}
                            />
                          </div>
                      </Form.Group>
       
                        </Col>
                      </Row>
                    </fieldset>
                  </Col>    

                  <Col xs={12} md={12} xl={8}>
                    <h5 style={{margin: "20px 10px 0px 10px"}}>Vorschau des Newsletters</h5>
                      {Object.keys(newsletter).length > 0 && 
                          <>
                            {/*
                            <NewsletterCard
                              image={imageUrl}
                              newstext={newsletter.message ? newsletter.message : ""}
                              title={newsletter.title ? newsletter.title : ""}
                              datum={newsletter.publishDate ? newsletter.publishDate : ""}
                              pdf={pdfFile ? pdfFile : ""}
                              url={newsletter.externeUrl ? "https://"+newsletter.externeUrl : ""}
                            />*/}
                            <Neuigkeiten
                                image={imageUrl}
                                message={newsletter.message ? newsletter.message : ""}
                                title={newsletter.title ? newsletter.title : ""}
                                datum={newsletter.publishDate ? newsletter.publishDate : ""}
                                pdf={pdfFile ? pdfFile : ""}
                                url={newsletter.externeUrl ? "https://"+newsletter.externeUrl : ""}
                            />
                          </>
                      }
                      {Object.keys(newsletter).length == 0 &&
                        <div style={{padding: "10px 10px 0px 10px"}}>
                          <i>Fülle Felder für einen neuen Newsletter aus, um die Vorschau zu generieren.</i>
                        </div>
                      }
                  </Col>

                </Row>
              </Form>

             



              <Row style={{ display: "flex" }}>
                <Col xs={12} md={12} xl={12}>
                  <fieldset
                    className="border"
                    style={{ background: "white", borderRadius: "8px" }}
                  >
                    <legend
                      className="float-none w-auto p-2"
                      style={{
                        color: COLORS.primCol,
                        background: COLORS.primBG,
                        borderRadius: "8px",
                        fontSize: "12px",
                      }}
                    >
                      Deine Newsletter
                    </legend>

                    <Row>
                      <Col xs={12} style={{overflowX: "scroll"}}>
                        <NewsletterTable
                          getNewsLetterFromUser={getNewsLetterFromUser}
                          currentNewsletter={allNewsletter}
                          updateNewsletterData={handleUpdateNewsletterData}
                        />
                      </Col>
                    </Row>
                  </fieldset>
                </Col>
              </Row>
            </Container>
          </div>

          {/*
          <CropImageModal
            src={imageUrl}
            showModal={showModal}
            setShowModal={setShowModal}
            setCroppedImageUrl={setCroppedImageUrl}
            croppedImageUrl={croppedImageUrl}
          />
          */}

          <NewsLetterHelperModal
            open={showHelper}
            setOpen={setShowHelper}
            newsletter={newsletter}
            setNewsletter={setNewsletter}
          />

        </div>

        {spinner &&
          <Spinner 
            message={"Bitte warten, Newsletter wird angelegt."}
          />
        }

      </Container>
    </>
  );
}

export default Newsletter;
