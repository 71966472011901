import React, { useEffect, useState, useContext } from "react";
import { MainContextProvider } from "./contexts/MainContext";

import WebFont from "webfontloader";

// Tailwind
import './tailwind.css';

import "./App.scss";

//Moment
import moment from 'moment';
import 'moment/locale/de';

import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useParams
} from "react-router-dom";
import Login from "./pages/Login";
import Mitarbeiter from "./pages/Mitarbeiter/Mitarbeiter";
import Dash from "./pages/Dash";
import AuthComponent from "./services/AuthComponent";

// Imports in Reihenfolge wie Seitenleiste
import Home from "./pages/Home";
import Analyse from "./pages/Analyse/Analyse";
import Kunde from "./pages/Kunden/Kunde";
import KundenListe from "./pages/KundenListe/KundenListe";
import BestaetigungenListe from "./pages/BestaetigungenListe/BestaetigungenListe"
import Aufgaben from "./pages/Aufgaben/Aufgaben";
import Newsletter from "./pages/Newsletter/Newsletter";
import Gewinnspiel from "./pages/Gewinnspiel/Gewinnspiel";

import MitarbeiterListe from "./pages/Mitarbeiter/MitarbeiterListe";

import PasswordReset from "./pages/PasswordReset";


import { MainContext } from "./contexts/MainContext";
import AboAnfragen from "./pages/Abo/AboAnfragen";



export const ProtectedRoute = ({ children }) => {

  const { jwt } = useContext(MainContext);

  if (!jwt) {
     // user is not authenticated - redirect to Login
    return <Navigate to="/login" />;
  }
  return children;
};






const App = () => {
  //WebFont.load({google: {families: ["Montserrat:300,400,500,700"]}});

  const { id } = useParams();

  moment.locale('de');

  
  //console.log(process.env.REACT_APP_BACKEND, "Test");

  return (
    <MainContextProvider>
      <Router basename="/">
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/password-reset/:token/:id"
            element={<PasswordReset />}
          />

          {/*
              <Route path="/signup" element={
                <Signup />
              } />
            */}

          {/* Alle in diesem Block definierten Path sind Unterpunkte von /dash */}
          <Route
            path="/dash"
            element={
              <ProtectedRoute>
                <AuthComponent>
                  <Dash />
                </AuthComponent>
              </ProtectedRoute>
            }
          >
            <Route path="home" element={<Home />} />

            <Route path="analyse" element={<Analyse />} />

            <Route path="abo" element={<AboAnfragen />} />

            <Route path="kunde" element={<Kunde />} />
            <Route path="kunde/:id" element={<Kunde />} />

            <Route path="kunden" element={<KundenListe />} />

            <Route path="bestaetigungen" element={<BestaetigungenListe />} />

            <Route path="aufgabe" element={<Aufgaben />} />
            <Route path="aufgabe/:id" element={<Aufgaben />} />

            <Route path="newsletter" element={<Newsletter />} />
            <Route path="gewinnspiele" element={<Gewinnspiel />} />

            <Route path="mitarbeiter" element={<Mitarbeiter />} />
            <Route path="mitarbeiter/:id" element={<Mitarbeiter />} />
            <Route path="mitarbeiter" element={<MitarbeiterListe />} />

            <Route path="*" element={
                <ProtectedRoute>
                  <Home />
                </ProtectedRoute>
              }
            />

          </Route>

          <Route path="*" element={<Login />} />
        </Routes>
      </Router>
    </MainContextProvider>
  );
};

export default App;
