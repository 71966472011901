import React, { useContext } from 'react'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faDownload, faTrash } from "@fortawesome/free-solid-svg-icons";

import style from "./CardSub.module.css"

import { Row, Col } from 'react-bootstrap';

import Swal from 'sweetalert2'
import { MainContext } from '../../../contexts/MainContext';
import AuthAxios from '../../../utils/AuthAxios';
//import Dokumente from '../Dokumente/Dokumente';

function CardDokument(props) {

  const { user } = useContext(MainContext);


  async function downloadFile(url, filename) {

    let fileURLObj = await AuthAxios.post('/dokumente/download', {
      googleUID: props.googleUID,
      fileName: filename
    });
    let fileURL = fileURLObj.data;

    const xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = (event) => {
      const blob = xhr.response;

      //url = window.URL.createObjectURL(blob);
      fileURL = window.URL.createObjectURL(blob);
      var a = document.createElement("a");
      a.href = fileURL;
      a.download = filename.substring(36);
      a.click();
      window.URL.revokeObjectURL(fileURL);
    };
    xhr.open('GET', fileURL);
    xhr.send();
    
  }




  return (
   
    <div className={style.cardContainerUpload} style={{
      cursor: "default",
      marginBottom: "20px",
      background: !props.data.customerActionType ? "#fff" : 
        (props.data.bestaetigt && !props.data.abgelehnt) ?
          "rgba(2, 222, 2, 0.3)" : (props.data.customerActionType && !props.data.abgelehnt ? "rgba(100, 100, 222, 0.3)" : "rgba(255, 0, 0, 0.3)")
        
        
    }} >
      <Row style={{width:"100%"}}>
        <Col style={{display: "flex", flex: "11", flexDirection: "column", alignItems: "flex-start", padding: "10px 0px 0px 15px"}}>
          <p className="fw-bold" style={{padding: "0", marginBottom: "5px"}}>{props.data.title.substring(36)} - {new Intl.DateTimeFormat('de-DE', {
              month: '2-digit',
              day: '2-digit',
              year: 'numeric'
            }).format(new Date(props.data.created._seconds *1000))}{props.data.bearbeiter && " | Bearbeiter: "+props.data.bearbeiter}
          </p>
          
          <p style={{padding: "0", marginBottom: "5px"}}>
            {(props.data.customerActionType && !props.data.bestaetigt && !props.data.abgelehnt ) && <span>warte auf Kundenbestätigung.
              {props.data.customerActionNote &&
                <>
                  &nbsp;| Notiz an Kunde: {props.data.customerActionNote}
                </>
              }
              </span>}

            {(props.data.customerActionType && props.data.abgelehnt) &&<span>Abgelehnt: {props.data.abgelehnt}</span>}
            {(props.data.customerActionType && props.data.bestaetigt && props.data.bestaetigt_am) && <span>Bestätigt: {new Intl.DateTimeFormat('de-DE', {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric'
              }).format(new Date(props.data.bestaetigt_am._seconds *1000))}</span>}
            
            {/*
            {(props.data.unterschrift && !props.data.unterschrieben) && <span>warte auf Kundenbestätigung.</span>}
            */}
          </p>

        </Col>

        <Col style={{display: "flex", flex: "1", justifyContent: "flex-end"}}>

          {/* Löschen nur erlauben, wenn keine customerAction gesetzt ist! */}
          {!props.data.customerActionType && 
            <>
             {props.data.partner_name === user.partner_name &&
                <FontAwesomeIcon style={{width:"auto", cursor: "pointer", marginTop: "5px", marginRight: "30px", color: "#707070"}} icon={faTrash} size="lg" onClick={() => {
                   Swal.fire({
                     icon: "info",
                     title: "Dokument löschen",
                     html: "Willst du das Dokument: <b>"+props.data.title.substring(36) + "</b> wirklich löschen?",
                     showDenyButton: true,
                     confirmButtonText: 'Löschen',
                     denyButtonText: `Abbrechen`
                   })
                   .then( async (result) => {
                     if (result.isConfirmed) {
                       props.deleteFileAndDoc(props.id, props.data.title);
                     } 
                   })
                 }
                } />
              }
            </>
          }
               <FontAwesomeIcon style={{width:"auto", cursor: "pointer", marginTop: "5px", color: "#707070"}} icon={faDownload} size="lg" onClick={() => downloadFile(props.data.url, props.data.title)} />    

           </Col>
      </Row>
        
    </div>
      
  )
}

export default CardDokument