import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import style from "./CardSub.module.css";
import { Row, Col, Form } from "react-bootstrap";

function CardUpload(props) {
  return (
    <div className={style.cardContainerUpload} style={{ marginBottom: "20px" }}>
      <Row style={{ width: "100%" }}>
        <Col
          style={{
            display: "flex",
            flex: "11",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: "10px 0px 0px 15px",
          }}
        >
          <p className="fw-bold" style={{ padding: "0", marginBottom: "5px" }}>
            {props.data.name}
          </p>

          {/*
            bestaetigung setzt Feld bestaetigung = true
            unterschrift setzt Feld unterschrift = true
          */}

          <Form.Label>
            Wenn Kundenaktion ausgewählt & Datei hochgeladen, lässt sich das
            Dokument nicht mehr löschen.
          </Form.Label>
          <Form.Select
            onChange={(e) => props.setCustomerAction(props.data.id, e.target.value, "action")}
            value={props.customerAction?.action ? props.customerAction.action : ""}
          >
            <option value="">-</option>
            <option value="bestaetigung">
              Kunden Bestätigung erforderlich
            </option>
            {/*<option value="unterschrift">Kunden Unterschrift erforderlich</option>*/}
          </Form.Select>

          {props.customerAction && (
            <>
              <Form.Label>Kurzbeschreibung für Kunde</Form.Label>
              <Form.Control
                type="text"
                name="customerAction"
                placeholder="Kurzbeschreibung für Kunde"
                value={props.customerAction.note ? props.customerAction.note : ""}
                onChange={(e) => props.setCustomerAction(props.data.id, e.target.value, "note")}
              />
            </>
          )}
        </Col>

        <Col style={{ display: "flex", flex: "1", justifyContent: "flex-end" }}>
          <FontAwesomeIcon
            style={{
              width: "auto",
              cursor: "pointer",
              marginTop: "5px",
              color: "#707070",
            }}
            icon={faXmark}
            size="xl"
            onClick={() => props.removeFileUploadHandler(props.data.id)}
          />
        </Col>
      </Row>
    </div>
  );
}

export default CardUpload;

{
  /*<p style={{color: "grey", padding: "0", marginBottom: "5px"}}>Beschreibung</p>
<input
    type="text"
    placeholder="Hier Beschreibung eingeben..."
    style={{border: "none", width: "100%"}}
    onChange={(e) => props.setDesc(e.target.value.trim())}
/>
*/
}
