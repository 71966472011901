import React, { useContext, useEffect, useState } from "react";
import { MainContext } from "../contexts/MainContext";
import { Badge, Col, Container, Row } from "react-bootstrap";
import { COLORS } from "../constants";
import AuthAxios from "../utils/AuthAxios";

//import { render } from 'react-dom'
//import Highcharts from 'highcharts'
//import HighchartsReact from 'highcharts-react-official'

import CardAnalytics from "../Bausteine/Cards/CardAnalytics";
import CardKennzahl from "../Bausteine/Cards/CardKennzahl";
import CardKennzahlen from "../Bausteine/Cards/CardKennzahlen";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt as farFileAlt } from '@fortawesome/free-regular-svg-icons'
import { faUser, faCommentDots } from '@fortawesome/free-regular-svg-icons' // Import the solid file-alt icon
import { faList, faEnvelopeOpenText, faGift, faStarHalfAlt, faTicket, faQrcode } from "@fortawesome/free-solid-svg-icons";

function Home() {

  const { user } = useContext(MainContext);

  const [openTasks, setOpenTasks] = useState(0);
  const [facts, setFacts] = useState({});
  const [quota, setQuota] = useState({});
  const [quotaSum, setQuotaSum] = useState(0);

  const [kennzahlenPartner, setKennzahlenPartner] = useState([]);
  const [vorteilskennzahlen, setVorteilskennzahlen] = useState([]);


  const getStatistics = async () => {
    try {
      const response = await AuthAxios.get("/statistics");
      setFacts(response.data);
      
    } catch (error) {
      console.log(error);
    }
  }

  const getQuota = async () => {
    try {
      const response = await AuthAxios.get("/statistics/quota");

      setQuota(response.data);

      let quotaGesamt = 0;
      if(response.data.length > 0) {
        response.data.map(q => {
          quotaGesamt += (Number(q.totalSize)/1000000);
        });
      }
      setQuotaSum(quotaGesamt.toFixed(2));
      //console.log(quotaGesamt.toFixed(2));

      /*
    
      if(response.data.length > 0) {
       (q => 
       
      )}
      setSumQuota(quotaGesamt);
      */

    } catch (error) {
      console.log(error);
    }

  }

  useEffect(() => {
    getStatistics();

    getQuota();
  }, []);


  useEffect(() => {
    setKennzahlenPartner([
      {
        icon: faUser,
        kennzahl: facts.kunden,
        title: "Kunden",
      },
      {
        icon: faEnvelopeOpenText,
        kennzahl: facts.newsletter,
        title: "Newsletter",
      },
      /*{
        icon: faGift,
        kennzahl: facts.gewinnspiele,
        title: "Gewinnspiele",
      },  
      */
      {
        icon: faStarHalfAlt,
        kennzahl: facts.bewertungen,
        title: "Bewertungen",
      },   
      {
        icon: faCommentDots,
        kennzahl: facts.vorfaelle,
        title: "Mitteilungen",
      },

      {
        icon: faList,
        kennzahl: facts.offeneTasks,
        title: "Offene Aufgaben",
      },
    ]);


    setVorteilskennzahlen([
      {
        icon: faTicket,
        kennzahl: facts.codeAnzeige,
        title: "Vorteilscode Online aufgerufen",
      },
      {
        icon: faQrcode,
        kennzahl: facts.qrScans,
        title: "QR-Scans vor Ort",
      },
    ]);
    

  },[facts])






  return (
 
    <div
      style={{
        marginLeft: "0px",
        marginTop: "30px",
        marginBottom: "0px",
        width: "100%",
        height: "100%",
      }}
    >
       <div className="mainWork" style={{padding: "20px", background: "#fff"}}>



      <h1>Home {"- "+user.partner_name}</h1>
      <p className="lead">Hallo {user.vorname}.</p>
      <p>
        Hier findest du die aktuellen Ankündigungen und News für die Nutzung der CRM-Software.
      </p>

      {/*
      <Row style={{paddingRight: "20px"}}>
        <CardKennzahlen data={kennzahlenPartner} />
      </Row>
      */}



      <div>
        <dl className="mt-5 grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow md:grid-cols-4 md:divide-x md:divide-y-0">
          {kennzahlenPartner.map(data =>
            <div
              key={data.title}
              className="px-4 py-3 sm:p-3"
            >
            <div className="flex flex-row">
              <div className="basis-1/6" style={{padding: "4px 8px 0px 0px"}}>
                {data.icon &&
                  <FontAwesomeIcon icon={data.icon} size="2x" />
                }
              </div>
              <div className="basis-1/2">
                <dt className="text-base font-normal text-gray-900">{data.title}</dt>
                <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                  <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
                    {data.kennzahl}
                    {/*<span className="ml-2 text-sm font-medium text-gray-500">from 70,946</span>*/}
                  </div>
          
                  {/*<div className="inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium bg-green-100 text-green-800 md:mt-2 lg:mt-0">
                    <svg className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-green-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M10 17a.75.75 0 01-.75-.75V5.612L5.29 9.77a.75.75 0 01-1.08-1.04l5.25-5.5a.75.75 0 011.08 0l5.25 5.5a.75.75 0 11-1.08 1.04l-3.96-4.158V16.25A.75.75 0 0110 17z" clipRule="evenodd" />
                    </svg>
                    <span className="sr-only"> Increased by </span>
                    12%
                  </div>*/}
                </dd>
              </div>
            </div>
          </div>
        )}
        </dl>
      </div>


      {facts.codeAnzeige !== 0 && facts.qrScan !== 0 &&
        <div>
          <h5 className="mt-5 pl-2">Auswertung Vorteilspartner</h5>
          <dl className="grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow md:grid-cols-4 md:divide-x md:divide-y-0">
            {vorteilskennzahlen.map(data =>
              <div
                key={data.title}
                className="px-4 py-3 sm:p-3"
              >
              <div className="flex flex-row">
                <div className="basis-1/6" style={{padding: "4px 8px 0px 0px"}}>
                  {data.icon &&
                    <FontAwesomeIcon icon={data.icon} size="2x" />
                  }
                </div>
                <div className="basis-1/2">
                  <dt className="text-base font-normal text-gray-900">{data.title}</dt>
                  <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                    <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
                      {data.kennzahl}
                      {/*<span className="ml-2 text-sm font-medium text-gray-500">from 70,946</span>*/}
                    </div>
            
                    {/*<div className="inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium bg-green-100 text-green-800 md:mt-2 lg:mt-0">
                      <svg className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-green-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fillRule="evenodd" d="M10 17a.75.75 0 01-.75-.75V5.612L5.29 9.77a.75.75 0 01-1.08-1.04l5.25-5.5a.75.75 0 011.08 0l5.25 5.5a.75.75 0 11-1.08 1.04l-3.96-4.158V16.25A.75.75 0 0110 17z" clipRule="evenodd" />
                      </svg>
                      <span className="sr-only"> Increased by </span>
                      12%
                    </div>*/}
                  </dd>
                </div>
              </div>
            </div>
          )}
          </dl>
        </div>
      }



  
    <Row className="mt-3" style={{paddingRight: "20px"}}>
      {/*
      <Col
        xs={12} sm={6} md={3} lg={3}
        style={{background: "white"}}
      >
        <CardKennzahl icon={faList} kennzahl={facts.offeneTasks} title={"Offene Aufgaben"} />
      </Col>
      <Col
        xs={12} sm={6} md={3} lg={3}
        style={{background: "white"}}
      >
        <CardKennzahl icon={farFileAlt} kennzahl={facts.offeneDokumente} title={"Dokumente ohne Reaktion"} />
    
      </Col>
                */}
      <Col 
        xs={12} md={6} lg={4}
        style={{background: "white", minHeight: "100%"}}
      >
        <CardAnalytics title={"Datennutzung"} usage={quotaSum} limit={10000} faktor={1000} einheitProgress={"MB"} einheitLimit={"GB"} />
      </Col>
    </Row>
    




   {/*<Row className="mt-5 mb-4">
      {quota.length >= 1 ?
        <Col>
          <p className="fw-bold">Aktuelle Datennutzung:</p>
          {quota.map(q => 
            <p>Art: {q.mimeType.split('/')[1]} - {(Number(q.totalSize)/1000000).toFixed(1)}MB</p>
          )}
        </Col>
        :
        <Col>
          <i>Aktuell hast du noch keine Daten für deine Kunden hochgeladen.</i>
        </Col>
      }
    </Row>*/}
      

      </div>
    </div>
  );
}

export default Home;
