import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { useLocation } from "react-router-dom";

import SelectVerantwortung from "../../Bausteine/SelectVerantwortung";
import SelectMitarbeiter from "../../Bausteine/SelectMitarbeiter";
import SelectGruppe from "../../Bausteine/SelectGruppe";
import SelectKundeObj from "../../Bausteine/SelectKundeObj";

import { COLORS } from "../../constants";

import moment from "moment";

import AuthAxios from "../../utils/AuthAxios";
import Swal from "sweetalert2";

function NeueAufgabe(props) {

  const location = useLocation();

  const [deadlineWochentag, setDeadlineWochentag] = useState("");
  const [aufgabenarten, setAufgabenarten] = useState([]);

  const day_of_week = (date) => {
    var weekday = ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"];
    var n = new Date(date).getUTCDay();
    return weekday[n];
  };

  // Wenn Neue Aufgabe aus Kundenkonto aufgerufen wird, Kunden hinterlegen
  useEffect(() => {
    if (location.state && location.state.selectedKunde.value !== "") {
      props.setSelect("kunde", location.state.selectedKunde);
    }

    /*
    if (props.user && props.user.mitarbeiter) {
      props.setSelect("mitarbeiter", {
        value: props.user.mitarbeiter._id,
        label:
          props.user.vorname +
          " " +
          props.user.nachname,
      });
    }
    */
  }, []);


  //Aufgabenarten abrufen und hinterlegen
  useEffect(() => {
    
    AuthAxios.get("/aufgabenart")
      .then((res) => {
        setAufgabenarten(res.data);
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Fehler",
          html: "Aufgabenarten konnten nicht abgerufen werden.",
        });
      });
  }, []);

  //Wochentag bei Deadline anzeigen
  useEffect(() => {
    if (moment(props.data.deadline, "YYYY-MM-DD", true).isValid()) {
      setDeadlineWochentag(day_of_week(props.data.deadline));
    } else {
      setDeadlineWochentag("");
    }
  }, [props.data.deadline]);
  //Kein Datum in der Vergangenheit zulassen.



  const handleUpload = () => {
    //console.log("Upload...")
  };


        


  return (
    <Container fluid>
      <Row>
        <Col xs={12} md={4}>
          <fieldset
            className="border"
            style={{ background: "white", borderRadius: "8px" }}
          >
            <legend
              className="float-none w-auto p-2"
              style={{
                color: COLORS.primCol,
                background: COLORS.primBG,
                borderRadius: "8px",
                fontSize: "12px",
              }}
            >
              Aufgabe
            </legend>

            <Row>
              <Col>
                {props.data.ersteller && <p style={{fontSize: "85%", margin: "0<"}}>Erstellt von: <b>{props.data.ersteller}</b></p>}
                <Form.Label>Eintragsdatum</Form.Label>
                <Form.Control
                  className="readOnly"
                  type="text"
                  name="createdAt"
                  value={
                    props.data.createdAt
                      ? new Date(props.data.createdAt).toLocaleString("de-DE", {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                        })
                      : "-wird beim Speichern vergeben-"
                  }
                  onChange={() => {}}
                />
              </Col>
            </Row>

            <SelectKundeObj
              setSelect={props.setSelect}
              selectedValue={props.data.kunde}
            />

            {/*
            <SelectKunde
              setSelect={props.setSelect}
              selectedValue={props.data.kdnr}
            />
          */}

            <SelectVerantwortung
              setSelect={props.setSelect}
              selectedValue={props.data.verantwortung}
            />

            <SelectGruppe
              setSelect={props.setSelect}
              selectedValue={props.data.gruppe}
            />


            <SelectMitarbeiter
              setSelect={props.setSelect}
              selectedValue={props.data.mitarbeiter}
              taskId={props.data._id}
            />

            <Form.Label>Status</Form.Label>
            <Form.Select
              aria-label="Default select"
              name="status"
              value={props.data.status ? props.data.status : "nicht_begonnen"}
              onChange={(e) => props.setAufgabe(e)}
            >
              <option value="Nicht begonnen">Nicht begonnen</option>
              <option value="In Bearbeitung">In Bearbeitung</option>
              <option value="Erledigt">Erledigt</option>
              <option value="Zurückgestellt">Zurückgestellt</option>
              <option value="Abgebrochen">Abgebrochen</option>
            </Form.Select>

            <Form.Label>Priorität</Form.Label>
            <Form.Select
              aria-label="Default select"
              name="prio"
              value={props.data.prio ? props.data.prio : "normal"}
              onChange={(e) => props.setAufgabe(e)}
            >
              <option value="höchste">höchste</option>
              <option value="hoch">hoch</option>
              <option value="normal">normal</option>
              <option value="niedrig">niedrig</option>
              <option value="niedrigste">niedrigste</option>
            </Form.Select>

            <Form.Label>Art</Form.Label>
            <Form.Select
              aria-label="Default select"
              name="art"
              value={props.data.art ? props.data.art : ""}
              onChange={(e) => props.setAufgabe(e)}
            >
              <option>-</option>
              
              {aufgabenarten.map((art, index) => 
                <option key={index} value={art}>{art}</option>
              )}
          
              {!aufgabenarten.includes("Anfrage") && <option value="Anfrage">Anfrage</option>}
              {!aufgabenarten.includes("Angebot erstellen") && <option value="Angebot erstellen">Angebot erstellen</option>}
              {!aufgabenarten.includes("Beratung") && <option value="Beratung">Beratung</option>}
              {!aufgabenarten.includes("Rückruf") && <option value="Rückruf">Rückruf</option>}
              {!aufgabenarten.includes("Termin vereinbaren") && <option value="Termin vereinbaren">Termin vereinbaren</option>}
              {!aufgabenarten.includes("ToDo") && <option value="ToDo">ToDo</option>}
            </Form.Select>

            <Form.Label>
              Deadline
              {deadlineWochentag !== "" && deadlineWochentag !== undefined && (
                <span> - Wochentag: {deadlineWochentag}</span>
              )}
            </Form.Label>
            <Form.Control
              type="date"
              name="deadline"
              min={new Date().toISOString().split("T")[0]}
              value={props.data.deadline ? props.data.deadline : ""}
              onChange={(e) => props.setAufgabe(e)}
            />

            {/* 
            var curr = new Date();
curr.setDate(curr.getDate() + 3);
var date = curr.toISOString().substring(0,10);
<input id="dateRequired" type="date" name="dateRequired" defaultValue={date} /> 


=> new Date(new Date().setDate(new Date("2022-05-10").getDate() + 3)).toISOString().substring(0,10);
          
          */}

            <Form.Label>Deadline setzen</Form.Label>
            <br />
            <Button
              onClick={() =>
                props.setManually(
                  "deadline",
                  new Date(new Date().setDate(new Date().getDate() + 1))
                    .toISOString()
                    .substring(0, 10)
                )
              }
              className="ms-1 mb-1"
            >
              + 1 Tag
            </Button>
            <Button
              onClick={() =>
                props.setManually(
                  "deadline",
                  new Date(new Date().setDate(new Date().getDate() + 2))
                    .toISOString()
                    .substring(0, 10)
                )
              }
              className="ms-1 mb-1"
            >
              + 2 T.
            </Button>
            <Button
              onClick={() =>
                props.setManually(
                  "deadline",
                  new Date(new Date().setDate(new Date().getDate() + 3))
                    .toISOString()
                    .substring(0, 10)
                )
              }
              className="ms-1 mb-1"
            >
              + 3 T.
            </Button>
            <Button
              onClick={() =>
                props.setManually(
                  "deadline",
                  new Date(new Date().setDate(new Date().getDate() + 5))
                    .toISOString()
                    .substring(0, 10)
                )
              }
              className="ms-1 mb-1"
            >
              + 5 T.
            </Button>
            <Button
              onClick={() =>
                props.setManually(
                  "deadline",
                  new Date(new Date().setDate(new Date().getDate() + 7))
                    .toISOString()
                    .substring(0, 10)
                )
              }
              className="ms-1 mb-1"
            >
              + 7 T.
            </Button>
          </fieldset>
        </Col>

        <Col xs={12} md={8}>
          <fieldset
            className="border"
            style={{ background: "white", borderRadius: "8px" }}
          >
            <legend
              className="float-none w-auto p-2"
              style={{
                color: COLORS.primCol,
                background: COLORS.primBG,
                borderRadius: "8px",
                fontSize: "12px",
              }}
            >
              Inhalte
            </legend>

            <Form.Label>Betreff</Form.Label>
            <Form.Control
              type="text"
              placeholder="Betreff"
              name="betreff"
              value={props.data.betreff ? props.data.betreff : ""}
              onChange={(e) => props.setAufgabe(e)}
            />

            
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Beschreibung</Form.Label>
              <Form.Control
                as="textarea"
                rows={10}
                placeholder="Aufgabenbeschreibung"
                name="beschreibung"
                value={props.data.beschreibung ? props.data.beschreibung : ""}
                onChange={(e) => props.setAufgabe(e)}
              />
            </Form.Group>
                  
            {(props.data.dateien && props.data.dateien.length >= 0 ) &&
              <>
                <Form.Label>Datei/-en</Form.Label><br />
                  {/*<i style={{fontSize:"85%"}}>Keine Dateien vorhanden.</i>*/}

                  <ul style={{paddingLeft: "0px"}}>
                    {props.data.dateien && props.data.dateien.map((datei) => 
                      <li
                        key={datei.title}
                        className="mt-2"
                        style={{listStyle: "none", textDecoration: "underline", cursor: "pointer", width: "fit-content"}}
                        onClick={() => window.open(datei.url, '_blank').focus()}
                      >
                        {datei.title.substring(36)}
                      </li>
                    )}
                  </ul>
              

                {/*
                <Form.Label>Datei/-en (noch keine Funktion!)</Form.Label>

                //<Col xs={6} md={4} style={{marginTop: "10px", cursor: "pointer"}} onClick={handleUpload}>
                //    <div style={{borderStyle: "solid", borderRadius: "16px", width: "100px", aspectRatio: "1", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
                //        <FontAwesomeIcon icon={faCirclePlus} size="2x" style={{marginBottom: "10px"}} />
                //        <p style={{display: "inline-flex", margin: "0px"}}>Upload</p>
                //    </div>
                //</Col>
                
                
                <Row>
                  <Col>
                    <Button onClick={handleUpload} className="d-flex justify-content-center align-items-center"><FontAwesomeIcon icon={faCirclePlus} size="lg" style={{margin: "0px 8px 0px 0px"}} />Upload Datei</Button>
                    {"files picked?" == true &&
                      <p>Ausgeben der Liste</p>
                    }
                  </Col>
                </Row>

                <Row style={{alignItems: "flex-start"}}>
                  <Col xs={6} md={1} className="ms-2">
                    <div style={{width: "100px", height: "100px", background: "grey", borderRadius: "8px", margin:"8px"}}></div>
                  </Col>
                  <Col xs={6} md={1} className="ms-2">
                    <div style={{width: "100px", height: "100px", background: "grey", borderRadius: "8px", margin:"8px"}}></div>
                  </Col>
                  <Col xs={6} md={1}>
                    <div style={{width: "100px", height: "100px", background: "grey", borderRadius: "8px", margin:"8px"}}></div>
                  </Col>
                </Row>
                  */}
              </>    
            }
          </fieldset>
        </Col>
      </Row>
    </Container>
  );
}

export default NeueAufgabe;
