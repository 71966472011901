import React, { useState, useEffect, useContext } from 'react';
import { MainContext } from '../../contexts/MainContext';
import { Container, Col, Row, Alert, Tab, Tabs } from 'react-bootstrap';
import NeueAufgabe from './NeueAufgabe';
import AufgabenListe from './AufgabenListe';
import MeineAufgabenListe from './MeineAufgabenListe';
import GruppenAufgabenListe from './GruppenAufgabenListe';
import VerantwortungAufgabenListe from './VerantwortungAufgabenListe';
import AbgeschlosseneAufgabenListe from './AbgeschlosseneAufgabenListe';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCirclePlus, faPlus, faSave, faCheckCircle, faCheck, faPenToSquare, faQrcode, faRotate, faXmark } from "@fortawesome/free-solid-svg-icons";

import { useParams, useNavigate } from 'react-router-dom'

//import api from '../../utils/axiosConfigaxiosConfig';
import Swal from 'sweetalert2';
import AuthAxios from '../../utils/AuthAxios';


function Aufgaben() {

  let { id } = useParams();
  const { user } = useContext(MainContext);
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(1);
  const [alertSuccess, setAlertSuccess] = useState("")
  const [alertError, setAlertError] = useState("")
  const [active, setActive] = useState(true);
  const [aufgabe, setAufgabe] = useState({
    status: "Nicht begonnen",
    prio: "normal",
    deadline: new Date().toISOString().substring(0, 10),
    mitarbeiter: {}
  });

  const [meineAufgabenListe, setMeineAufgabenListe] = useState([]);
  const [gruppenAufgabenListe, setGruppenAufgabenListe] = useState([]);
  const [verantwortungAufgabenListe, setVerantwortungAufgabenListe] = useState([]);
  const [abgeschlossenAufgabenListe, setAbgeschlossenAufgabenListe] = useState([]);
  
  const [aufgabenListe, setAufgabenListe] = useState([]);


  const [reloadTasks, setReloadTasks] = useState(false);

  // Tracking ob es Änderungen gab
  const [changed, setChanged] = useState(false);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    setChanged(false);
  }, []);

  useEffect(() => {

    if (id !== undefined && id.length === 24) {
      getAufgabe(id);
      setChanged(false);
    }

 
    if(reload && id !== undefined && id.length === 24) {
      getAufgabe(id);
      setReload(false);
    } else if (reload && (id == undefined || id.length != 24)) {
      newAufgabe();
      setReload(false);
    }

  }, [id, reload]);

  const getAufgabe = async(id) => {
    AuthAxios.get('/task/' + id)
      .then(res => {
        //console.log(res.data);
        setAufgabe(res.data);
      })
      .catch(err => {
        console.log(err);
        Swal.fire({
          icon: 'error',
          title: "Fehler",
          html: "Aufgabe konnte nicht gefunden werden."
        });
      });
  }


  // + Icon neuer Partner
  const newAufgabe = () => {
    setAufgabe({
      status: "Nicht begonnen",
      priorität: "normal",
      deadline: new Date().toISOString().substring(0, 10),
      mitarbeiter: {"value": user._id, "label": user.vorname+" "+user.nachname}
    });
    navigate("/dash/aufgabe");
  };




  // CB einzeln auswerten, da nicht e.target.value, sondern e.target.checked!
  const setCBs = (e) => {

    // Wenn das erste mal verändert, Speicherdiskette einblenden!!
    setChanged(true);
    setAufgabe({
      ...aufgabe,
      [e.target.name]: e.target.checked
    });
  }


  // React Single Select Zwueisung
  const setSelect = (name, value) => {

    // Wenn das erste mal verändert, Speicherdiskette einblenden!!
    setChanged(true);
    setAufgabe({
      ...aufgabe,
      [name]: value
    });
  }

  // React Single Select Zwueisung
  const setSelectKunde = (name, kundeObj) => {

    setChanged(true);
    setAufgabe({
      ...aufgabe,
      [name]: kundeObj
    });
  }


  // React Multi Select Zuweisung
  const setSelectMulti = (name, value) => {

    setChanged(true);
    let newVals = [];

    value?.map(val => {
      newVals.push(val.value)
    })

    setAufgabe({
      ...aufgabe,
      [name]: newVals
    });

  }

  const handleInputChange = (event) => {

    // Wenn das erste mal verändert, Speicherdiskette einblenden!!
    setChanged(true);
    const value = event.target.value;
    setAufgabe({
      ...aufgabe,
      [event.target.name]: value
    });
  }


  const handleInputChangeManually = (name, value) => {

    // Wenn das erste mal verändert, Speicherdiskette einblenden!!
    setChanged(true);


    setAufgabe({
      ...aufgabe,
      [name]: value
    });
  }





  const cancelEdit = () => {
    Swal.fire({
      icon: "info",
      title: "Änderungen verwerden",
      html: "Willst du deine Änderungen wirklich verwerfen?",
      showDenyButton: true,
      confirmButtonText: 'Verwerfen',
      denyButtonText: `Abbrechen`
    })
    .then( async (result) => {
      if (result.isConfirmed) {
        setChanged(false);
        setReload(true);
      } 
    })
  }



  const saveAufgabe = () => {

    if (!aufgabe.betreff || !aufgabe.beschreibung) {
      Swal.fire({
        icon: 'info',
        title: "Nicht gespeichert!",
        html: "Gib einen Betreff oder eine Beschreibung ein und versuche es erneut."
      });
      return;
    }

    // Diese Werte dürfen nur vom Backend ausgelesen werden
    //delete aufgabe['appzugriffCb'];
    //delete aufgabe['maklervollmachtCb'];
    //delete aufgabe['tippgeberCb'];

    AuthAxios.post('/task', aufgabe)
      .then(res => {
        //console.log(res.data);
        setAufgabe({
          ...aufgabe,
          _id: res.data._id //Damit nicht erneut ein Task mit selbem Inhalt erstellt wird!
        });

        if (aufgabe._id === "" || aufgabe._id === undefined) {
          // Update DataGrid mit neuem Task   
          setAufgabenListe([
            {
              "_id": res.data._id,
              "partner_name": res.data.partner_name,
              "ersteller": res.data.ersteller,
              "kunde": res.data.kunde,
              "verantwortung": res.data.verantwortung,
              "gruppe": res.data.gruppe,
              "mitarbeiter": res.data.mitarbeiter,
              "status": res.data.status,
              "prio": res.data.prio,
              "art": res.data.art,
              "deadline": res.data.deadline,
              "betreff": res.data.betreff,
              "beschreibung": res.data.beschreibung,
              "createdAt": res.data.createdAt,
              "updatedAt": res.data.updatedAt
            },
            ...aufgabenListe
          ]);
        }
        else {
          // Update DataGrid mit neuem Task (nur Task Update) 
          // alte Id kurz entfernen
          // updated obj hinzufügen
          // rest der daten hinzufügen 
          setAufgabenListe([
            {
              "_id": res.data._id,
              "partner_name": res.data.partner_name,
              "ersteller": res.data.ersteller,
              "kunde": res.data.kunde,
              "verantwortung": res.data.verantwortung,
              "gruppe": res.data.gruppe,
              "mitarbeiter": res.data.mitarbeiter,
              "status": res.data.status,
              "prio": res.data.prio,
              "art": res.data.art,
              "deadline": res.data.deadline,
              "betreff": res.data.betreff,
              "beschreibung": res.data.beschreibung,
              "createdAt": res.data.createdAt,
              "updatedAt": res.data.updatedAt
            },
            ...aufgabenListe.filter(x => x._id !== aufgabe._id),
          ]);
        }
        setAlertSuccess("Aufgabe erfolgreich gespeichert.");
      })
      .catch(err => {
        console.log(err);
        setAlertError("Aufgabe konnte nicht gespeichert werden.");
      });

    setChanged(false);

  }


  const reloadAllTasks = () => {
    setReloadTasks(true);
  }









  return (

    <div style={{ marginLeft: "0px", marginTop: "40px", width: "100%" }}>

      <Row style={{ display: "flex", alignItems: "center", margin: "0px" }}>
        <Col xs={12} md={12} className="mainActions" style={{ justifyContent: "space-between" }}>

          <Col xs={12} md={6}>
            {activeTab == 1 &&
              <FontAwesomeIcon
                icon={faPlus}
                size="2x"
                className="actionIcon"
                onClick={newAufgabe}
                title="Neue Aufgabe anlegen"
              />
            }

            {changed && activeTab == 1 &&
              <>
                <span style={{paddingLeft: "8px"}}>
                  <FontAwesomeIcon
                    icon={faSave}
                    size="2x"
                    className="actionIcon"
                    onClick={saveAufgabe}
                    title="Aufgabe speichern"
                  />
                </span>
                <span style={{paddingLeft: "8px"}}>
                  <FontAwesomeIcon
                    icon={faXmark}
                    size="2x"
                    className="actionIcon"
                    onClick={cancelEdit}
                    title="Änderungen verwerfen"
                  />
                </span>
              </>
            }

            {!changed && activeTab == 1 &&
              <FontAwesomeIcon
                icon={faCheckCircle}
                size="2x"
                className="actionIcon"
                title="Keine Änderung erfolgt"
              />
            }

            {activeTab != 1 &&
              <FontAwesomeIcon
                icon={faRotate}
                size="2x"
                className="actionIcon"
                onClick={reloadAllTasks}
                title="Tasks neu abrufen"
              />
            }
          </Col>


          <Col xs={12} md={6}>
            {alertSuccess.length > 0 &&
              <Alert key={1} variant={'success'} onClick={() => setAlertSuccess("")}>
                {alertSuccess}
              </Alert>
            }
          </Col>

          <Col xs={12} md={6}>
            {alertError.length > 0 &&
              <Alert key={2} variant={'danger'} onClick={() => setAlertError("")}>
                {alertError}
              </Alert>
            }
          </Col>

        </Col>
      </Row>

      <div className="mainWork">
        <Tabs
          activeKey={activeTab}
          onSelect={(key) => setActiveTab(parseInt(key))}
          //defaultActiveKey="neueaufgabe"
          id="fill-tab-example"
          fill
          style={{ display: "inline-flex", width: "auto", marginTop: "0px", padding: "0px" }}
        >

          {/*
            Gruppenaufgaben auswerten Reiter - nur wenn Benutzer auch in Gruppe ist anzeigen!
            Alle Aufgaben nur anzeigen wenn Rechte vorhanden sind!
          */}

          <Tab style={{ background: "white", position: "relative", paddingTop: "15px" }} eventKey="1" title="Neue Aufgabe">
            <NeueAufgabe user={user} data={aufgabe} setAufgabe={handleInputChange} setCBs={setCBs} setSelect={setSelect} setSelectMulti={setSelectMulti} setManually={handleInputChangeManually} />
          </Tab>
          
          <Tab style={{ background: "white", position: "relative", paddingTop: "15px" }} eventKey="2" title={"Meine Aufgaben (" + meineAufgabenListe.length + ")"}>
            <MeineAufgabenListe user={user} data={meineAufgabenListe} setAufgabenListe={setMeineAufgabenListe} reloadTasks={reloadTasks} setReloadTasks={setReloadTasks} setActiveTab={setActiveTab} activeTab={activeTab} />
          </Tab>
          
          <Tab style={{ background: "white", position: "relative", paddingTop: "15px" }} eventKey="3" title={"Gruppen (" + gruppenAufgabenListe.length + ")"}>
            <GruppenAufgabenListe data={gruppenAufgabenListe} setAufgabenListe={setGruppenAufgabenListe} reloadTasks={reloadTasks} setReloadTasks={setReloadTasks} setActiveTab={setActiveTab} activeTab={activeTab} />
          </Tab>

          <Tab style={{ background: "white", position: "relative", paddingTop: "15px" }} eventKey="4" title={"Verantwortung (" + verantwortungAufgabenListe.length + ")"}>
            <VerantwortungAufgabenListe data={verantwortungAufgabenListe} setAufgabenListe={setVerantwortungAufgabenListe} reloadTasks={reloadTasks} setReloadTasks={setReloadTasks} setActiveTab={setActiveTab} activeTab={activeTab} />
          </Tab>

          <Tab style={{ background: "white", position: "relative", paddingTop: "15px" }} eventKey="5" title={"Abgeschlossen (" + abgeschlossenAufgabenListe.length + ")"}>
            <AbgeschlosseneAufgabenListe data={abgeschlossenAufgabenListe} setAufgabenListe={setAbgeschlossenAufgabenListe} reloadTasks={reloadTasks} setReloadTasks={setReloadTasks} setActiveTab={setActiveTab} activeTab={activeTab} />
          </Tab>

          {(user.roleId == 1 || user.roles?.includes("Administrator")) && 
            <Tab style={{ background: "white", position: "relative", paddingTop: "15px" }} eventKey="6" title={"Alle (" + aufgabenListe.length + ")"}>
              <AufgabenListe data={aufgabenListe} setAufgabenListe={setAufgabenListe} reloadTasks={reloadTasks} setReloadTasks={setReloadTasks} setActiveTab={setActiveTab} activeTab={activeTab} />
            </Tab>
          }
        
        </Tabs>

      </div>
    </div>

  );
}

export default Aufgaben;