import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

import { Container, Row, Col, Button, NavItem } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faCheck } from "@fortawesome/free-solid-svg-icons";
import {  } from '@fortawesome/free-regular-svg-icons'

import { deDE, GridToolbar, GridApi, gridClasses, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton, GridToolbarQuickFilter, GridToolbarColumnsButton, GridFooterContainer, GridFooter } from '@mui/x-data-grid';
import StripedDataGrid from '../../Bausteine/StripedDataGrid';

import Box from '@mui/material/Box';

import AuthAxios from "../../utils/AuthAxios";
import Swal from 'sweetalert2'

function AboAnfragenOffenListe(props) {

  const navigate = useNavigate();

  const [abos, setAbos] = useState([]);

  const [loading, setLoading] = useState(true);


  async function getOpenAbos() {
    AuthAxios.get('/abo/open')
      .then(res => {
        setAbos(res.data);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        Swal.fire({
          icon: 'error',
          title: "Fehler",
          html: "Offene Abo Bestätigungen konnten nicht geladen werden."
        });
      });
  }


  useEffect(() => {
    if(props.activeTab == props.tab) {
      getOpenAbos();
    }
  }, [props.activeTab])


  const handleAbo = (googleuid, status) => {

    if(status === "approved") {

      AuthAxios.post('/abo/accept', {
        googleuid: googleuid
      })
      .then(res => {
        const updatedAbos = abos.filter(abo => abo.googleuid !== googleuid);
        setAbos(updatedAbos); // Update your state with the filtered array
      })
      .catch(err => {
        console.log(err);
        Swal.fire({
          icon: 'error',
          title: "Fehler",
          html: "Versuche es später erneut."
        });
      });

    }
    else if(status == "declined") {

      AuthAxios.post('/abo/decline', {
        googleuid: googleuid
      })
      .then(res => {
        const updatedAbos = abos.filter(abo => abo.googleuid !== googleuid);
        setAbos(updatedAbos); // Update your state with the filtered array
      })
      .catch(err => {
        console.log(err);
        Swal.fire({
          icon: 'error',
          title: "Fehler",
          html: "Versuche es später erneut."
        });
      });
      
    }

  }


  



  const columns = [
    {
      field: "kdnr",
      headerName: "KdNr",
      minWidth: 80,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "anrede",
      headerName: "Anrede",
      minWidth: 65,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "firma",
      headerName: "Firma",
      minWidth: 100,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "vorname",
      headerName: "Vorname",
      minWidth: 100,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "nachname",
      headerName: "Nachname",
      minWidth: 110,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "anschrift",
      headerName: "Anschrift",
      minWidth: 160,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "plz",
      headerName: "PLZ",
      minWidth: 70,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "stadt",
      headerName: "Stadt",
      minWidth: 130,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    /*
    {
      field: "land",
      headerName: "Land",
      minWidth: 100,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    */
    {
      field: "email",
      headerName: "Email",
      minWidth: 250,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "action",
      minWidth: 275,
      headerName: "Quick-Links",
      sortable: false,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
  
        return (
          <>
            <Button
              variant="success"
              style={{marginRight: "8px"}}
              onClick={() => handleAbo(params.row.googleuid, "approved")}
            >
              <FontAwesomeIcon
                icon={faCheck}
                size="1x"
              /> Annehmen
            </Button>
            <Button
              variant="danger"
              onClick={() => handleAbo(params.row.googleuid, "declined")}
            >
              <FontAwesomeIcon
                icon={faXmark}
                size="1x"
              /> Ablehnen
            </Button>
          </>
        )
      }
    },

    /*
    {
      field: "webseite",
      headerName: "Webseite",
      minWidth: 155,
      flex: 1,
      valueGetter: (params) => (
        <Link href={`${params.row.webseite}`}>{params.row.webseite}</Link>
      ),
      headerClassName: "super-app-theme--header",
    }*/
  ];


  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <Row style={{ display: "flex", justifyContent: "flex-start", width: "100%" }}>
          <Col xs={12} lg={2}>
            <GridToolbarQuickFilter />
          </Col>
          <Col xs={6} lg={1}>
            <GridToolbarFilterButton />
          </Col>
          <Col xs={6} lg={1}>
            <GridToolbarColumnsButton />
          </Col>

        </Row>

      </GridToolbarContainer>
    );
  }


  const CustomFooter = () => {
    return (
      <GridFooterContainer>
      {/* Add what you want here */}
      <GridFooter sx={{
          border: 'none', // To delete double border.
          alignItems: 'flex-start'
        }} />
    </GridFooterContainer>
    );
  }




  return (
    <>
      <div style={{width: "100%", height: `calc(100vh - 200px)` }}>
        {props.activeTab == props.tab &&
          <Box
            sx={{
              width: '100%',
              height: '100%',
              '& .super-app-theme--header': {
                backgroundColor: '#c58164',
              },
              '& .MuiDataGrid-toolbarContainer': {
                padding: '10px'
              },
              margin: "0px",
              fontWeight: 'bold',
              boxShadow: 0,
            }}
          >
            {/* Server side pagination wenn zu viele Daten - https://mui.com/x/react-data-grid/pagination/ */}
            <StripedDataGrid
              //checkboxSelection
              //onSelectionModelChange={handleCheckboxSelection}
              disableRowSelectionOnClick={true}
              //autoHeight
              density={'compact'}
              rows={abos}
              columns={columns}
              getRowId={(row) => row._id}
              //onRowClick={handleNavigation}
              //pageSize={20}
              //rowsPerPageOptions={[20]}
              autoPageSize
              //onFilterModelChange={onFilterModelChange}
              localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
              loading={loading}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
              }
              components={{ Toolbar: CustomToolbar }}
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 100 },
                },
              }}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'kdnr', sort: 'desc' }],
                },
              }}
              sx={{/*
                boxShadow: 2,
                border: 2,
                borderColor: 'primary.dark',
                '& .MuiDataGrid-cell:hover': {
                  color: 'primary.main',
                },
                bgcolor: 'background.paper'
              */}}
            />

          </Box>
        }

      </div>

    </>
  );
}


export default AboAnfragenOffenListe