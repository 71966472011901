import { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Tab, Tabs, Alert, Container, Row, Col, Button } from 'react-bootstrap';

import Stammdaten from './Stammdaten';
import Dateien from './Dateien';
import DateiListe from './DateiListe';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faXmark, faCheckCircle, faPenToSquare } from "@fortawesome/free-solid-svg-icons";

import Swal from 'sweetalert2';
import AuthAxios from '../../utils/AuthAxios';


function Kunde() {

  let { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [activeTab, setActiveTab] = useState(1);

  // Falls location prop Datei Kategorie gesetzt ist in Datei Komponent übergeben
  const [dateiKategorie, setDateiKategorie] = useState("");
  
  let alterMitarbeiter = null;

  // Globaler State für Kunde mit allen Daten
  const [kunde, setKunde] = useState({
    kdnr: "-nicht gefunden-",
    anrede: "Herr",
    kontaktart: "privatkunde",
    land: "Deutschland"
  });

  // Letzte Aktivitäten des Kunden
  const [letzteAktivität, setLetzteAktivität] = useState([]);

  // Tracking ob es Änderungen am Kunde gab
  const [changed, setChanged] = useState(false)
  const [reload, setReload] = useState(false)

  const [alertSuccess, setAlertSuccess] = useState("")
  const [alertError, setAlertError] = useState("")


  //if location state is set (für Navigation direkt zu Dokument)
  useEffect(() => {
    
    if(location.state?.props) {
      //activeTab, kategorie, dokument
      setActiveTab(location.state.props.activeTab);
      setDateiKategorie(location.state.props.kategorie);
    }

    if(location.state?.target == "files") {
      setActiveTab(2);
    }

  },[location])


  useEffect(() => {
  
    async function getKundeByMongoID(id) {

      AuthAxios.get('/kunde/' + id)
        .then(res => {
          setKunde(res.data);
          alterMitarbeiter=res.data.mitarbeiter;
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: 'error',
            title: "Fehler",
            html: "Kunde konnte nicht gefunden werden."
          });
        });
    }

    async function getKundeByKdNr(id) {
      AuthAxios.get('/kunde/'+ id)
        .then(res => {
          setKunde(res.data);
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: 'error',
            title: "Fehler",
            html: "Kunde konnte nicht gefunden werden."
          });
        });
    }

    if (id !== undefined && id.length === 24) {
      getKundeByMongoID(id);
    }

    if (id !== undefined && id.length <= 24 && Number.isInteger(parseInt(id))) {
      getKundeByKdNr(id);
    }

    if(reload){
      getKundeByMongoID(id);
      setReload(false);
    }
    if(reload && id !== undefined && id.length === 24) {
      getKundeByMongoID(id);
      setReload(false);
    } else if (reload && id !== undefined && id.length <= 24 && Number.isInteger(parseInt(id))) {
      getKundeByKdNr(id);
      setReload(false);
    }
    
  }, [id, reload]);


  useEffect(() => {

    if(kunde?.kdnr !== undefined && Number.isInteger(parseInt(kunde?.kdnr))) {
      AuthAxios.get('/kunde/latest-tasks/'+kunde.kdnr)
      .then(res => {
        setLetzteAktivität(res.data);
      })
      .catch(err => {
        console.log(err);
        Swal.fire({
          icon: 'error',
          title: "Fehler",
          html: "Letzte Aktivitäten konnte nicht gefunden werden."
        });
      });
    }

  },[kunde])



  const setSelect = (name, value) => {

    // Wenn das erste mal verändert, Speicherdiskette einblenden!!
    setChanged(true);

    setKunde({
      ...kunde,
      [name]: value
    });
  }

  const handleInputChangeManually = (name, value) => {

    // Wenn das erste mal verändert, Speicherdiskette einblenden!!
    setChanged(true);

    setKunde({
      ...kunde,
      [name]: value
    });
  }



  const cancelEdit = () => {
    Swal.fire({
      icon: "info",
      title: "Änderungen verwerfen",
      html: "Willst du deine Änderungen wirklich verwerfen?",
      showDenyButton: true,
      confirmButtonText: 'Verwerfen',
      denyButtonText: `Abbrechen`
    })
    .then( async (result) => {
      if (result.isConfirmed) {
        setChanged(false);
        setReload(true);
      } 
    })
  }


  const updateCustomer = () => {

    if(!changed) {
      Swal.fire({
        icon: "info",
        title: "Update",
        html: "Seit dem letzten speichern wurden die Daten nicht verändert."
      });
      return;
    }

    AuthAxios.post('/kunde/update', {
      googleuid: kunde.googleuid, //ident
      notiz: kunde.notiz,
      mitarbeiter: kunde.mitarbeiter
    })
    .then(res => {
      setChanged(false);
      setReload(true);
    })
    .catch(err => {
      console.log(err);
      Swal.fire({
        icon: 'error',
        title: "Fehler",
        html: "Update Fehler. Versuche es später erneut."
      });
    });
  }

  
  return (

    <div
      style={{
        marginLeft: "0px",
        marginTop: "30px",
        marginBottom: "0px",
        width: "100%",
        height: "100%",
      }}
    >

      {kunde?._id ? (
        <>
          <Row className="mainActions">
            <Col xs={12} sm={2}>
              
              <div>
                {changed && activeTab == 1 &&
                  <>
                    <span style={{paddingLeft: "8px"}}>
                      <FontAwesomeIcon
                        icon={faSave}
                        size="2x"
                        className="actionIcon"
                        onClick={updateCustomer}
                        title="Kunde speichern"
                      />
                    </span>
                    <span style={{paddingLeft: "8px"}}>
                      <FontAwesomeIcon
                        icon={faXmark}
                        size="2x"
                        className="actionIcon"
                        onClick={cancelEdit}
                        title="Änderungen verwerfen"
                      />
                    </span>
                  </>
                }
                {!changed && activeTab == 1 &&
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    size="2x"
                    className="actionIcon"
                    title="Keine Änderung erfolgt"
                  />
                }
              </div>
            
            </Col>
            
            <Col xs={12} sm={5} className="meldungen">
                {alertSuccess.length > 0 &&
                  <Col xs={12} md={6}>
                    <Alert key={1} variant={'success'} onClick={() => setAlertSuccess("")}>
                      {alertSuccess}
                    </Alert>
                  </Col>
                }
                {alertError.length > 0 &&
                  <Col xs={12} sm={6} md={6}>
                    <Alert key={2} variant={'danger'} onClick={() => setAlertError("")}>
                      {alertError}
                    </Alert>
                  </Col>
                }
            </Col>
            
              {/* Hier muss noch bei Neukunden die "ObjectID" geprüft werden */}
              {id &&
                <Col className="quick-actions" xs={12} sm={5} md={3}> 
                    <Row>
                      {/* Vorerst zumindestens kein Archivieren 
                      <Col style={{width: "100%"}}>
                        
                          <Button
                            style={{width: "100%"}}
                            onClick={handleArchiv}
                            className="mt-2"
                          >
                            Archivieren
                          </Button>
                      </Col>
                    */}
                      <Col> 
                        <Button
                          onClick={() =>
                            navigate("/dash/aufgabe", {
                              state: {
                                selectedKunde: {"value": kunde.kdnr, "label": kunde.kdnr+" | "+kunde.vorname+" "+kunde.nachname}
                              },
                            })
                          }
                        >
                            <FontAwesomeIcon
                              icon={faPenToSquare}
                              size="lg"
                              className="actionIcon"
                              title="Aufgabe anlegen"
                            />
                          Aufgabe
                        </Button>
                      </Col>
                  </Row>
                </Col>
              }

          </Row>

          <div style={{ marginLeft: "0px", marginTop: "0px", marginBottom: "0px", width: "100%" }}>
            <div className="mainWork">
              <Tabs
                activeKey={activeTab}
                onSelect={(key) => setActiveTab(parseInt(key))}
                defaultActiveKey="stammdaten"
                id="fill-tab-example"
                fill
                style={{ display: "inline-flex", width: "auto", marginTop: "0px", padding: "0px" }}
              >
                <Tab style={{ background: "white", position: "relative", paddingTop: "15px" }} eventKey="1" title="Stammdaten">
                  <Stammdaten data={kunde} alterMitarbeiter={alterMitarbeiter} letzteAktivität={letzteAktivität} setSelect={setSelect} setKundeManually={handleInputChangeManually} setChanged={setChanged} />
                </Tab>

                {kunde._id &&    
                  <Tab style={{ background: "white", position: "relative", paddingTop: "15px" }} eventKey="2" title="Dateien">
                    <Dateien data={kunde} activeTab={activeTab} dateiKategorie={dateiKategorie} />
                  </Tab>
                }

                {kunde._id &&
                  <Tab style={{ background: "white", position: "relative", paddingTop: "15px" }} eventKey="3" title="Datei Liste">
                    <DateiListe data={kunde} activeTab={activeTab} />
                  </Tab>
                }

              </Tabs>
            </div>
          </div>

        </>

      ) : (

        <Container fluid style={{ marginLeft: "0px", marginTop: "80px"}}>
          <Row className="d-flex justify-content-center mt-3">
              <p className="text-center">Bitte wähle einen Kunden aus deiner Kundenliste aus, um hier die Kundendaten zu sehen und Dateien hochzuladen.</p>
              <Button className="w-auto" onClick={() => navigate("/dash/kunden")}>Zur Kundenliste</Button>
          </Row>
        </Container>
      )}

    </div>
  )
  
}

export default Kunde;