import React, { useEffect, useState } from "react";

import { Row, Col } from "react-bootstrap";

import { deDE, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton, GridToolbarQuickFilter, GridToolbarColumnsButton } from '@mui/x-data-grid';
import StripedDataGrid from "../../Bausteine/StripedDataGrid";
import Box from "@mui/material/Box";

//import GroupModal  from "../../Bausteine/GroupModal";

//import AuthAxios from "../../utils/AuthAxios";
import Swal from "sweetalert2";


function GruppenListe(props) {

  const [showEdit, setShowEdit] = useState(false);  

  const columns = [
    {
      field: "groupId",
      headerName: "ID",
      width: 60,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "gruppenname",
      headerName: "Gruppen-Name",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "anzahlMitarbeiter",
      headerName: "Anzahl Mitarbeiter",
      width: 150,
      valueGetter: (params) =>
      params.row.anzahlMitarbeiterGruppe !== undefined
        ? params.row.anzahlMitarbeiterGruppe
        : "0",
      headerClassName: "super-app-theme--header",
    },
    {
      field: "beschreibung",
      headerName: "Beschreibung",
      flex: 1,
      minwidth: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "createdAt",
      headerName:"Erstellt",
      width: 200,
      renderCell: (params) => (
        <span>{new Date(params.row.createdAt).toLocaleString("de-De")}</span>
      ),
      headerClassName: "super-app-theme--header",
    }
  ];
  

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <Row style={{ display: "flex", justifyContent: "flex-start", width: "100%" }}>
          <Col xs={12} lg={2}>
            <GridToolbarQuickFilter />
          </Col>
          <Col xs={6} lg={1}>
            <GridToolbarFilterButton />
          </Col>
          <Col xs={6} lg={1}>
            <GridToolbarColumnsButton />
          </Col>
         
        </Row>
      </GridToolbarContainer>
    );
  }



    return (
      <div style={{height: `90%`, width:"100%", marginBottom: "30px"}}>
        {props.activeTab === 3 && 
           <Box
            sx={{
              height: `100%`,
              width: "100%",
              "& .super-app-theme--header": {
                backgroundColor: "#c58164",
              },
              "& .MuiDataGrid-toolbarContainer": {
                padding: "10px",
              },
              margin: "0px",
              padding: "5px 10px",
              fontWeight: "bold",
              boxShadow: 3,
            }}
          >

          <StripedDataGrid
            autoHeight
            density={'compact'}
            rows={props.data}
            columns={columns}
            getRowId={(row) => row._id}
            onRowClick={(e) => {
              // Hier zum editieren das Modal öffnen und Daten übergeben
              /*props.setNewGroup({
                id: e.id,
                groupId: e.row.groupId,
                gruppenname: e.row.gruppenname,
                beschreibung: e.row.beschreibung
              });
              setShowEdit(true);*/
            }}
            pageSize={20} 
            rowsPerPageOptions={[20]}
            localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
            loading={props.loading}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
            }
            components={{ Toolbar: CustomToolbar }}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 100 },
              },
            }}
            initialState={{
              sorting: {
                sortModel: [{ field: 'groupId', sort: 'asc' }],
              },
            }}
            sx={{/*
              marginTop: "20px",
              boxShadow: 2,
              border: "2px solid black",
              padding: "5px 10px",
              borderColor: 'primary.dark',
              '& .MuiDataGrid-cell:hover': {
                color: 'primary.main',
              },
              bgcolor: 'background.paper'
            */}}
          />

          </Box>
        }


      {/*
        Für sptäer, wenn wir Gruppen editierbar machen..
        showEdit  &&
        <GroupModal title={"Gruppe bearbeiten"} setChanged={props.setChanged} visible={showEdit} setVisible={setShowEdit} newGroup={props.newGroup} setNewGroup={props.setNewGroup} createNewGroup={props.createNewGroup} />
      */}

      </div>
    );
  }
  

export default GruppenListe