import React, { Fragment, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import style from "./CardNotiz.module.css";
import classLister from "css-module-class-lister";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlag } from "@fortawesome/free-solid-svg-icons";
import {
  faFlag as faFlagLight,
  faPenToSquare,
  faTrashCan,
} from "@fortawesome/free-regular-svg-icons";

import { Col, Row } from "react-bootstrap";
import { MainContext } from "../../contexts/MainContext";

import LinesEllipsis from "react-lines-ellipsis";
import responsiveHOC from "react-lines-ellipsis/lib/responsiveHOC";

const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

function CardNotiz(props) {
  const { user } = useContext(MainContext);

  let navigate = useNavigate();
  const classes = classLister(style);

  const [showMore, setShowMore] = useState(false);

  const handleShowMore = () => {};

  
  return (
    <div className={style.cardContainer}>
      {/*<img className={style.cardImg} src={require("../../img/"+props.img)} alt="" />*/}
      <Row style={{ width: "100%" }}>
        <Col xs={12} md={9}>
          <span className={style.cardDate}>
            {new Intl.DateTimeFormat('de-DE', {
              month: '2-digit',
              day: '2-digit',
              year: 'numeric'
            }).format(new Date(props.data.createdAt))}&nbsp; 
            {props.data.mitarbeiter}
          </span>
          {/*<p className={style.cardText} style={{overflow: "hidden", whiteSpace: "no-wrap", textOverflow: "ellipsis"}}>{props.data.notiz}</p>*/}
          {!showMore && (
            <ResponsiveEllipsis
              text={props.data.notiz}
              maxLine="2"
              ellipsis="..."
              trimRight
              basedOn="letters"
              /*onReflow={(clamped, text) => {
                            if(clamped) {
                                props.setShowMore(props.data.id, true);
                            } else {
                                props.setShowMore(props.data.id, false);
                            }
                        }}*/
            />
          )}
          {showMore && <p className={style.cardText}>{props.data.notiz}</p>}
        </Col>
        <Col xs={12} md={3} className="mt-3 mt-sm-0">
          <Row>
            <Col className="d-flex justify-content-center justify-content-sm-end">
              {/* nur eigene Notizen bearbeiten + löschen oder als Admin.. */}
              {(props.data.mitarbeiter === user.vorname + " " + user.nachname ||
                user.roleId == 1) && (
                <Fragment>
                  <FontAwesomeIcon
                    style={{ margin: "0 10px", cursor: "pointer" }}
                    icon={faPenToSquare}
                    size="1x"
                    title="Notiz bearbeiten"
                    onClick={() => props.handleNotizenEdit(props.data.id)}
                  />
                  <FontAwesomeIcon
                    style={{ margin: "0 10px", cursor: "pointer" }}
                    icon={faTrashCan}
                    size="1x"
                    title="Notiz löschen"
                    onClick={() => props.handleNotizenDelete(props.data.id)}
                  />
                  <FontAwesomeIcon
                    style={{
                      margin: "0 10px",
                      rotate: "45deg",
                      cursor: "pointer",
                    }}
                    icon={props.data.pinned ? faFlag : faFlagLight}
                    size="1x"
                    title="Notiz pinnen"
                    onClick={() => props.handleNotizenPinned(props.data.id)}
                  />
                </Fragment>
              )}
            </Col>
          </Row>
          <Row>
            {!showMore && (
              <Col
                xs={12}
                className="d-flex justify-content-center justify-content-sm-end mt-2"
              >
                <a onClick={() => setShowMore(true)}>mehr anzeigen</a>
              </Col>
            )}
            {showMore && (
              <Col
                xs={12}
                className="d-flex justify-content-center justify-content-sm-end mt-2"
              >
                <a onClick={() => setShowMore(false)}>einklappen</a>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default CardNotiz;
