import React, { useContext, useEffect } from "react";
import { MainContext } from "../contexts/MainContext";
import { useNavigate } from "react-router-dom";

//Rechteverwaltung
//import ability from '../defineAbility'

/*
ability.can('read', 'Post') // true
ability.can('read', 'User') // true
ability.can('update', 'User') // true
ability.can('delete', 'User') // false
ability.cannot('delete', 'User') // true
*/

const AuthComponent = (props) => {
  const { jwt } = useContext(MainContext);

  const navigate = useNavigate();

  // wird leider nicht ausgeführt...
  /*
     //Refresh Page Stay Logged In
     useEffect(() => {

      try {
        console.error("Fetching Data for stay logged in AuthComponent...");
        
        setJwt(sessionStorage.getItem('token'));
        setUser(JSON.parse(sessionStorage.getItem('user')));
      } catch (error) {
        setJwt('');
        setUser('')
      }
    }, []);
    */

  useEffect(() => {
    if (!jwt || jwt === "") {
      // Rechte für User setzten CASL
      // Rechte clearen
      /*
      ability.update([]);
      ability.update([ // switch to readonly mode
        { action: 'read', subject: 'all' }
      ]);
      */

      return navigate("/");
    }
  }, [jwt, navigate]);

  return <>{props.children}</>;
};

export default AuthComponent;
