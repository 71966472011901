import React, { useState, useContext } from 'react'
import { Row, Col, Form, Button } from "react-bootstrap"
import { COLORS } from '../constants'
import { MainContext } from '../contexts/MainContext';



function InputNotiz(props) {

  const { user } = useContext(MainContext);

  const [notiz, setNotiz] = useState({
    id: crypto.randomUUID(),
    notiz: "",
    mitarbeiter: user.vorname+" "+user.nachname,
    createdAt: new Date().toString(),
    pinned: false
  });


  const saveNotiz = () => {
    setNotiz({
      id: crypto.randomUUID(),
      notiz: "",
      mitarbeiter: user.vorname+" "+user.nachname,
      createdAt: new Date().toString(),
      pinned: false
    })
    props.setKundeManually("notiz", [...props.data.notiz, notiz]);
  }



  return (
    <fieldset
      className="border"
      style={{ background: "white", borderRadius: "8px" }}
    >
      <legend
        className="float-none w-auto p-2"
        style={{
          color: COLORS.primCol,
          background: COLORS.primBG,
          borderRadius: "8px",
          fontSize: "12px",
        }}
      >
        Notizen
      </legend>

      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label>Notizfeld</Form.Label>
        <Form.Control as="textarea" rows={4}
          placeholder="Platz für deine Notizen"
          name="notiz"
          value={notiz.notiz}
          onChange={(e) => setNotiz({
              ...notiz,
              notiz: e.target.value
            })
          }
        />
      </Form.Group>

      <Form.Check
        label="wichtige Notiz"
        className="mb-3 lead"
        name="pinned"
        onChange={(e) => setNotiz({
            ...notiz,
            pinned: e.target.checked
          })
        }
      />

      <Row>
        <p style={{fontSize: "80%"}}>Die Notiz wird erst gespeichert, wenn du oben in der Aktionsleiste den Speichern-Button drückst.</p>
        <Button onClick={saveNotiz}>Notiz anlegen</Button>
      </Row>

    </fieldset>
   
  );
}
export default InputNotiz