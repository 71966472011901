import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap';

import Swal from 'sweetalert2';

import style from "./NewsletterCard.module.css";

function Neuigkeiten(props) {

  const handleExternalURL = (url) => {
    // iFrames machen haftbar in eigener Seite, daher als neuer Tab.
    window.open(url, '_blank', 'noopener,noreferrer');
  } 


  return (
    <Container style={{ maxWidth: "800px", background: "white", borderRadius: "12px", marginTop: "20px", padding: "0px 30px 30px 30px" }}>
      <Row>
        <Col xs={12} className="text-center d-flex flex-col justify-center align-middle">
          <div style={{background: "white", paddingTop: "20px", display: "flex", justifyContent: "center"}} >
            <div style={{
              backgroundColor: "#ddd",
              maxWidth: "160px",
              width: "160px",
              aspectRatio: "1",
              justifyContent: "center",
              alignItems: "center",
              display: "flex"
            }} >
              {props.image ?
                <img src={props.image} alt="newsletter" />
                :
                <span style={{color: "grey", fonzSize: "24px", padding: "5px"}}>IMG</span>
              }
            </div>
          </div>
          
 
          {props.datum &&
            <p className="mt-3 mb-0">{new Intl.DateTimeFormat('de-DE', {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit'
              }).format(new Date(props.datum))}
            </p>
          }
          <h1>{props.title}</h1>
        </Col>

        <Col xs={12} className="text-center mt-3">
          {props.pdf && (
            <Button
              className="fw-bold"
              style={{
                background: "rgb(83, 178, 83)",
                border: "none",
                marginRight: "10px",
              }}
              onClick={() => Swal.fire({
                icon:"info",
                title: "PDF-Download",
                text:"Hier wird in der App der Download gestartet. Da das PDF noch nicht hochgeladen ist, kann hier keine Vorschau erfolgen."
              })}
            >
              PDF Download
            </Button>
          )}
          {props.url && (
            <Button
              className="fw-bold"
              style={{ background: "#520da7" }}
              onClick={() => handleExternalURL(props.url)}
            >
              Mehr Infos
            </Button>
          )}
        </Col>

        <Col xs={12} className="mt-5">
          <p>{props.message}</p>
        </Col>
      </Row>
    </Container>
  );
}

export default Neuigkeiten